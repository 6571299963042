
import React, { useRef, useContext } from "react";
import PageWrapper from "../components/PageWrapper";
import emailjs from 'emailjs-com';
import validator from 'validator';
import ReCaptcha from "react-google-recaptcha";
import GlobalContext from "../context/GlobalContext";
import { Helmet } from "react-helmet";
export default function Contact(props) {
    const captchaRef = useRef(null);
    const gContext = useContext(GlobalContext);
    const siteKey = process.env.REACT_APP_CAPTCHA_SITEKEY; 
    const [captchaMessage, setCaptchaMessage] = React.useState(false); 
    const [showMessage, setShowMessage] = React.useState(false); 
    const [values, setvalues] = React.useState({ email: '', subject: '', phone: '', name: '', message: '', error: { email: '', subject: '',phone:'', name: '', message: '' }});
    
    function checkValidity() {
        let checkValidation = false;
        let { error,phone,...rest } = values;
        
       
        if (validator.isEmpty(rest.name))
            error.name = validator.isEmpty(rest.name) ? "Name is required" : error.name;

        error.email = validator.isEmpty(rest.email) ? "Email is required" : error.email;
        error.subject = validator.isEmpty(rest.subject) ? "Subject is required" : error.subject;
        error.message = validator.isEmpty(rest.message) ? "Message is required" : error.message;

        setvalues({ ...values, error });
      
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                checkValidation = true;
            }
        });

        if (checkValidation)
            return false;
        else
            return true;       

    }
     

    const handleChange = (e) => {

        const { name, value } = e.target;
        let error = { ...values.error };
        setShowMessage(false);
        switch (name) {
            case "name":
                error.name = validator.isNumeric(value.trim()) ? "Invalid Name" : "";
                break; 
            case "message":
                error.message = validator.isAlphanumeric(value.trim()) ? "" : "Invalid ";
                break;            
            case "subject":
                error.subject = validator.isAlphanumeric(value.trim()) ? "" : "Invalid";
                break;
            case "email":                
                error.email = validator.isEmail(value.trim()) ?"": "Invalid email";                   
                break;
            case "phone":
                if (value.trim() != '')
                    error.phone = validator.isMobilePhone(value) ? "" : "Invalid phone number";  
                else
                    error.phone="";
                break;
            default:
                break;
        }
        setvalues({ ...values, error, [name]: value });
        
    }
   
    // send email from contact us page using Emailjs
    function sendEmail(e) { 
        setCaptchaMessage(false);
        
        if (checkValidity()) { 
            e.preventDefault();
         
            const token = captchaRef.current.getValue();
            
            if (token == "") {
                setCaptchaMessage(true);
                return;
            } 
            gContext.setLoaderModalVisible(true);

            let { error, ...rest } = values;
            emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_VALUE, process.env.REACT_APP_EMAIL_TEMPLATE_VALUE, rest, process.env.REACT_APP_EMAIL_USERID_VALUE).then(res => {
                
                 
                gContext.setSuccessMessage("Email sent successfully.");
                gContext.toggleSuccessModal();
                setvalues({ email: '', subject: '', phone: '', name: '', message: '', error: { email: '', subject: '', phone: '', name: '', message: '' } });
                captchaRef.current.reset();
                gContext.setLoaderModalVisible(false);
            }).catch(err => {
                gContext.setLoaderModalVisible(false);
                gContext.setErrorModal("Server error");
                gContext.toggleErrorModal();
            });
        }
    }
  return (
      <>
           
          <PageWrapper headerConfig={{
              bgClass: "dynamic-sticky-bg",
              button: props.navType
          }}>
              <span className="screen-darken"></span>

              <div className="page-wrapper">


                  <div className="breadcrumb">
                      <div className="container-fluid">

                          <div className="breadcrumb-wrapper">
                              <h4>Contact Us</h4>

                              <div className="info-meta">
                                  <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / Contact Us</span>
                              </div>

                          </div>

                      </div>
                  </div>

                  <section className="section contact-us-block p-relative">

                      <div className="left-sec">
                          <div className="img-wrapper">
                              <img className="img-block" src="images/office-staff-contact.svg" alt="office-staff"/>
        </div>
                          </div> 

      <div className="contact-block">
                              <h5 className="m-b-20">We would like to hear from you.</h5>

                              <div className="row">

                                  <div className="col-lg-6 col-md-12 col-sm-12">
                                      <div className="forn-group-input">
                                          <label for="job title" className="form-label m-b-10">Your Name <span className="text-red"> * </span></label>
                                      <input type="text"
                                          className={`  form-control   ${(values.error.name.length > 0) ? 'error' : ''}`}
                                          placeholder="Name"
                                          id="name"
                                          name="name"
                                          value={values.name}
                                          autoComplete="off"
                                          required onChange={(e) => (handleChange(e))}
                                      />
                                      {values.error.name.length > 1 && (
                                          <span className="text-red">{values.error.name}</span>
                                      )}
            </div>
                                      </div>

                                      <div className="col-lg-6 col-md-12 col-sm-12">
                                          <div className="forn-group-input">
                                              <label for="job title" className="form-label m-b-10">E-mail <span className="text-red"> * </span></label>
                                      <input type="text"
                                          className="form-control" value={values.email}
                                          className={`form-control   ${(values.error.email.length > 0) ? 'error' : ''}`}
                                          id="email"
                                          placeholder="example@domain.com"
                                          name="email"
                                          autoComplete="off"
                                          onChange={(e) => (handleChange(e))}

                                      />
                                      {values.error.email.length > 1 && (
                                          <span className="text-red">{values.error.email}</span>
                                      )}
            </div>
                                          </div>

                                          <div className="col-lg-6 col-md-12 col-sm-12">
                                              <div className="forn-group-input">
                                                  <label for="job title" className="form-label m-b-10">Subject<span className="text-red"> * </span></label>
                                      <input type="text"
                                          className={`  form-control   ${(values.error.subject.length > 0) ? 'error' : ''}`}
                                          placeholder="Subject"
                                          id="subject"
                                          name="subject" value={values.subject}
                                          autoComplete="off"
                                          required onChange={(e) => (handleChange(e))}
                                      />
                                      {values.error.subject.length > 1 && (
                                          <span className="text-red">{values.error.subject}</span>
                                      )}
            </div>
                                              </div>

                                              <div className="col-lg-6 col-md-12 col-sm-12">
                                                  <div className="forn-group-input">
                                                      <label for="job title" className="form-label m-b-10">Phone number<span className="text-red">  </span></label>
                                      <input type="text"
                                          className={`  form-control   ${(values.error.phone.length > 0) ? 'error' : ''}`}
                                          placeholder="1-234-232-232"
                                          id="phone"
                                          name="phone" value={values.phone}
                                          autoComplete="off"
                                          onChange={(e) => (handleChange(e))}
                                      />
                                      {values.error.phone.length > 1 && (
                                          <span className="text-red">{values.error.phone}</span>
                                      )}
            </div>
                                                  </div>

                                                  <div className="col-lg-12 col-md-12 col-sm-12">
                                                      <div className="forn-group-input">
                                                          <label for="job title" className="form-label m-b-10">Message<span className="text-red"> * </span></label>
                                      <textarea id="message"
                                          placeholder="Type your message"
                                          className={`  form-control  h-px-144  ${(values.error.message.length > 0) ? 'error' : ''}`}
                                          value={values.message}
                                          name="message"
                                          autoComplete="off"
                                          required onChange={(e) => (handleChange(e))}
                                      ></textarea>
                                      {values.error.message.length > 1 && (
                                          <span className="text-red">{values.error.message}</span>
                                      )}
            </div>
                                                      </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 m-b-20">  <ReCaptcha ref={captchaRef} sitekey={siteKey} onExpired={() => {
                                  captchaRef.current.reset(); // here
                              }} />
                                  {captchaMessage === true && (
                                      <span className="text-red">Select captcha</span>
                                  )}
                              </div>


                                                      <div className="contact-btn">
                                  <button type="button" className="btn btn-green" onClick={sendEmail}>Send Message</button>
                                                      </div>
                              {showMessage === true && <div className="col-lg-12 col-md-12 col-sm-12 text-green" role="alert">   </div>}

                                                  </div> 

      </div> 

    </section>

                                          <section className="section reach-us-block d-none">
                                              <div className="container">
                                                  <span className="bg-wrap"></span>
                                                  <div className="p-relative">
                                                      <div className="section-title">
                                                          <h1>Reach US</h1>
                                                      </div>

                                                      <div className="row">

                                                          <div className="col-lg-4 col-md-4 col-sm-12">
                                                              <div className="card step-block">
                                                                  <img src="images/contact-chat.svg" alt="contact-user"/>
                                                                      <h6>Chat with us</h6>
                                                                      <div className="d-flex justify-content-center">
                                                                          <button type="button" className="btn btn-orange">Start Chat</button>
                                                                      </div>
              </div>
                                                              </div>

                                                              <div className="col-lg-4 col-md-4 col-sm-12">
                                                                  <div className="card step-block">
                                                                      <img src="images/contact-msg.svg" alt="contact-msg.svg"/>
                                                                          <h6>Email</h6>
                                                                          <a href="mailto:contact@syncuppro.com">contact@syncuppro.com</a>
              </div>
                                                                  </div>

                                                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                                                      <div className="card step-block">
                                                                          <img src="images/contact-map.svg" alt="contact-map"/>
                                                                              <h6>Address</h6>
                                          <p className="m-b-0">12600 Deerfield Parkway<br/>
                                          Suite 100 Alpharetta,<br />
GA 30004</p>
              </div>
            </div>


                                                                      </div> 

        </div>
                                                              </div>
    </section>

                  <div className="maps-block  d-none">
                      <iframe style={{ border: '0', width: '100%', height: '100%', borderRadius: '10px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.220512355831!2d-84.27333322506968!3d34.08949101576399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f59b68a779a1df%3A0xffa1ffc6c68e0c80!2sSync%20Resource!5e0!3m2!1sen!2sin!4v1690175509088!5m2!1sen!2sin" frameBorder="0" allowFullScreen></iframe>
                                                          </div>

                                                      </div>
      </PageWrapper>
    </>
  );
};

import React, { useContext, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { isInvalid } from "../../utils/helperFn";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { getEncodedEmail } from "../../utils/helperFn";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeCardPaymentCreate, StripeUSBankPaymentCreate } from "../../utils/payment";
import { CreateAlert, PaymentHistoryUpdate } from "../../utils/apiCalls";
import AlertUtils from "../../utils/AlertUtils";
import GlobalContext from "../../context/GlobalContext";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Pay from "../payment/Pay";

const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const CardForm = (props) => {
    
    const gContext = useContext(GlobalContext);
   
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [data, setData] = React.useState(null); 
    const [message, setMessage] = React.useState("");
    const [msAmount, setAmount] = React.useState(0);    
    const [error, setError] = React.useState("");    
    const [clientName, setClientName] = React.useState((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [inprogress, setInprogress] = React.useState(false);
    const [successModalVisible, setSuccessModalVisible] = React.useState(false);
    const [successMessage, setSucMessage] = React.useState('');
    const [paymentType, setPaymentType] = React.useState("bank");
    const [paymentAccount, setPaymentAccount] = React.useState(null);
   
    gContext.setSuccessMessage("");
    useEffect(() => {
        gContext.setErrorModal(errorMessage);
        if (errorMessage != "") {           
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);

 
    

    useEffect(() => {
        console.log("props",props);
        document.title = "Payment | Syncuppro";
    }, []);
    useEffect(() => {
        if (props.paymentInfo && props.paymentInfo.mscode && props.paymentInfo.amount) {
            if (props.paymentInfo.mscode == 500)
                setAmount(props.paymentInfo.amount / 2);
            else
                setAmount(props.paymentInfo.amount);
        }
    }, [props.paymentInfo]);
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
        if (gContext.userInfo.profileInfo) {
            setData(JSON.parse(gContext.userInfo.profileInfo));            
        }
        if (gContext.userInfo.paymentInfo && gContext.userInfo.paymentInfo.length > 0)
        { 
            setPaymentType("bank");
        }
        else
            setPaymentType("card");

    }, [gContext.userInfo.profileInfo]);
    useEffect(() => {
       setClientName((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
    }, [data]);
    //const handleSubmit = async (e) => {
    //    e.preventDefault();
    //    setErrorMessage("");
    //    if (clientName.trim() == "") {
    //        setError("Name is required.");
    //        setErrorMessage("Name field is required.");
    //        return;
    //    }
    //    else if(isInvalid(clientName))
    //    {
    //        setError("Name is invalid.");
    //        setErrorMessage("Name is invalid.");
    //        return;             
    //    }
        
    //    gContext.setLoaderModalVisible(true);
    //    setInprogress(true);
    //    // We don't want to let default form submission happen here,
    //    // which would refresh the page.
       
      
    //    //if (!stripe || !elements) {
    //    //    // Stripe.js has not yet loaded.
    //    //    // Make sure to disable form submission until Stripe.js has loaded.
    //    //    setErrorMessage('Stripe has not yet loaded.');
           
    //    //    setInprogress(false);
    //    //    gContext.setLoaderModalVisible(false);
    //    //    return;
    //    //}  
    //    if (paymentType != 'bank') {
    //        const element = elements.getElement(CardElement);
    //        const { error: tokenError, token } = await stripe.createToken(element);
    //        if (tokenError) {
    //            setErrorMessage(tokenError.message);
    //            setInprogress(false);
    //            gContext.setLoaderModalVisible(false);
    //            return;
    //        }

    //        try {

    //            const result = await StripeCardPaymentCreate({ name: clientName, amount: msAmount, destination: props.paymentInfo?.stripe_id, token: token?.id, email: gContext.userInfo.email, milestoneid: props.paymentInfo?.id, contractid: props.paymentInfo?.contractid, expertid: props.contract?.expertid, clientid: props.contract?.clientid, paymentMethodType: "card", clientStripeId: null, bankAccountId: null, taskName: props.paymentInfo?.task, clientName: props.contract?.hiringperson });

    //            if (result.data && result.data.stripePaymentCreate && result.data.stripePaymentCreate.responseData.code == "200") {

    //                let intentResponse = JSON.parse(result.data.stripePaymentCreate.responseData.message);

    //                const confirmPayment = await stripe.confirmCardPayment(
    //                    intentResponse.client_secret);

    //                if (confirmPayment && confirmPayment.paymentIntent && confirmPayment.paymentIntent.status && confirmPayment.paymentIntent.status.toLowerCase() === "succeeded") {

    //                    SendNotification("success");
    //                    setSucMessage(`Payment completed successfully. `);
    //                    const updateResult = await PaymentHistoryUpdate(confirmPayment.paymentIntent.id, confirmPayment.paymentIntent.status);

    //                    if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
    //                        //console.log("console.log(updateResult);", updateResult);
    //                    }
    //                    setSuccessModalVisible(true);
    //                    setInprogress(false);
    //                    props.setShowPayment();
    //                    props.bindData();
    //                    gContext.setLoaderModalVisible(false);

    //                }
    //                else if (confirmPayment.error && confirmPayment.error.payment_intent) {
    //                    setInprogress(false);
    //                    const updateResult = await PaymentHistoryUpdate(confirmPayment.error.payment_intent.id, confirmPayment.error.message);

    //                    if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
    //                        //  console.log("updateResult",updateResult);
    //                    }
    //                    SendNotification("failure");
    //                    setErrorMessage(confirmPayment.error.message);
    //                    gContext.setLoaderModalVisible(false);
    //                }
    //            }
    //            else {
    //                setInprogress(false);
    //                SendNotification("failure");
    //                setErrorMessage(JSON.parse(result.data.stripePaymentCreate.responseData.message));
    //                // console.log(result.data.stripePaymentCreate.responseData.message);
    //                gContext.setLoaderModalVisible(false);
    //            }
    //        } catch (e) {
    //            console.log(e);
    //            setInprogress(false);
    //            setErrorMessage(e.message);
    //            gContext.setLoaderModalVisible(false);

    //        }
    //    }
    //    else {
    //        setErrorMessage("");
    //        console.log("paymentAccount", paymentAccount);
    //        if (paymentAccount) {
    //            try {

    //                const result = await StripeUSBankPaymentCreate({ name: clientName, amount: msAmount, destination: props.paymentInfo?.stripe_id, token: "", email: gContext.userInfo.email, milestoneid: props.paymentInfo?.id, contractid: props.paymentInfo?.contractid, expertid: props.contract?.expertid, clientid: props.contract?.clientid, paymentMethodType: "", clientStripeId: (data && data.length > 0 && data[0] != null) ? data[0].stripeid : null, bankAccountId: paymentAccount?.id, taskName: props.paymentInfo?.task, clientName: props.contract?.hiringperson });

    //                if (result.data && result.data.stripePaymentCreate && result.data.stripePaymentCreate.responseData.code == "200") {

    //                    let intentResponse = JSON.parse(result.data.stripePaymentCreate.responseData.message);
    //                    console.log("intentResponse",intentResponse);
    //                    const confirmPayment = await stripe.confirmUsBankAccountPayment(
    //                        intentResponse.client_secret);
    //                    console.log("confirmPayment",confirmPayment);
    //                    if (confirmPayment && confirmPayment.paymentIntent && confirmPayment.paymentIntent.status && confirmPayment.paymentIntent.status.toLowerCase() === "processing") {

    //                        SendNotification("processing");
    //                        setSucMessage(`Your bank account has been charged successfully. It may take few business days to reflect in your bank account. `);
    //                        const updateResult = await PaymentHistoryUpdate(confirmPayment.paymentIntent.id, confirmPayment.paymentIntent.status);

    //                        if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
    //                            //console.log("console.log(updateResult);", updateResult);
    //                        }
    //                        setSuccessModalVisible(true);
    //                        setInprogress(false);
    //                        props.setShowPayment();
    //                        props.bindData();
    //                        gContext.setLoaderModalVisible(false);

    //                    }
    //                    else if (confirmPayment.error && confirmPayment.error.payment_intent) {
    //                        setInprogress(false);
    //                        const updateResult = await PaymentHistoryUpdate(confirmPayment.error.payment_intent.id, confirmPayment.error.message);

    //                        if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
    //                            //  console.log("updateResult",updateResult);
    //                        }
    //                        SendNotification("failure");
    //                        setErrorMessage("Charging your bank account is failed - " + confirmPayment.error.message +". Please verify your bank account and try again.");
    //                        gContext.setLoaderModalVisible(false);
    //                    }
    //                }
    //                else {
    //                    setInprogress(false);
    //                    SendNotification("failure");
    //                    setErrorMessage("Charging your bank account is failed - " + JSON.parse(result.data.stripePaymentCreate.responseData.message) + ". Please verify your bank account and try again.");
    //                    // console.log(result.data.stripePaymentCreate.responseData.message);
    //                    gContext.setLoaderModalVisible(false);
    //                }
    //            } catch (e) {
    //                console.log(e);
    //                setInprogress(false);
    //                setErrorMessage(e.message);
    //                gContext.setLoaderModalVisible(false);
    //            }
    //        }
    //        else {               
    //            setInprogress(false);
    //            setErrorMessage("Select bank account.");
    //            gContext.setLoaderModalVisible(false);
    //            return;
    //        }
           
    //    } 
    //    gContext.setLoaderModalVisible(false);
    //    // Show a success message to your customer
    //    // There's a risk of the customer closing the window before callback
    //    // execution. Set up a webhook or plugin to listen for the
    //    // payment_intent.succeeded event that handles any business critical
    //    // post-payment actions.
    //    //addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    //};
    async function SendNotification(status) { 
        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = (status == "success")? AlertUtils.PaymentSuccess.                 
                replace("[MTITLE]", props.paymentInfo.task).
                replace("[GUID]", props.contract.contractguid) : AlertUtils.PaymentFailure.
                    replace("[MTITLE]", props.paymentInfo.task).
                    replace("[GUID]", props.contract.contractguid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Payment", display: true, status: 'N', toTypeId: 1, toUserId: props.contract?.expertid   });

            if (result.data.createAlert.responseData.code == "200") {

            }
            const result1 = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Payment", display: true, status: 'N', toTypeId: 2, toUserId: props.contract?.clientid });

            if (result1.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    const popoverProcessing = (
        <Popover className="  zindex " style={{ zIndex: "99999999999" }} >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                {paymentType == "card" && <p>  Stripe Processing fee 2.9% + $0.30</p>}
                {paymentType == "bank" && <p>  Stripe Processing fee 0.8% or $5 </p>}
            </div>


        </Popover>

    );
    const popoverPlatform = (
        <Popover className=" zindex  " style={{ zIndex :"99999999999"} } >


            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>  Platform fee 3%  </p>

            </div>


        </Popover>

    );
    return (
        <> 
            <ModalStyled size="sm"
                centered backdrop="static"
                show={ successModalVisible}
                onHide={() => { setSucMessage(''); setSuccessModalVisible(false); document.title = "Contract | Syncuppro"; }}
            >
                <div className="modal-body text-center"  >
                    <img className="m-b-20" src="../images/sucess-check.svg" alt="Success" />
                    <h5 className="m-b-10">Success!</h5>
                    <p className="text-light-gray">{successMessage}</p>

                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120" onClick={() => { setSuccessModalVisible(false);  }}>Ok</button>
                    </div>

                </div>
            </ModalStyled>
            <ModalStyled size="sm"
                centered backdrop="static"
                show={props.showPayment}
                onHide={() => { props.setShowPayment(); document.title = "Contract | Syncuppro"; }}
            >
                
                <div className="modal-body text-left">

                    <button type="button" className="btn-close " onClick={() => { props.setShowPayment(false); }}></button>
                    <div className="d-flex m-t-0 "><img className=" w-40 m-r-50" src="../images/synuppro-logo-icon.jpg" alt="Syncuppro" /> <h6 className="m-t-5"> Milestone Payment</h6></div>
                     
                    <div className="d-flex justify-content-between ">

                        <p className="m-0 m-t-5 text-left f-16">
                            Payment for Task <b>{(props.paymentInfo.task) ? "MS" + props.paymentInfo.seq + "-" + props.paymentInfo.task : ''}</b> to the Expert <b>{props.paymentInfo.firstname + ' ' + props.paymentInfo.lastname} </b>
                        </p>

                     
                    </div>
                  
                    <div className="forn-group-input m-t-20 m-b-5">
                        <label htmlFor="name" className="form-label m-b-10">  Name </label>

                        <input id="name" className={`form-control   ${(error.length > 0) ? 'error' : ''}`} onChange={(e) => { setClientName(e.target.value);  if (e.target.value == "") setError("Name is required"); else {  setError(""); } }} value={clientName}></input>
                        {error.length > 1 && (
                            <span className="text-red">{error}</span>
                        )}
                    </div>
                    <div className="forn-group-input p-t-0 m-t-0 m-b-5">
                        <label htmlFor="email" className="form-label m-b-10">  E-mail  </label>

                        <label id="email" className="form-label text-green"> {getEncodedEmail(gContext.userInfo.email)} </label>
                    </div>
                    <div className="forn-group-input m-b-5">
                        <label htmlFor="amount" className="form-label m-b-10">  Amount  </label>

                        <div className="row m-l-5">
                            <div className="col-6 ">
                                Milestone
                            </div>
                            <div className="col-6 ">
                                {(msAmount) ? new Intl.NumberFormat('en-US', {
                                    style: "currency",
                                    currency: "USD",
                                }).format(msAmount) : '-'} 
                            </div>
                            <div className="col-6 ">
                                Processing Fee
                            </div>
                            <div className="col-6   d-flex">
                                {paymentType == "card" && < span className="align-middle m-b-5">   {(msAmount) ? new Intl.NumberFormat('en-US', {
                                    style: "currency",
                                    currency: "USD",
                                }).format((msAmount * .029) + 0.30) : '-'}
                                </span>}
                                {paymentType == "bank" && < span className="align-middle m-b-5">   {(msAmount) ? new Intl.NumberFormat('en-US', {
                                    style: "currency",
                                    currency: "USD",
                                }).format(((msAmount * .008) <= 5) ? msAmount * .008 : 5) : '-'}
                                </span>}
                                <OverlayTrigger trigger="hover" placement="auto" overlay={
                                    popoverProcessing}
                                     >
                                    <span className="text-theme m-icon material-icons m-l-10 pointer f-18" >info</span>
                                </OverlayTrigger>
                            </div>
                            <div className="col-6  ">
                                Platform Fee
                            </div>
                            <div className="col-6  align-middle  d-flex">
                                <span className="align-middle m-b-5"> {(msAmount) ? new Intl.NumberFormat('en-US', {
                                    style: "currency",
                                    currency: "USD",
                                }).format(msAmount * parseFloat(process.env.REACT_APP_CLIENTCHARGES)) : '-'}
                                </span>   <OverlayTrigger trigger="hover" placement="auto" overlay={popoverPlatform} >
                                    <span className="text-theme m-icon material-icons m-l-10 pointer f-18" >info</span>
                                </OverlayTrigger>
                            </div>
                            <div className="col-6 ">
                                Total
                            </div>
                            <div className="col-6 ">
                                {paymentType == "card" && <>   {(msAmount) ? new Intl.NumberFormat('en-US', {
                                    style: "currency",
                                    currency: "USD",
                                }).format(parseFloat(msAmount) + parseFloat(msAmount * 0.029) + parseFloat(0.30) + parseFloat(msAmount * .03)) : '-'}</>}
                                {paymentType == "bank" && <>   {(msAmount) ? new Intl.NumberFormat('en-US', {
                                    style: "currency",
                                    currency: "USD",
                                }).format(parseFloat(msAmount) + parseFloat(((msAmount * .008) <= 5) ? msAmount * .008 : 5) + parseFloat(msAmount * .03)) : '-'}</>}
                            </div>
                        </div>
                       
                      
                    </div>
                    <div className="forn-group-input p-t-0 m-t-0 m-b-5">
                        <label htmlFor="payment" className="form-label m-b-10"> Payment </label>
                        {gContext.userInfo.paymentInfo && gContext.userInfo.paymentInfo.length > 0 &&
                        <div className="d-flex">

                            <div className="d-flex align-item-center m-r-30">
                                <div className="form-check m-r-10">
                                    <input className="form-check-input" type="checkbox" name="bank" id="expert-check" onChange={() => setPaymentType('bank')} checked={paymentType == 'bank'} />
                                </div>
                                <span className="pointer" onClick={() => setPaymentType('bank')}>Bank account </span>
                            </div>

                            <div className="d-flex align-item-center">
                                <div className="form-check m-r-10">
                                    <input name="cardpayment"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="client-check" onChange={() => { setPaymentType('card'); setPaymentAccount(null); }} checked={paymentType == 'card'} />
                                </div>
                                <span className="pointer" onClick={() => { setPaymentType('card'); setPaymentAccount(null); }}>Card</span>
                            </div>

                        </div>}

                    </div>
                    {paymentType == "bank" && gContext.userInfo.paymentInfo && gContext.userInfo.paymentInfo.length>0 &&
                        
                        gContext.userInfo.paymentInfo.map((payData, index) => {
                           
                            return <div className="forn-group-input m-t-20  m-b-5 d-flex pointer" key={payData.id} onClick={() => { setPaymentAccount(payData); }} >
                                <div className="d-flex justify-content-middle align-center">   <input className="form-check-input " type="radio" id={index + "account"} onChange={() => setPaymentType('bank')} checked={paymentAccount && paymentAccount.id == payData.id} /> <i className="fa fa-bank text-right text-green f-24 m-l-10 "  ></i> <span className="m-l-20"> {payData.bank_name}</span> <span className="text-upper m-l-5"> ({payData.currency}) </span></div>
                        <span className="m-t-5 m-l-30"> **** {payData.last4} </span>

                                </div>
                            })
                   }
 
                    
                    {paymentType == "card" &&
                        <Elements stripe={loadStripe(process.env.REACT_APP_PAY_KEY)}>
                            <Pay setSucMessage={(msg) => { setSucMessage(msg); setSuccessModalVisible(true); }} msAmount={msAmount} paymentType={paymentType} paymentAccount={paymentAccount} setError={(e) => { setError(e) }} paymentInfo={props.paymentInfo} contract={props.contract} showPayment={props.showPayment} bindData={() => { props.bindData() }} setShowPayment={(flag) => { props.setShowPayment(flag) }} clientName={clientName }></Pay></Elements>
                    }
                    {paymentType == "bank" &&
                        <Elements stripe={loadStripe(process.env.REACT_APP_PAY_KEY, { stripeAccount: (data && data.length > 0 && data[0] != null) ? data[0].stripeid : "" }) }>
                            <Pay setSucMessage={(msg) => { setSucMessage(msg); setSuccessModalVisible(true); }} msAmount={msAmount} paymentType={paymentType} paymentAccount={paymentAccount} setError={(e) => { setError(e) }} paymentInfo={props.paymentInfo} contract={props.contract} showPayment={props.showPayment} bindData={() => { props.bindData() }} setShowPayment={(flag) => { props.setShowPayment(flag) }}></Pay></Elements>}

                    <p className="m-0 m-t-20 text-left">
                        * <strong>SyncupPro</strong> never stores your CC or ACH info. <br />
                        * Payments are made via <strong>Stripe</strong><i className="fa-regular fa-registered"></i> Payment Gateway.
           </p>


                    </div>
            </ModalStyled>
          
            
        </>
    );
};

export default CardForm;
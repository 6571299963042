const AlertUtils = {

    //NewProposal: "You have received a <b>new proposal</b> from <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a> for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.",
    NewProposal: "New proposal received: <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> role from <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.<br/> Check  <a class='text-theme' href = '" + window.location.origin + "/dashboard' target = '_self'   > dashboard</a> Proposal section to shortlist, make an offer, or reject",

    // New offer received for the job: 9001 Audit - Multiple Opportunities from Sync Resource.
    //NewOffer: "You have received a <b>new offer</b> from <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a> for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.",
    NewOffer: "New offer received for the job: <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> from <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a>.",

    // ProposalShortListed: "Your proposal has been <b>shortlisted</b> for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> by <a class='text-theme' href='" + window.location.origin +"/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a>.",
    //Congratulations! Shortlisted: ISO 27001 Consultant utility specific with Sync Resource.
    ProposalShortListed: "Congratulations! Shortlisted: <a class= 'text-theme' href = '" + window.location.origin + "/job/[JOBGUID]' target = '_self' rel = 'noopener noreferrer' > [JOBTITLE]</a > with <a class='text-theme' href = '" + window.location.origin + "/client/[CLIENTSUB]' target = '_self' rel = 'noopener noreferrer' > [COMPANY]</a>.",


    //ProposalWithdrawn: "The proposal has been <b>withdrawn</b> for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",
    ProposalWithdrawn: "Proposal for <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> withdrawn by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",

    //OfferAccepted: "Your offer for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> has been accepted   by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",
    OfferAccepted: "Offer Accepted: <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> role by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",  

   // OfferRejected: "Your offer for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> has been rejected  by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",
    OfferRejected: "Offer Rejected: <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> role by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>",  


    //OfferExpired: "Your offer has been Expired for the job <a class='text-theme' href='job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.",

    //Offer withdrawn: 27001 Consultant position by Sync Resource.
    OfferWithdrawn: "Offer withdrawn: <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> by <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a>.",

    //   New contract received: 9001 Audit - Multiple Opportunities from Sync Resource. Please E-sign before the expiry date.
    //NewContract: "You have received a <b>new contract</b> from <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a> for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.Please E-Sign the contract before expiry date.",
    NewContract: "New contract received: <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> from <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a>. Please E-sign before the expiry date.",

    // ActiveContracttoExpert: "Congratulation!, You have <b>e-signed</b> a new contract <a class='text-theme' href='" + window.location.origin + "/contract/[CGUID]' target='_self' rel='noopener noreferrer' >[CONTRACTTITLE]</a> for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.",
    // ActiveContracttoClient: "Congratulation! The contract, <a class='text-theme' href='" + window.location.origin + "/contract/[CGUID]' target='_self' rel='noopener noreferrer' >[CONTRACTTITLE]</a> has been<b> e-signed </b>for the job <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",
    //  Successfully E-signed a new contract for 9001 Audit - Multiple Opportunities.
    ActiveContracttoExpert: "Successfully E-signed a new contract for <a class= 'text-theme' href = '" + window.location.origin + "/job/[JOBGUID]' target = '_self' rel = 'noopener noreferrer' > [JOBTITLE]</a >.",
    ActiveContracttoClient: "Successfully E-signed a new contract for <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",

    //Terminated certification audit for 13485 contract citing  reason: Others.

    //TerminatedbyExperttoExpert: "You have <b>terminated</b> <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract  for the reason <strong>[REASON] </strong> .",
    //TerminatedbyExperttoClient: " <a class='text-theme' href='" + window.location.origin +"/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract has been terminated for the reason <strong>[REASON] </strong> .",
    //TerminatedbyClienttoExpert: " <a class='text-theme' href='" + window.location.origin +"/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract has been terminated for the reason <strong>[REASON] </strong> .",
    //TerminatedbyClienttoClient: "You have <b>terminated </b><a class='text-theme' href='" + window.location.origin +"/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract  for the reason <strong>[REASON] </strong> .",
    TerminatedbyExperttoExpert: "Terminated <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract citing  reason: <strong>[REASON] </strong>.",
    TerminatedbyExperttoClient: "Terminated <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract citing  reason: <strong>[REASON] </strong>.",
    TerminatedbyClienttoExpert: "Terminated <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract citing  reason: <strong>[REASON] </strong>.",
    TerminatedbyClienttoClient: "Terminated <a class='text-theme' href='" + window.location.origin + "/job/[JOBGUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> contract citing  reason: <strong>[REASON] </strong>.",
     

    //Successful completion: 9001 Audit - Multiple Opportunities contract.
    CompletedContract: "Successful completion:  <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[TITLE]</a> contract.",

    //Feedback received for the ISO 9001 Audit - Multiple Opportunities job.
    //FeedbacktoExpert: "You have received feedback from <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a> for the contract <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.",
    //FeedbacktoClient: "You have received feedback from <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a> for the contract <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a>.",
    FeedbacktoExpert: "Feedback received for the <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> - <a class= 'text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' > [COMPANY]</a>.",
    FeedbacktoClient: "Feedback received for the <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> - <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a>.",
    //MilestonetoExpert: "You have received a <b> [STATUS]</b> milestone from <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a> for the contract <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[TITLE]</a>.",
    //MilestonetoClient: "You have received a <b> completed </b> milestone from <a class='text-theme' href='" + window.location.origin + "/client/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a> for the contract <a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[TITLE]</a>.",
    MilestonetoExpert: "<a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[MILESTONE]</a> <b>[STATUS]</b>: <a class='text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' >[COMPANY]</a> updated for the <a class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[TITLE]</a>.",
     
    //MilestonetoClient: "You have received a <b> completed </b> milestone from <a class='text-theme' href='" + window.location.origin + "/client/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a> for the contract <a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[TITLE]</a>.",
    MilestonetoClient: "<a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[MILESTONE]</a> <b>Completed<b/> by <a class='text-theme' href='" + window.location.origin + "/expert/[EXPERTSUB]' target='_self' rel='noopener noreferrer' >[EXPERTNAME]</a> for the <a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[TITLE]</a>. ",
    PaymentProcessing: "Payment process: Payment for the milestone <a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[MTITLE]</a> is in progress.",
    PaymentSuccess: "Payment success: Payment for the milestone [SEQ] <a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[MTITLE]</a> from [CLIENT] completed successfully.",
    PaymentFailure: "Payment failure: Payment for the milestone <a  class='text-theme' href='" + window.location.origin + "/contract/[GUID]' target='_self' rel='noopener noreferrer' >[MTITLE]</a> failed to complete.",
    ClientJobInvite: "You are invited for the job <a class='text-theme' href='" + window.location.origin + "/job/[GUID]' target='_self' rel='noopener noreferrer' >[JOBTITLE]</a> by <a class= 'text-theme' href='" + window.location.origin + "/client/[CLIENTSUB]' target='_self' rel='noopener noreferrer' > [COMPANY]</a>.",
}
export default AlertUtils; 

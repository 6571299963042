 import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import {  ProposalStatusUpdate, CreateAlert} from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays"; 
import ModalJobProposal from "../../components/JobProposal/ClientJobProposal";
import Message from "../../components/Message";   
import SendMessage from "../../components/Message/SendMessage";
import AlertUtils from "../../utils/AlertUtils";
import ReactCountryFlag from "react-country-flag";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function PostedJobs(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [proposalList, setProposalList] = useState([]);
    const [currentValue, setCurrentValue] = useState({ id: null });
    const [message, setMessage] = React.useState(false);
    const [proposalData, setproposalData] = useState({});
    const [proposalModalVisible, setProposalModalVisible] = useState(false);
    const [modalvisible, setmodalvisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(proposalList, page, gContext.paginationSize);
    const toggleProposalModal = () => {
        setProposalModalVisible(!proposalModalVisible);
    };
    const togglemodal = () => {
        setmodalvisible(!modalvisible);
    };
    function ViewProposal(data) {
        setproposalData(data);
        toggleProposalModal();
    }   
    function ViewMessage() {
       
        toggleProposalModal();
        togglemodal();
    }   

    const callConfirm = (mode, id,data) => {
       
        if (id) { 
                setproposalData(data);
                gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + mode +" this proposal?", ["confirmed"]: false, id: id, showButton: true, mode: mode,page:"proposal" });
                gContext.toggleConfirmModal();
        } 
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed && gContext.confirmModal.page == "proposal") { 
            updateStatus(gContext.confirmModal.id, gContext.confirmModal.mode);   
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["page"]: ""
            });
        }
        else { setCurrentValue({ id: null, page: "" }); }
    }, [gContext.confirmModal]);

    const updateStatus = async (id, mode) => {
        gContext.setLoaderModalVisible(true);
        try {
            
            let status = (mode === "shortlist") ? 200 : (mode === "reject") ? 300 : 100;
                const result = await ProposalStatusUpdate(id, status );
               
            if (result.data.proposalStatusUpdate.responseData.code == "200") { 
                if (mode === "shortlist")
                   SendNotification();

                props.callbackUpdateProposal();
                } 
            }
        catch (e) {  
            console.log(e);
            gContext.setLoaderModalVisible(false);
        } 
        gContext.setLoaderModalVisible(false);
    } 
    async function SendNotification() {

        const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
        

        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = AlertUtils.ProposalShortListed.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company:"").
                replace("[JOBTITLE]", proposalData.title).
                replace("[JOBGUID]", proposalData.guid);
           

            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: proposalData.expertid });

            if (result.data.createAlert.responseData.code == "200") {
                 
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    function LoadProposal() {
        try {
            if (props.proposalData) {
                setPage(1);
                let data;
                if (props.mode === "open" || props.mode === "job") {
                    if (props.id)
                        data = props.proposalData.open.filter(item => item["guid"] == props.id);
                    else
                        data = props.proposalData.open;
                }
                else if (props.mode === "shortlisted") {
                    if (props.id)
                        data = props.proposalData.shortlisted.filter(item => item["guid"] == props.id);
                    else
                        data = props.proposalData.shortlisted;

                }
                else if (props.mode === "withdrawn") {
                    if (props.id)
                        data = props.proposalData.withdrawn.filter(item => item["guid"] == props.id);
                    else
                        data = props.proposalData.withdrawn;

                }
                else if (props.mode === "archived") {
                    if (props.id)
                        data = props.proposalData.archived.filter(item => item["guid"] == props.id);
                    else
                        data = props.proposalData.archived;

                }
                else {
                    if (props.id)
                        data = props.proposalData.rejected.filter(item => item["guid"] == props.id);
                    else
                        data = props.proposalData.rejected;

                }
                console.log("dataproposal", data);
                setProposalList(data);
                setLoaded(true);
            }
        }
        catch (e) {
            console.log(e);

        }
    }
    useEffect(() => {
        
        LoadProposal(); 
    }, [props.proposalData]);

    useEffect(() => {
         
        LoadProposal(); 
    }, [props.mode]);

    useEffect(() => { 
        document.title = "Proposals | Syncuppro";
    }, []);
    return (
        <>
       

                            {message && <div className="col-lg-12 col-xl-12 text-left ml-6">
                                <Message message="Job deleted successfully" />
                            </div>}
                       
            {(loaded && proposalList && proposalList.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No proposal found</span>
            </div>}
            {(loaded && proposalList && proposalList.length > 0) &&

                <>
                <div className="table-responsive">
                <table className="table table-striped">



                    <thead>
                        <tr>
                            <th
                                    className="w-200 min-lg-cell"
                            >
                                Job Title
                        </th>
                            <th
                                    className="w-200  min-lg-cell"
                            >
                                Applicant
                                </th> <th className="w-150  min-lg-cell"
                               
                            >
                                Location
                        </th>
                                <th className="  min-lg-cell d-none"
                                
                            >
                                Experience
                       </th>
                            <th
                                    className="w-150   min-lg-cell"
                            >
                                Rate 
                        </th>
                            <th
                                    className="  min-lg-cell"
                            >
                                Bid Rate 
                        </th>
                                <th className="  min-lg-cell">
                                Submitted on
                            </th>
                                <th className="  min-lg-cell">
                                {(props.mode === "shortlisted") ? "Shortlisted on" :(props.mode === "withdrawn") ? "Withdrawn on" : (props.mode === "archived") ? "Archived on" : (props.mode === "rejected") ? "Rejected on" : ''}
                            </th>
                                <th className="  min-lg-cell"
                                
                            ></th>


                        </tr>
                    </thead> 

                       
                        <tbody>
                        {slice && slice.map((data, index) => {

                            return (

                               
                                    <tr key={index}>
                                       
                                       <td>
                                        <span className="d-flex justify-content-left align-items-left" onClick={() => { window.location.href = "job/" + data.guid; }}>
                                            <span className="title    d-flex  pointer" data-toggle="tooltip" title={data.title} ><span className="one-line">{data.title}</span>  </span>
                                                    
                                                </span>
                                            </td>
                                       
                                       
                                       
                                        <td className="d-none">
                                        <div className="d-flex justify-content-left align-items-left">
                                            <div className="info-meta company pointer" data-toggle="tooltip" title={data.title} >  <span className=" table-title one-line text-black pointer" onClick={() => {window.location.href= "job/" + data.guid; }}>
                                                {data.title} </span> 
                                            </div></div>
                                    </td>
                                    <td>

                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/expert/" + data.sub; }}   >
                                                <img className="m-r-10 " id={"img"+index} src={s3URL + data.avatar} onError={(img) => { img.target.src = '../images/user-default.png'; }} alt=""/>
                                                <span className="one-line text-black ">{data.firstname + ' ' + data.lastname} </span>  
                                            </div>

                                           
                                        </div>
                                    </td>
                                        <td>
                                            <div className="info-meta flag">
                                                <ReactCountryFlag
                                                countryCode={data.countrycode}
                                                    svg
                                                    style={{
                                                        width: '1.5em',
                                                        height: '.7em',
                                                    }}
                                                    title={data.countrycode}
                                                />
                                            <span className="one-line text-black m-l-5 pointer" data-toggle="tooltip" title={data.city.toLowerCase() + ' ' + data.state.toLowerCase() + ' ' + data.countrycode } > <span className={`    ${(data.city != null && data.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{data.city.toLowerCase() + ' '}</span> <span className={`    ${(data.state != null && data.state.length > 2) ? 'text-capi' : 'text-upper'}`}>{data.state.toLowerCase()}</span> {' ' + data.countrycode}</span>
                                            </div>
                                        </td>
                                    <td className="d-none"> {data.experienclevel}</td>
                                        <td>
                                       ${(data.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.dailyrate)) : ((data.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.fixedrate)) : (data.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.hourlyrate)) : (data.ratefrom && data.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(data.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(data.rateto)) : '-')}
                                        </td>
                                        <td>
                                       ${(data.bid) ? new Intl.NumberFormat('en-US').format(parseInt(data.bid)) : '-'}
                                        </td>
                                        <td>
                                        {(data.statuscode === 500 || data.statuscode === 400 || data.statuscode === 300) ? ((data.datecreated) ? getMonthDateYear(data.datecreated) : "-") : (data.datecreated ? getMonthDateYear(data.datecreated) : "-")}</td>
                                        
                                        <td>
                                        {( data.statuscode === 200 || data.statuscode === 500 || data.statuscode === 400 || data.statuscode === 300) ? ((data.datemodified) ? getMonthDateYear(data.datemodified) : "-") : ''}</td>
                                        
                                        <td>
                                        <div className="btn-table">

                                            {(props.mode === "open" || props.mode === "job") &&
                                                <>



                                                <button className="btn btn-sm btn-gray"  onClick={() => (ViewProposal(data))}>
                                                    View</button>
                                                <button className="btn btn-sm btn-gray"  onClick={() => (callConfirm('shortlist', data.id, data))}>
                                                    ShortList</button>
                                                <button className="btn btn-sm btn-gray"  onClick={() => (callConfirm('reject', data.id, data))}>Reject</button>
                                            </>
                                               }

                                            {props.mode === "shortlisted" &&


                                                <>



                                                <button className="btn btn-sm btn-gray" style={{ width:"120px" }} onClick={() => (ViewProposal(data))}>
                                                        Make an Offer</button>
                                                <button className="btn btn-sm btn-gray"  onClick={() => { setproposalData(data); togglemodal(); }}>
                                                    Message</button>
                                                <button className="btn btn-sm btn-gray"  onClick={() => (callConfirm('reject', data.id, data))}>
                                                    Reject</button>
                                                </>
                                            }

                                            {props.mode === "rejected" && <>
                                                <button className="btn btn-sm btn-gray"  onClick={() => (ViewProposal(data))}>
                                                    View</button>
                                                <button className="btn btn-sm btn-gray"  onClick={() => (callConfirm('reopen', data.id, data))}>
                                                    Reopen</button>
                                            </>
                                                }
                                            {(props.mode === "withdrawn" || props.mode === "archived") &&
                                                <button className="btn btn-sm btn-gray"  onClick={() => (ViewProposal(data))}>
                                                    View</button>}

                                            </div>
                                        </td>
                                    </tr>)
                            })}</tbody>
                    </table>
                </div>
                {(loaded && proposalList && proposalList.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}


                </>
                             }
                   
            {modalvisible && <SendMessage userdata={proposalData} modalvisible={modalvisible} togglemodal={togglemodal} />}
            {proposalModalVisible && <ModalJobProposal proposaldata={proposalData} id={proposalData.guid} proposalmodalvisible={proposalModalVisible} toggleproposalmodal={toggleProposalModal} viewmessage={ViewMessage} />}
        </>
    );
};
import React, { useContext, useEffect, useState } from "react";
import StarRating from "../../components/Core/Rating";
import styled from "styled-components";
import validator from 'validator';
import { sinceDate } from "../../utils/addDays";
import { FileUpload, getFileName } from "../../utils/fileUpload";
import { isInvalid } from "../../utils/helperFn";
import { ClientJobsRead, Createproposal, GetExpertPreviewLoad, CreateAlert } from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import Message from "../../components/Message";
import AlertUtils from "../../utils/AlertUtils";
import ReactCountryFlag from "react-country-flag";
import { Modal } from "react-bootstrap"; 
import ReactQuill from 'react-quill';
import DOMPurify from 'isomorphic-dompurify';
import { Popover, OverlayTrigger } from 'react-bootstrap';
const imgLoader = "../assets/image/svg/spinning-circles.svg";
 
const ModalStyled = styled(Modal)`
   &.modal {
   
  
  }
`;

export default function JobProposal(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL; 
    const gContext = useContext(GlobalContext); 
    const [job, setJobs] = useState({}); 
    const [values, setValues] = React.useState({id: null, proposalSummary: '', proposalSummaryText: '', bidRate: null, resetResumeName: '', resumeName: '', resume: null, error: { bidRate: "", proposalSummary: "" } });
 
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showUpdate, setShowUpdate] = React.useState(false);

    const reactQuillRef = React.useRef();
    const [editvalue, setEditvalue] = useState('');
    const [editLength, setEditLength] = useState(0);


    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };
    const popoverFile = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p   >
                    Allowed format jpeg,png,jpg,doc,dox,pdf.
                </p>

            </div>



        </Popover>
    );

    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];

    const editorKeyDown = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 4000 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }
    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = values; 
        error.bidRate = (rest.bidRate) ? error.bidRate : "Bid Rate is required";
       
        error.proposalSummary = validator.isEmpty(reactQuillRef.current.unprivilegedEditor.getText().trim()) ? "Cover Letter is required" : (isInvalid(reactQuillRef.current.unprivilegedEditor.getText()) ? "Invalid format" : "");
 
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    async function SendNotification() {

        const profileInfo = gContext.userInfo.profileInfo;
       

        try { 
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = AlertUtils.NewProposal.
                replace("[EXPERTSUB]", gContext.userInfo.sub).
                replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                replace("[JOBTITLE]", job.title).
                replace("[JOBGUID]", job.guid);
             

            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: job.clientid });
            
            if (result.data.createAlert.responseData.code == "200") { 
               
               
            }
            
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    async function ApplyProposal() {
        if (checkValidity()) {
           
            gContext.setLoaderModalVisible(true);
            var data = { ...values, ['proposalSummary']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)), ["email"]: gContext.userInfo.email, ["clientId"]: job.clientid, ["expertId"]: gContext.userInfo.userID, ["jobId"]:job.id};
           
            try {

                if (values.resume) {
                   
                    data.resumeName = getFileName(values.resume, "resume-" + job.id);
                    FileUpload(values.resume, data.resumeName.split('/').pop());
                }
                
                const result = await Createproposal(data);
                 
                if (result.data.proposalCreate.responseData.code == "200") {
                   
                    const profile = await GetExpertPreviewLoad(gContext.userInfo.sub);

                    if (profile.data.expertAllLoad.responseData.code == "200") {
                        let data = JSON.parse(profile.data.expertAllLoad.responseData.message);
                        gContext.setUserInfo({ ...gContext.userInfo, ["appliedJobIds"]: (data[0].appliedJobIds) ? data[0].appliedJobIds : ""});
                    }

                    SendNotification();
                    gContext.toggleRefreshDashboard("proposal");
                    setShowUpdate(true);
                    gContext.setLoaderModalVisible(false);
                }
                else {
                    setErrorMessage(JSON.parse(result.data.proposalCreate.responseData.message));
                }
                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
                setErrorMessage("Server error");
            }
        }
        gContext.setLoaderModalVisible(false);
    }
    const editorChange = (content, delta, source, editor) => {
        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 4000) {
            setEditvalue(editor.getHTML());
            setEditLength(editor.getText().trim().length);

            if (isHtml(editor.getText())) {
                error.proposalSummary = ""; 
            }
            else {
                error.proposalSummary = "";  }
        }
        else {
            setEditvalue(editvalue + " ");
            setEditLength(editLength);
        }
    };
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {
           
            case "bidRate":
                error.bidRate = validator.isDecimal(value) ? "" : "Bid rate is invalid";
                break;
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }
        setValues({ ...values, error, [name]: value });

    }
    const handleClose = () => {
        ClearData();
        
        props.toggleProposalModal();
    };
 
    const onFileChange = (event) => {
        
        if (event.target.files.length > 0) 
        {

            TestFileType(event.target.files[0],['gif', 'jpg', 'png', 'jpeg','pdf','docx','doc','msword','vnd.openxmlformats-officedocument.wordprocessingml.document']);

            
        }

        
    }

    function TestFileType( fileName, fileTypes )
     {       
       
        
            var type = fileName.type.substring(fileName.type.indexOf("/")+1, fileName.type.length);

            if((fileTypes.indexOf(type) != -1) == true)
            {
              
                setValues({ ...values, ["resume"]: fileName });
                          
            }
            else {
          
            }

            }


    
   
    async function BindJobs() {
        try {
            if (props.id) {
                const result = await ClientJobsRead(props.id);
                
                if (result.data.jobRead && result.data.jobRead.responseData.code == "200") {

                    let data = JSON.parse(result.data.jobRead.responseData.message);
                    
                    if (data.length > 0) {
                        setJobs(data[0]);
                    }
                }
                if (props.mode && props.mode == "edit" && props.proposaldata) {
                    setEditvalue(props.proposaldata.cover_letter);
                    setValues({ ["id"]: props.proposaldata.id , ["proposalSummary"]: props.proposaldata.cover_letter, ["bidRate"]: (props.proposaldata.bid)? Math.floor(props.proposaldata.bid) : null , ["resetResumeName"]: (props.proposaldata.attachments) ? props.proposaldata.attachments : "", ["resumeName"]: (props.proposaldata.attachments)? props.proposaldata.attachments:"", ["resume"]: null, ["error"]: { ["bidRate"]: "", ["proposalSummary"]: "" } });
                }
            }
        }
        catch (e) {
            console.log(e); 
        }
    }
    function ClearData() {
        setEditvalue("");
        setEditLength(0); 
        setValues({["proposalSummary"]: '', ["proposalSummaryText"]: '', ["bidRate"]:  null, ["resetResumeName"]: '', ["resumeName"]: '', ["resume"]: null, ["error"]: { ["bidRate"]: "", ["proposalSummary"]: "" } });
    }


   
  
    useEffect(() => { 
        
    }, [props.id]);
    useEffect(() => {   
     BindJobs(); 
    }, [gContext.userInfo]);

    useEffect(() => {
        if (props.proposalModalVisible) { 
            BindJobs(); 
        }
        else
        {
            gContext.toggleRefreshDashboard("proposal");
            ClearData(); 
            setShowUpdate(false); 
        }
    }, [props.proposalModalVisible]);
    return (
        <>
            <ModalStyled className="modal fade"
                backdrop="static" size={((showUpdate === true && props.id) || !props.id) ? "sm" : "lg"}
                centered
                show={props.proposalModalVisible}
                onHide={props.toggleProposalModal}
            >

                {(!props.id) &&

                    <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/unsuccess-cross.svg"   />
                        <h5 className="m-b-10"></h5>
                        <p className="text-gray">{(!(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].exptcerts && gContext.userInfo.profileInfo[0].exptcerts.length > 0)) ? "Profile is not completed. Please update profile/check certificate's status." : "Profile is not completed. Please update profile/check certificate's status."} </p>

                    <div className="d-flex justify-content-center">
                            <a className="btn btn-orange m-r-10" href={(!(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].exptcerts && gContext.userInfo.profileInfo[0].exptcerts.length > 0)) ? "/profile#certificate" : "/profile"} >  Update Profile</a>
                        <button type="button" className="btn  btn-dark-outline " onClick={() => { props.toggleProposalModal(); }}>Ok</button>
                        </div>

                    </div> 
                     }

                {(showUpdate === true && props.id) &&  
                    
                    <div className="modal-body text-center">
                        <img className="m-b-20" src="../images/sucess-check.svg" alt="sucess-check"/>
                            <h5 className="m-b-10">Successful!</h5>
                            <p className="text-light-gray">Your proposal has been submitted successfully.</p>

                            <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green  " onClick={handleClose}>Ok</button>
                            </div>

         </div>}
                {(showUpdate === false && props.id) &&
                  
                    <div className="modal-content">


                        <div className="modal-header">
                        <h4 className="modal-title">Submit a Proposal</h4>
                        <button type="button" className="btn-close"   onClick={() => {
                            ClearData();
                            props.toggleProposalModal();
}}></button>
                        </div>


                        <div className="modal-body"   >
                            <div className="d-flex justify-content-between m-b-10">
                            
                                <h6><a href={window.location.origin + "/job/" + job.guid}  rel="noopener noreferrer" target="_blank">
                                {(job.title) ? job.title : ''}
                            </a></h6>

                            
                            </div>
                            <div className="d-flex justify-content-between m-b-20">

                              

                                {job.dateposted && <div className="info-meta">
                                    <span className="text-theme m-icon material-icons m-r-10">timer</span><span>Posted on : <b>{sinceDate(job.dateposted, Date.now())}</b></span>
                                </div>}

                            </div>

                        <p className="m-b-20 three-line" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p>

                            <div className="divider-line"></div>

                            <div className="row">

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper">
                                        <span className="text-theme m-icon material-icons m-r-10">monitor</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5 text-capi ">Job type</span>
                                            <span className="text-capi "> <b>{ (job.type)?job.type.toLowerCase():""}</b></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper">
                                        <span className="text-theme m-icon material-icons m-r-10">location_on</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Location</span>
                                        <div className="info-meta flag">
                                            <ReactCountryFlag
                                                countryCode={(job.countrycode) ? job.countrycode : ''}
                                                svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '.7em',
                                                }}
                                                title={(job.countryname) ? job.countryname : ''}
                                            />       <span className="one-line text-black m-l-5"><strong> {' '}
                                                <span className={`    ${(job.city != null && job.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(job.city) ? job.city.toLowerCase() : "" + '   '}{" "}</span>
                                                {' '}<span className={`    ${(job.state != null && job.state.length > 2) ? 'text-capi' : 'text-upper'}`}>{(job.state) ? job.state.toLowerCase() : ""}</span>
                                                {' '} {(job.countrycode) ? job.countrycode : ''}</strong></span>
                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper">
                                        <span className="text-theme m-icon material-icons m-r-10">straighten</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Project Length</span>
                                        <b>  {(job.projlength) ? job.projlength : ''}</b>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6  ">
                                    <div className="icon-wrapper  ">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Weekly Hours</span>
                                        <b> {(job.weeklyhrs) ? job.weeklyhrs : ''}</b>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6  ">
                                    <div className="icon-wrapper  ">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Experience Level</span>
                                        <b>{job.explevel}</b>
                                        </div>
                                    </div>
                                </div>


                            {(job.ratefrom && job.rateto) ?

                                    <div className="col-lg-4 col-md-4 col-sm-6  ">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5"> Rate Range (USD)</span>
                                            <b> ${(job.ratefrom && job.rateto) ? new Intl.NumberFormat('en-US').format(job.ratefrom) + " - " + new Intl.NumberFormat('en-US').format(job.rateto) : ''}</b>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper ">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">  {(job.dailyrate) ? "Daily " : ((job.fixedrate) ? "Fixed " : "Hourly ")}Rate (USD)</span>
                                            <b>  {(job.dailyrate) ?"$" +(new Intl.NumberFormat('en-US').format(job.dailyrate)) : ((job.hourlyrate) ? "$" + (new Intl.NumberFormat('en-US').format(job.hourlyrate)) : "$" +(new Intl.NumberFormat('en-US').format(job.fixedrate)))}</b>
                                       
                                        </div>
                                    </div>
                                </div>
                                 }
                             

                            </div>

                            <div className="divider-line"></div>

                       

                            <div className="row">
                            {errorMessage === "" && <>  <div className="col-lg-12 col-md-12 col-sm-12"><span className="text-red">{errorMessage} </span></div></>}
                                <div className="col-lg-6 col-md-6 col-sm-12 d-none">
                                    <div className="d-flex align-item-center">
                                        <div className="medie-info-img">
                                            <img src="images/Frame.svg" alt="frame.svg" />
                                        </div>
                                        <span className="expert-desg"><strong>ISO Consultment</strong></span>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input ">
                                    <label for="first name" className="form-label m-b-10">Bid Rate (USD) <span className="text-red"> *</span></label>
                                        <input name="bidRate"
                                            type="text" maxLength="6" className={`form-control   ${(values.error.bidRate.length > 0) ? ' error' : ''}`}

                                        id="bidRate" defaultValue={values.bidRate} Value={values.bidRate} onKeyUp={handleFieldsChange}
                                        placeholder=" " onChange={handleFieldsChange}
                                    />
                                    {values.error.bidRate.length > 1 && (
                                        <span className="text-red">{values.error.bidRate}</span>
                                    )}
                                    </div>
                            </div>
                            <div className="company-desc ">
                                <div className="desc-info">
                                    <label className="form-label m-b-0 f-16"> Cover Letter   <span className="text-red"> * (Max 4000 character )</span></label>
                                    <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green">  {4000 - ((editLength) ? editLength : 0)}</span></p>
                                </div>



                                <ReactQuill modules={modules}
                                    formats={formats} theme="snow" id="editor" style={{ height: "15vh" }} className="m-b-30"
                                    onKeyDown={editorKeyDown}
                                    ref={reactQuillRef}
                                    value={editvalue} onChange={editorChange}
                                ></ReactQuill>
                                {values.error.proposalSummary.length > 1 && (
                                    <span className="text-red  m-t-15">{values.error.proposalSummary} </span>
                                )}
                            </div>


                            <div className="company-meta m-b-20">
                                    <label className="d-flex form-label m-b-10 f-16">Upload Resume<OverlayTrigger trigger="hover" placement="auto" overlay={popoverFile} >
                                        <span className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                    </OverlayTrigger></label>
                                    {(values.resumeName === "") &&
                                        <>
                                            <label
                                                htmlFor="fileUpload"
                                                className="mb-0  "
                                                style={{ cursor: 'pointer' }}>
                                                {(!values.resume) &&

                                                    <>    <div className="inline-icon-info">
                                                        <div className="upload-file p-relative">
                                                            <span className="text-light-gray m-icon material-icons m-r-10" data-toggle="tooltip" title="Click here to add file" >upload_file</span>Upload a resume

                    </div>
                                                    </div>


                                                    </>}

                                            </label>


                                            {(values.resume) && <>

                                                <div className="upload-doc-img m-b-10">
                                                    <div className="workers-img">
                                                        <img src="../images/file.png" htmlFor="idUpload" />
                                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: values.resetResumeName })}>
                                                    <span className="m-icon material-icons">close</span>
                                                </div>
                                            </div>


                                                   

                                                </div>


                                            </>
                                            }

                                            {(values.resume) && <span className="text-gray" > {(values.resume) ? values.resume.name : ''} </span>}




                                            <input
                                            type="file" key={values.resume}
                                                id="fileUpload" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                className="sr-only"
                                            onChange={onFileChange} onSelect={onFileChange}
                                            />

                                        </ >}
                                    {(values.resumeName !== "") &&
                                        <>

                                            <div className="upload-doc-img m-b-10">
                                            <div className="workers-img pointer"  >
                                                <img src="../images/file.png" data-toggle="tooltip" title="Click here to view." htmlFor="idUpload" className="pointer" onClick={() => { window.open(s3URL + values.resumeName, "_blank"); }} />
                                              


                                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: "" })}>
                                                    <span className="m-icon material-icons">close</span>
                                                </div>
                                        </div>
                                            </div>
                                        </>}



                                    
                                    
                                </div>



                           
                             
                        </div>
                        <div className="modal-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm   m-r-20 text-white" onClick={() => {

                                    ApplyProposal();
                                }}>Apply</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                    ClearData();
                                    props.toggleProposalModal();

                                }}>Cancel</button>
                            </div>
                        </div>

                        </div>

                    </div>
                   
                }


                 
            </ModalStyled>
        </>
    );
};

import React, { useContext, useEffect } from 'react'; 
import styled from "styled-components";
import { Modal } from "react-bootstrap"; 
import GlobalContext from "../../context/GlobalContext"; 
import validator from 'validator'; 
import { AddRecipientUPIMutation, AddRecipientBankMutation } from "../../utils/payment";

const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const WisePayment = (props) => {
    
    const gContext = useContext(GlobalContext);
   
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [data, setData] = React.useState(null);  
    const [error, setError] = React.useState("");    
    const [clientName, setClientName] = React.useState((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
    const [errorMessage, setErrorMessage] = React.useState('');  
    const [successModalVisible, setSuccessModalVisible] = React.useState(false);
    const [successMessage, setSucMessage] = React.useState('');
    const [showAccountHolderEdit, setshowAccountHolderEdit] = React.useState(false);
    const [paymentType, setPaymentType] = React.useState("upi");
    const [values, setvalues] = React.useState({ userName:"",upiId: '', accountHolderName: '', accountNo: '', ifscCode: '', error: { upiId: '', accountHolderName: '', accountNo: '', ifscCode: '' } });
    const [errorModalVisible, setErrorModalVisible] = React.useState(false);

    gContext.setSuccessMessage("");
    useEffect(() => {
        
        if (errorMessage != "") {           
            toggleErrorModal();
        }
    }, [errorMessage]);

    async function ConnectPaymentDetails() {
        gContext.setVisibleProfileMessage(false);
        setErrorMessage("");
        setSucMessage("");
        if (checkValidity()) {
            gContext.setLoaderModalVisible(true);
            try {

                const result = (paymentType == "upi") ? await AddRecipientUPIMutation(values.accountHolderName, values.upiId, gContext.userInfo.sub) : await AddRecipientBankMutation(values.accountHolderName, values.accountNo, values.ifscCode, gContext.userInfo.sub);
                
              
                if (result.data.wiseCreateAccountExpert.responseData.code == "200") {
                    props.setShowPayment(false);
                    setSucMessage("Account connected successfully"); 
                    setSuccessModalVisible(true);
                    gContext.getUpdatedInfo("1", gContext.userInfo.email, gContext.userInfo.sub);
                }                
                else if (result.data.wiseCreateAccountExpert.responseData.code == "500") {
                    setErrorMessage(result.data.wiseCreateAccountExpert.responseData.message);

                   
                    // setErrorMessage("Something went wrong, please try again later or reach out the support team at support@syncuppro.com");
                }
                else {
                    setErrorMessage(result.data.wiseCreateAccountExpert.responseData.message);
                }
            }
            catch (e) {
                console.log(e);
                props.setShowPayment(false);
                setErrorMessage("Server error");
                gContext.setLoaderModalVisible(false);
            }
        }

        else {
            setErrorMessage("Required fields are missing.");
            gContext.setLoaderModalVisible(false);
        }

        gContext.setLoaderModalVisible(false);
    }
    
    function checkValidity() {


        let inValid = false;
        let { error, ...rest } = values;

        error.accountHolderName = validator.isEmpty(rest.accountHolderName.trim()) ? "Account Holder Name is required" : error.accountHolderName;
        if (paymentType == "upi") {
            error.upiId = validator.isEmpty(rest.upiId.trim()) ? "UPI ID is required" : error.upiId;
            setvalues({ ...values, error });
            if (error.accountHolderName.length>1 || error.upiId.length > 1)
                return false;
                
        }         
        else {
            error.accountNo = validator.isEmpty(rest.accountNo.trim()) ? "Account Number is required" : error.accountNo;
            error.ifscCode = validator.isEmpty(rest.ifscCode.trim()) ? "IFSC Code is required" : error.ifscCode;
            setvalues({ ...values, error });
            if (error.accountNo.length > 1 || error.ifscCode.length > 1)
                return false;         
            
        }
        return true;     

        
    }
    /* Fields change event */
    const handleChange = (e) => { 

        let { name, value } = e.target;

        let error = { ...values.error };
       
        switch (name) {
           
            case "upiId":
                const exp = /^[A-Za-z0-9.-]+@\w+$/
                error.upiId = ((value.match(exp)) || value.match(/^[0-9]+@\w+$/) || value === "") ? "" : "UPI ID is not valid"; 
                break;
            case "accountHolderName":
                const exp1 = /^[A-Za-z]{1,40}\s[A-Za-z0-9]{1,40}$/;
                error.accountHolderName = ((value.match(exp1)) || value === "") ? "" : "Account Holder Name is not valid";
                break;
            case "accountNo":
                const regex = /^[0-9]{9,18}$/;
                error.accountNo = ((value.match(regex)) || value === "") ? "" : "Account number is not valid";
                break;
            case "ifscCode":
                value = value.toUpperCase();
                const reg = /^[A-Z]{4}0[A-Z0-9]{6}$/;
                error.ifscCode = ((value.match(reg)) || value === "") ? "" : "IFSC is not valid";
                break;                
            default:
                error[name] = validator.isEmpty(value) ? "*" : "";
                break;
        }

        setvalues({ ...values, error, [name]: value });

    }

    

    useEffect(() => {
       
        document.title = "Payment | Syncuppro";
    }, []);
    
    useEffect(() => {
        if (errorMessage != "") {
           
           toggleErrorModal();
        }
        if (gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0)
            setvalues({ ...values, userName: gContext.userInfo.profileInfo[0].firstname + " " + gContext.userInfo.profileInfo[0].lastname,accountHolderName: gContext.userInfo.profileInfo[0].firstname + " " + gContext.userInfo.profileInfo[0].lastname });

    }, [gContext.userInfo.profileInfo]);

  
    const toggleErrorModal = () => {

        setErrorModalVisible(!errorModalVisible);
    };
    
    
    return (
        <> 
            <ModalStyled size="sm"
                centered backdrop="static"
                show={ successModalVisible}
                onHide={() => { setSucMessage(''); setSuccessModalVisible(false);  }}
            >
                <div className="modal-body text-center"  >
                    <img className="m-b-20" src="../images/sucess-check.svg" alt="Success" />
                    <h5 className="m-b-10">Success!</h5>
                   
                    <p className="text-light-gray">{successMessage}</p>
                   
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120" onClick={() => { setSuccessModalVisible(false); props.setShowPayment(false); }}>Ok</button>
                    </div>

                </div>
            </ModalStyled>

            <ModalStyled size="sm"
                centered backdrop="static"
                show={errorModalVisible}
                onHide={() => { setErrorMessage(""); toggleErrorModal(); }}
            >
                <div className="modal-body text-center">
                    <img className="m-b-10" src="../images/unsuccess-cross.svg" alt="error" />
                    <h5 className="m-b-10">Error!</h5>
                   
                    {errorMessage?.split(',')?.map((data, index) => {
                        if (!data.includes("Sorry"))
                            return (<div key={index} className="d-flex text-left"><span className="text-red  m-l-5 m-r-5">*</span><p className="m-b-5 text-capi"> {data.trim().replaceAll('"',"")}</p><span></span></div>)
                    })}


                    <div className="d-flex justify-content-center m-t-5">
                        <button type="button" className="btn btn-green w-120" onClick={() => { toggleErrorModal(); }}>Ok</button>
                    </div>

                </div>
            </ModalStyled>
            <ModalStyled size="sm"
                centered backdrop="static"
                show={props.showPayment}
                onHide={() => { props.setShowPayment();  }}
            >
                
                <div className="modal-body text-left">

                    <button type="button" className="btn-close " onClick={() => { props.setShowPayment(false); }}></button>
                    <div className="d-flex m-t-0 "><img className=" w-40 m-r-50" src="../images/synuppro-logo-icon.jpg" alt="Syncuppro" /> <h6 className="m-t-5 p-t-3"> Connect your Account </h6></div>
                     
                  

                    <div className="d-flex">
                        <div className="forn-group-input m-t-20 m-b-5">
                            <label htmlFor="name" className="form-label m-b-10">  Select your Account Type </label>

                            <div className="d-flex align-item-center m-r-30">
                                <div className="form-check m-r-10">
                                    <input className="form-check-input" type="checkbox" name="upi" id="expert-check" onChange={() => setPaymentType('upi')} checked={paymentType == 'upi'} />
                                </div>
                                <span className="pointer" onClick={() => setPaymentType('upi')}>UPI </span>


                                <div className="form-check m-l-30 m-r-10">
                                    <input name="cardpayment"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="client-check" onChange={() => { setPaymentType('bank'); }} checked={paymentType == 'bank'} />
                                </div>
                                <span className="pointer" onClick={() => { setPaymentType('bank'); }}>Bank Account</span>
                            </div>

                          

                        </div>
                     
                    </div>
                    <div className="forn-group-input m-t-20 m-b-5">
                        <label htmlFor="accountHolderName" className="form-label m-b-10">  Account Holder Name<span className="text-red m-l-5">* </span> <span className="text-theme m-icon material-icons pointer " data-toggle="tooltip" title={showAccountHolderEdit ? "Cancel Account holder" : "Edit Account holder"} onClick={() => { if (showAccountHolderEdit)setvalues({ ...values, accountHolderName: values.userName }); setshowAccountHolderEdit(!showAccountHolderEdit);  }} >{showAccountHolderEdit ? "cancel" : "edit"}</span></label>
                        {!showAccountHolderEdit && <label className=" m-b-10 "><b> {values.accountHolderName} </b></label>}
                        {showAccountHolderEdit && <> <input name="accountHolderName" id="accountHolderName" className={`form-control   ${(values.error.accountHolderName.length > 0) ? 'error' : ''}`} onChange={(e) => { handleChange(e) }} value={values.accountHolderName}></input>
                            {values.error.accountHolderName.length > 1 && (
                                <span className="text-red">{values.error.accountHolderName}</span>
                            )}</>}
                    </div>
                    {paymentType == "upi" && <div className="forn-group-input m-t-20 m-b-5">
                        <label htmlFor="upiId" className="form-label m-b-10"> UPI ID<span className="text-red m-l-5">* </span>  </label>

                        <input name="upiId"  className={`form-control   ${(values.error.upiId.length > 0) ? 'error' : ''}`} onChange={(e) => { handleChange(e) }} value={values.upiId}></input>
                        {values.error.upiId.length > 1 && (
                            <span className="text-red">{values.error.upiId}</span>
                        )}
                    </div>}
                    {paymentType == "bank" && <>
                        <div className="forn-group-input m-t-20 m-b-5">
                            <label htmlFor="ifscCode" className="form-label m-b-10"> IFSC Code<span className="text-red m-l-5">* </span> </label>

                            <input name="ifscCode" maxLength="11" className={`form-control   ${(values.error.ifscCode.length > 0) ? 'error' : ''}`} onChange={(e) => { handleChange(e) }} value={values.ifscCode}></input>
                            {values.error.ifscCode.length > 1 && (
                                <span className="text-red">{values.error.ifscCode}</span>
                            )}
                        </div>
                        <div className="forn-group-input m-t-20 m-b-5">
                        <label htmlFor="accountNo" className="form-label m-b-10"> Account Number<span className="text-red m-l-5">* </span>  </label>

                            <input name="accountNo"  className={`form-control   ${(values.error.accountNo.length > 0) ? 'error' : ''}`} onChange={(e) => { handleChange(e) }} value={values.accountNo}></input>
                        {values.error.accountNo.length > 1 && (
                            <span className="text-red">{values.error.accountNo}</span>
                        )}
                    </div>
                        
                    </>}

                    <div className="d-flex justify-content-center m-t-30">
                        <button className="btn btn-orange btn-sm bg-gren m-r-20 text-white" onClick={() => ConnectPaymentDetails()} >Submit</button>
                        <button type="button" className="btn btn-dark-outline btn-sm text-black  align-item-center" onClick={() => { props.setShowPayment(false);  }} data-bs-dismiss="modal" >Cancel</button>
                    </div>
                   
                    <p className="m-0 m-t-30 text-left">
                       
                        * <strong>SyncupPro</strong> does not store your payment details within the platform. <br />
                        * <strong>SyncupPro</strong> partners with <strong>Wise</strong> to transfer your funds. 
                    </p>

                    </div>
            </ModalStyled>
          
            
        </>
    );
};

export default WisePayment;
import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import Message from "../../components/Message";
import ConfirmMessages from "../../utils/confirmMessages";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import validator from 'validator';
import ReactQuill from 'react-quill';
import { isInvalid } from "../../utils/helperFn";
import { ClientJobsRead, UpdateClientJob,  ClientJobsList } from "../../utils/apiCalls";
import { GetJobUtils  } from "../../utils/GetAPIUtils";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'isomorphic-dompurify'; 

const Job = (props) => { 
    
    const reactQuillRef = React.useRef();
    const gContext = useContext(GlobalContext);
    const [existingTitles, setExistingTitles] = useState(null);
    const [titlesList, setTitlesList] = useState([]);
    const [tooltip, setTooltip] = useState(false);    
    const [projectLengthList, setProjectLengthList] = useState([]);
    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [compliances, setComplianceList] = useState([]);
    const [selectedCompliances, setSelectedCompliance] = useState([]);
    const [skills, setSkillsList] = useState([]);
    const [selectedSkillData, setSelectedSkillData] = useState([]);
    const [experience, setExperienceList] = useState([{}]);
    const [values, setValues] = React.useState({ rateTypeId: 0, rateType: "", industryTypeId: 0, industryType: "", compCategory: '', id: null, rateRangeFrom: null, rateRangeTo: null, jobType: 'REMOTE', clientId: gContext.userInfo.userID, fixedRate: null, hourlyRate: null, projLengthId: 0, projLength: null, city: '', state: '', countryId: 0, country: null, title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', skillSet: "", compliance: "", error: { rateRangeFrom: '', rateRangeTo: '', fixedRate: '', hourlyRate: '', title: '', expLevel: "", jobType: '', dailyRate: "", fixedRate: "", weeklyHrs: "", desc: "", skillSet: "", compliance: "", city: '', state: '', country: '', projLength: '', compCategory: '', industryType: '', rateType: "" } });
    const [initialValues, setInitialValues] = React.useState({ rateTypeId: 0, rateType: "", industryTypeId: 0, industryType: "", compCategory: '', id: null, rateRangeFrom: null, rateRangeTo: null, type: 'REMOTE', clientId: gContext.userInfo.userID, fixedRate: null, hourlyRate: null, projLengthId: 0, projLength: null, city: '', state: '', countryId: 0, country: null, title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', skillSet: "", error: { rateRangeFrom: '', rateRangeTo: '', fixedRate: '', hourlyRate: '', title: '', expLevel: "", jobType: '', dailyRate: "", fixedRate: "", weeklyHrs: "", desc: "", skillSet: "", compliance: "", city: '', state: '', country: '', projLength: "", compCategory: '', industryType: '', rateType: "" } });
    const [fullSkillSet, setFullSkillSet] = useState([]);
    const [fullCompliance, setFullCompliance] = useState([]);
    const [fullComplianceCategory, setFullComplianceCategory] = useState([]);
    const [fullIndustryType, setFullIndustryType] = useState([]);
    const [weeklyHours, setWeeklyHours] = useState([{}]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = React.useState(false);
    const [editvalue, setEditvalue] = useState('');
    const [editorText, seteditorText] = useState('');
    const [editLength, setEditLength] = useState(0);
    const [showRateRange, setShowRateRange] = useState(false);
    const [selectedIndustryData, setSelectedIndustryData] = useState([]);
    const [compCategory, setCompCategory] = useState([]);
    const [selectedCompCategory, setSelectedCompCategory] = useState([]);
    const [rateType, setRateType] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [profileValues, setprofileValues] = useState(JSON.parse(gContext.userInfo.profileInfo));
 
    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    const popoverRateRange = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta"> <p>  Required for NY State</p></div>
        </Popover>

    );
    const popoverRate = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta"> <p> Select only one rate type.Clear all the rate fields to change the rate type.<br/> </p></div>
        </Popover>

    );
    const popoverExisting = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta"> <p>Create using from past Job requirements </p></div> 
        </Popover>

    );
    
    const popoverSkillSet = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>                   

                    Select the functional role required based on the desired skills. For instance, for skills in documentation support, choose "Documentation," and for expertise in internal audit, select "Internal Auditor."
                </p>

            </div>            

        </Popover>

    );
    const popoverCompliance = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p   >
                    <strong>Choose the category based on the job details:</strong>

                    <br />  For Internal Audit (1st and 2nd Party), select "Auditor: 1st and 2nd Party."
                    <br />  For Training purposes, select "Training."
                    <br />  For External / 3rd Party Audit, choose "Auditor 3rd Party."

                    <br />  For Documentation/QA/Guidance/Consulting, select "Consultant." Please note, you cannot select both "3rd Party" and "Consultant" simultaneously.


                   <br/> A <strong>Consultant</strong> is a person who is an expert in ISO/Compliance standards and has an extensive experience in implementing the standards, following the guidelines and has worked in a variety of industries.

                <br />   A<strong> first-party audit</strong> is performed inside an organization to measure its strength and weakness against its systems or techniques or potentially against external standard principles embraced by the organization. A first-party audit is an internal audit led by auditors who are working in the organization but who have no personal stake in the audit results of the area being audited.

                <br />  A <strong> second-party audit</strong> is an external audit performed on a supplier by a client or by a contracted organization for a client. An agreement is set up, and the services and products are being or will be, conveyed. Second-party audits are dependent upon the guidelines of agreement law, as they are legally binding to the client and the supplier. Second-party audits will quite often be more formal than first-party audits since audit results could impact the client's buying choices.

                <br />   A <strong>third-party audit</strong> is performed by an audit organization independent of the client-supplier relationship and is liberated from an irreconcilable circumstance. The independence of the audit organization is a vital part of an external audit. Third-party reviews might bring about certification, enlistment, acknowledgment, an honor, license endorsement, a reference, a fine, or punishment given by the 3rd party organization.

                <br />  <strong> 1st party auditor</strong> can be an internal resource of organization  who conducts review of organizational systems.

                <br />  <strong> 2nd party auditor</strong> can be an internal or external resource conducting review of supplier /vendor systems.

                <br /> <strong>  3rd party auditor</strong> are affiliated to 3rd independent bodies to perform audit of the organizational system.

    </p>

            </div>
        </Popover>

    );
    function getStatus(code) {
        if (code == "100") return "Draft"; else if (code == "200") return "Posted"; else if (code == "250" || code == "400") return "Closed"; else if (code == "500") return "Archived";else if (code == "300") return "Hired";
    }
    function handleOption(value) {
        setValues({ ...values, ['jobType']: value });
    }
    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];
    async function BindExistingJobs() {
        try {

            const result = await ClientJobsList(gContext.userInfo.userID);

            if (result.data.clientJobsList.responseData.code == "200") {
                let data = JSON.parse(result.data.clientJobsList.responseData.message);
                 
                if (data.length > 0) {
                    let list = [];
                    data.map((row) => (
                        list.push({ code: row.guid, value: row.id, label: row.title   })));
                    setTitlesList(list);
                }
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const handleTitleSelectChange = (e) => {
        if (e != null) {
            gContext.setLoaderModalVisible(true);
            setValues({ existingTitlevalue: '', industryTypeId: 0, industryType: null, compCategory: '', id: null, rateRangeFrom: null, rateRangeTo: null, jobType: 'REMOTE', clientId: gContext.userInfo.userID, fixedRate: null, hourlyRate: null, projLengthId: 0, projLength: null, city: '', state: '', countryId: 0, country: null, title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', skillSet: "", compliance: "", error: { rateRangeFrom: '', rateRangeTo: '', fixedRate: '', hourlyRate: '', title: '', expLevel: "", jobType: '', dailyRate: "", fixedRate: "", weeklyHrs: "", desc: "", skillSet: "", compliance: "", city: '', state: '', country: '', projLength: '', compCategory: '', industryType: '' } });

            BindJobs(e.code);
            setExistingTitles(e);
        }
        
    }
   
    const editorChange = (content, delta, source, editor) => {
        
        let { error, ...rest } = values;
        
        if (editor.getText().length <= 4000) {
            setEditvalue(editor.getHTML());
            seteditorText(editor.getText());
            setEditLength(editor.getText().length);          
            
            if (isInvalid(editor.getText())) {
                error.desc = "Invalid format.";
                setValues({ ...values, error, ["desc"]: editor.getHTML()});
            }
            else {
                error.desc = "";
                setValues({ ...values, error });
            }
        }
        else {
            setEditvalue(editvalue + " ");
            setEditLength(editLength);
        }
    };
    const editorKeyDown = (event) => {
        
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor
     
        if (unprivilegedEditor.getText().length > 4000 && event.key !== 'Backspace') {
            
            event.preventDefault();
        }
    };

    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = values;
         
       
        error.title = (rest.title.trim() == '') ? "Title is required" : isInvalid(rest.title)?"Invalid input.": ''; 
        error.projLength = (rest.projLength) ? '' : "Project Length is required"; 
        error.country = (rest.country) ? '' : "Country is required"; 
        error.expLevel = (rest.expLevel) ? '' : "Experience Level is required"; 
        error.industryType = (rest.industryType) ? '' : "Industry Type is required"; 
        error.weeklyHrs = (rest.weeklyHrs) ? '' : "Weekly hours is required";
        error.desc = (reactQuillRef.current.unprivilegedEditor.getText().trim().length == 0) ? "Jobs description is required" : error.desc;
        error.desc = (isInvalid(reactQuillRef.current.unprivilegedEditor.getText())) ? "Invalid format" : error.desc;       
        error.skillSet = validator.isEmpty(rest.skillSet) ? "Skill Set is required" : ''; 
        error.compliance = validator.isEmpty(rest.compliance) ? "Compliance is required" : "";         
        error.compCategory = validator.isEmpty(rest.compCategory) ? "Compliance category is required" : ''; 
        error.city = validator.isEmpty(rest.city.trim()) ? "City is required" : isInvalid(rest.city) ? "Invalid input." : ''; 
        error.state = validator.isEmpty(rest.state.trim()) ? "State is required" : isInvalid(rest.state) ? "Invalid input." : '';         
        error.rateRangeFrom = (showRateRange) ? ((rest.rateRangeFrom) ? (validator.isDecimal((rest.rateRangeFrom.toString())) ? '' : "Rate range is invalid") : 'Rate range is required') : error.rateRangeFrom;
        error.rateRangeTo = (showRateRange) ? ((rest.rateRangeTo) ? (validator.isDecimal((rest.rateRangeTo.toString())) ? '' : "Rate range is invalid") : 'Rate range is required') : error.rateRangeTo;
          
     
         if (rest.rateRangeFrom && rest.rateRangeTo) {
            
             error.rateRangeFrom = (showRateRange) ? ((Number(rest.rateRangeFrom) > Number(rest.rateRangeTo)) ? "Invalid rate range" : error.rateRangeFrom) : "";
             error.rateRangeTo = (showRateRange) ? ((Number(rest.rateRangeFrom) > Number(rest.rateRangeTo)) ? "Invalid rate range" : error.rateRangeTo) : "";

        }

        values.dailyRate = (values.dailyRate == '') ? null : values.dailyRate;
        values.fixedRate = (values.fixedRate == '') ? null : values.fixedRate;
        values.hourlyRate = (values.hourlyRate == '') ? null : values.hourlyRate; 
     
     
       if(showRateRange == true)
      {
       
         error.dailyRate = '';
         error.fixedRate = '';
         error.hourlyRate = '';
           values.dailyRate = null;
           values.fixedRate = null;
           values.hourlyRate = null;

           if (values.rateType) {

               console.log("valuesvaluesvalues", values);

            if (values.rateType.label.toLowerCase() == "daily") {               

                error.rateRangeTo = ((rest.rateRangeTo < 10) || (rest.rateRangeTo > 5000)) ? "Rate range between 10-5000" : error.rateRangeTo;
                error.rateRangeFrom = ((rest.rateRangeFrom < 10) || (rest.rateRangeFrom > 5000)) ? "Rate range between 10-5000" : error.rateRangeFrom;
               
           
            }
            if (values.rateType.label.toLowerCase() == "fixed") {
                error.rateRangeTo = ((rest.rateRangeTo < 10) || (rest.rateRangeTo > 5000)) ? "Rate range between 10-5000" : error.rateRangeTo;
                error.rateRangeFrom = ((rest.rateRangeFrom < 10) || (rest.rateRangeFrom > 5000)) ? "Rate range between 10-5000" : error.rateRangeFrom;
            
            }
            if (values.rateType.label.toLowerCase() == "hourly") {
                error.rateRangeTo = ((rest.rateRangeTo < 3) || (rest.rateRangeTo > 500)) ? "Rate range between 3-500" : error.rateRangeTo;
                error.rateRangeFrom = ((rest.rateRangeFrom < 3) || (rest.rateRangeFrom > 500)) ? "Rate range between 3-500" : error.rateRangeFrom;
            
            }
        }

    }
       
      if (showRateRange == false)
      {
          values.rateRangeTo = null;
          values.rateRangeFrom = null;

        if(!rest.dailyRate && !rest.fixedRate && !rest.hourlyRate)
        { 
            error.dailyRate ='Daily Rate/Fixed Rate/Hourly Rate  is required';
            error.fixedRate = "Daily Rate/Fixed Rate/Hourly Rate  is required";
            error.hourlyRate = "Daily Rate/Fixed Rate/Hourly Rate  is required";
        }
        
        
        if (rest.dailyRate && !rest.fixedRate && !rest.hourlyRate)
        {   
 
         error.fixedRate = "";
         error.hourlyRate = "";

            if (values.dailyRate && (validator.isDecimal(values.dailyRate.toString())))
            { 
            
                if( values.dailyRate < 10 || values.dailyRate > 5000)
                {
                    error.dailyRate ='Daily rate range is 10-5000';
                }
                else
                {
                    error.dailyRate = '';
                } 
           }
            else {
                error.dailyRate = 'Daily rate is invalid';  
           }
      }
       if (!rest.dailyRate && rest.fixedRate && !rest.hourlyRate)
       { 
        error.dailyRate = "";
        error.hourlyRate = "";

         if (values.fixedRate && (validator.isDecimal(values.fixedRate.toString())))
        { 
        if( values.fixedRate < 10 || values.fixedRate > 5000)
        {
            error.fixedRate ='Fixed rate range is 10-5000';
        }
        else{
        error.fixedRate =''; } 
        }
        else{  error.fixedRate ='Fixed rate is invalid';

        }

       }
        
       if (!rest.dailyRate && !rest.fixedRate && rest.hourlyRate)
       { 
        error.dailyRate = "";
        error.fixedRate = "";
           if (values.hourlyRate && (validator.isDecimal(values.hourlyRate.toString())))
        {
        if( values.hourlyRate < 3 || values.hourlyRate > 500)
        {
            error.hourlyRate ='Hourly rate range is 3-500';
        }
        else{
        error.hourlyRate =''; } 
        }
        else{

            error.hourlyRate ='Hourly rate is invalid';

        }

       } 

    } 


        error.rateType = (showRateRange) ? (!rest.rateType) ? "Rate Type is required" : error.rateType : "";
        console.log("error", error);
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }

    async function updateProfile(posted) {
        if (checkValidity()) {
            setErrorMessage("");
            gContext.setLoaderModalVisible(true);

            var data = (posted) ? { ...values, ["statusId"]: 200, ['descText']: editorText.replaceAll(/\\/g, ""), ['desc']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`).replaceAll(/\\/g, "").replaceAll("&","") )} : { ...values, ["statusId"]: 100, ['descText']: editorText, ['desc']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)) };

            
            try {
                const result = await UpdateClientJob(data);
                console.log("result", result);
                if (result.data.jobUpdate.responseData.code == "200") {
                    setMessage(true);
                    gContext.setCurrentPage('1');
                    if (posted) {
                        gContext.setSuccessMessage(ConfirmMessages.JobPostedMessage);
                        gContext.toggleSuccessModal(); }
                }
                else {
                    setErrorMessage(JSON.parse(result.data.jobUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
               
                setErrorMessage("Server error");
            }
        }

        setTimeout(() => {
            gContext.setLoaderModalVisible(false);

        }, 2000);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    }
    useEffect(() => {
        if (showRateRange)
            setValues({
                ...values, ["dailyRate"]: null, ["fixedRate"]: null, ["fixedRate"]:null });
            else
            setValues({ ...values, ["rateTypeId"]: 0, ["rateType"]: "", ["rateRangeFrom"]: null, ["rateRangeTo"]: null });
    }, [showRateRange]);
  
    const handleLeave = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {

            case "dailyRate":
                error.dailyRate = (value != '') ? validator.isDecimal(value) ? "" : "Daily rate is invalid" : '';

                error.dailyRate = (value <= 5000) ? "" : "Max value  is 5000";

                break;
            case "fixedRate":
                error.fixedRate = (value != '') ? validator.isDecimal(value) ? "" : "Fixed rate is invalid" : '';
                break;
        }

        setValues({ ...values, error, [name]: value });
    }

    const handleSelectChange = (e, name) => {

        const value = e;

        let error = { ...values.error };
        switch (name) {
            case "weeklyHrs":
                error.weeklyHrs = "";
                break;
            case "expLevel":
                error.expLevel = "";
                break;
            case "country":
                if ((values.state.toLowerCase() == 'new york' || values.state.toLowerCase() == 'ny') && value.code == "US") {
                    error.dailyRate = ''; error.hourlyRate = ""; error.fixedRate = "";
                    setShowRateRange(true);
                }
                else {
                    error.rateRangeFrom = ''; error.rateRangeTo = "";
                    setShowRateRange(false);
                }
                error.country = "";
                break;
            case "projLength":
                error.projLength = "";
                break;
            case "industryType":
                error.industryType = "";
                break;
            case "rateType":
                error.rateType = "";
                error.rateRangeFrom = "";
                error.rateRangeTo = "";
                break;
            default:
                break;
        }
        if ((values.state.toLowerCase() == 'new york' || values.state.toLowerCase() == 'ny') && name == "country" && value.code == "US") {
            setValues({ ...values, error, [name]: value, [name + 'Id']: value.value, dailyRate: null, hourlyRate: null, fixedRate: null });

        }
        else if ((values.state.toLowerCase() != 'new york' && values.state.toLowerCase() != 'ny') && name == "country" && value.code == "US") {
            setValues({ ...values, error, [name]: value, [name + 'Id']: value.value, rateRangeFrom: null, rateRangeTo: null });
        }
        else if (name == "rateType") {
            setValues({ ...values, error, [name]: value, [name + 'Id']: value.value, ["rateRangeFrom"]: null, ["rateRangeTo"]: null });
        }
        else {
            setValues({ ...values, error, [name]: value, [name + 'Id']: value.value });
        }

    }
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        let data = { ...values };


        switch (name) {
            case "title":
                error.title = validator.isEmpty(value.trim()) ? "Title is required" : "";
                break;
            case "dailyRate":
               
                error.dailyRate = (value != '') ? validator.isDecimal(value) ? ((value < 10) || (value > 5000)) ? "Daily rate range is 10-5000" : "" : "Daily rate is invalid" : '';
                error.hourlyRate = "";
                error.fixedRate = "";
                break;
            case "fixedRate":
                
                error.fixedRate = (value != '') ? validator.isDecimal(value) ? ((value < 10) || (value > 5000)) ? "Fixed rate range is 10-5000" : "" : "Fixed rate is invalid" : '';
                error.hourlyRate = "";
                error.dailyRate = "";
                break;
            case "hourlyRate":
               
                error.hourlyRate = (value != '') ? validator.isDecimal(value) ? ((value < 3) || (value > 500)) ? "Hourly rate range is 3-500" : "" : "Hourly rate is invalid" : '';
                error.dailyRate = ""; error.fixedRate = "";
                break;
            case "rateRangeFrom":
                error.rateRangeFrom = (value != '') ? (validator.isDecimal(value) ? ((data.rateRangeTo) ? ((Number(data.rateRangeTo) < value) ? "Rate range is invalid" : '') : '') : "Rate range is invalid") : '';
                if (error.rateRangeFrom == "") {
                    if (values.rateType) {
                        if (values.rateType.label.toLowerCase() == "daily") {
                            error.rateRangeFrom = ((value < 10) || (value > 5000)) ? "Rate range between 10-5000" : "";
                        }
                        if (values.rateType.label.toLowerCase() == "fixed") {
                            error.rateRangeFrom = ((value < 10) || (value > 5000)) ? "Rate range between 10-5000" : "";
                        }
                        if (values.rateType.label.toLowerCase() == "hourly") {
                            error.rateRangeFrom = ((value < 3) || (value > 500)) ? "Rate range between 3-500" : "";
                        }
                    }
                }
                break;
            case "rateRangeTo":
                error.rateRangeTo = (value != '') ? (validator.isDecimal(value) ? ((data.rateRangeFrom) ? ((Number(data.rateRangeFrom) > value) ? "Rate range is invalid" : '') : '') : "Rate range is invalid") : '';
                if (error.rateRangeTo == "") {
                    if (values.rateType) {
                        if (values.rateType.label.toLowerCase() == "daily") {
                            error.rateRangeTo = ((value < 10) || (value > 5000)) ? "Rate range between 10-5000" : "";
                        }
                        if (values.rateType.label.toLowerCase() == "fixed") {
                            error.rateRangeTo = ((value < 10) || (value > 5000)) ? "Rate range between 10-5000" : "";
                        }
                        if (values.rateType.label.toLowerCase() == "hourly") {
                            error.rateRangeTo = ((value < 3) || (value > 500)) ? "Rate range between 3-500" : "";
                        }
                    }
                }
                break;
            case "state":
                if (values.country && values.country.code == "US" && name == 'state' && (value.toLowerCase() == 'new york' || value.toLowerCase() == 'ny')) {
                    error.dailyRate = ''; error.hourlyRate = ""; error.fixedRate = "";
                    setShowRateRange(true);
                }
                else { setShowRateRange(false); }

                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }

        if (values.country && values.country.code == "US" && name == 'state' && (value.toLowerCase() == 'new york' || value.toLowerCase() == 'ny')) { setValues({ ...values, error, [name]: value, dailyRate: null, hourlyRate: null, fixedRate: null }); }
        if (values.country && values.country.code == "US" && name == 'state' && (value.toLowerCase() != 'new york' && value.toLowerCase() != 'ny')) { setValues({ ...values, error, [name]: value, rateRangeFrom: null, rateRangeTo: null }); }
        else if ((name == "fixedRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["dailyRate"]: null });
        }
        else if ((name == "dailyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null });
        }
        else { setValues({ ...values, error, [name]: value }); }

    }

    function removeSelectedSkill(id) {

        const selected = skills.filter(item => item["id"] == id);

        if (selected.length > 0) {

            setChanges('skillSet', [...selectedSkillData, selected[0]]);
            setSelectedSkillData([...selectedSkillData, selected[0]]);
        }
        const removed = skills.filter(item => item["id"] != id);
        setSkillsList(removed);


    }
    function addToSkills(id) {
        if (skills) {
            const deleted = selectedSkillData.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                setSkillsList([...skills, deleted[0]]);
            }
            const added = selectedSkillData.filter(item => item["id"] != id);
            setSelectedSkillData(added);
            setChanges('skillSet', added);

        }
    }
    function addToCompCategory(id, code) {

        if (compCategory) {
            const deleted = selectedCompCategory.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                let result = [...compCategory, deleted[0]];
                result.sort((a, b) => {
                    return a.code - b.code;
                });
                setCompCategory(result);
            }
            const added = selectedCompCategory.filter(item => item["id"] != id);

            setSelectedCompCategory(added);
            setChanges('compCategory', added);

        }
    }
    function removeCompCategory(id, code) {

        const selected = compCategory.filter(item => item["id"] == id);

        if (selected.length > 0) {

            setChanges('compCategory', [...selectedCompCategory, selected[0]]);
            setSelectedCompCategory([...selectedCompCategory, selected[0]]);
        }
        const removed = compCategory.filter(item => item["id"] != id);
        setCompCategory(removed);

    }
    function removeCompliance(id) {
        const selected = compliances.filter(item => item["id"] == id.toString());
        if (selected.length > 0) {
            setChanges('compliance', [...selectedCompliances, selected[0]]);
            setSelectedCompliance([...selectedCompliances, selected[0]]);
        }
        const removed = compliances.filter(item => item["id"] != id.toString());
        setComplianceList(removed);
    }
    function addToCompliance(id) {
        if (compliances) {

            const deleted = selectedCompliances.filter(item => item["id"] == id.toString());

            if (deleted.length > 0) {
                setComplianceList([...compliances, deleted[0]]);
            }
            const added = selectedCompliances.filter(item => item["id"] != id.toString());
            setSelectedCompliance(added);
            setChanges('compliance', added);
        }
    }
    function setChanges(fields, lists) {

        let value = '';
        lists.map((data) => (value += data.id + ','));
        let error = { ...values.error };
        if (value !== "")
            error[fields] = '';
        setValues({ ...values, error, [fields]: (value !== '') ? value.substring(0, value.length - 1) : '' });

    }


    async function getUtils() {

        const result = await GetJobUtils();
        if (result.data && result.data.jobUtils && result.data.jobUtils.responseData.code =="200") {
            
            let data = JSON.parse(result.data.jobUtils.responseData.message);
            

            setExperienceList(data.expLevels);
            setWeeklyHours(data.weeklyHours);
            setProjectLengthList(data.projectLengths);
            setSkillsList(data.skills);
            setFullSkillSet(data.skills);
            setComplianceList(data.complianceTypes);
            setFullCompliance(data.complianceTypes);            
            setIndustryTypeList(data.industryTypes);
            setFullIndustryType(data.industryTypes);            
            setCompCategory(data.complianceCategory);
            setFullComplianceCategory(data.complianceCategory);
          

            if (data.rateTypes) {
                let type = [];
                data.rateTypes.map((value) => (
                    type.push({ code: value.code, value: value.id, label: value.rate })));

                setRateType(type);
            }

        }
        if (!gContext.jobsDetail.currentJobId)
            setLoaded(true); 

    }

    function cancelEdit() {

        if (gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode) {
            BindJobs(gContext.jobsDetail.currentJobId.guid);

        } else {


            setEditLength(0);
            setEditvalue("");
            seteditorText("");
            setSkillsList(fullSkillSet);
            setComplianceList(fullCompliance);
            setIndustryTypeList(fullIndustryType);
            setCompCategory(fullComplianceCategory);
            setSelectedCompCategory([]);
            setSelectedCompliance([]);
            setSelectedIndustryData([]);
            setSelectedSkillData([]);

            //  gContext.setCurrentPage('1');
            setInitialValues({ rateTypeId: 0, rateType: "", industryTypeId: 0, industryType: "", compCategory: '', id: null, rateRangeFrom: null, rateRangeTo: null, jobType: 'REMOTE', clientId: gContext.userInfo.userID, fixedRate: null, hourlyRate: null, projLengthId: 0, projLength: null, city: '', state: '', countryId: 0, country: null, title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', skillSet: "", compliance: "", error: { rateRangeFrom: '', rateRangeTo: '', fixedRate: '', hourlyRate: '', title: '', expLevel: "", jobType: '', dailyRate: "", fixedRate: "", weeklyHrs: "", desc: "", skillSet: "", compliance: "", city: '', state: '', country: '', projLength: '', compCategory: '', industryType: '', rateType: "" } });
            setValues({ rateTypeId: 0, rateType: "", industryTypeId: 0, industryType: "", compCategory: '', id: null, rateRangeFrom: null, rateRangeTo: null, jobType: 'REMOTE', clientId: gContext.userInfo.userID, fixedRate: null, hourlyRate: null, projLengthId: 0, projLength: null, city: '', state: '', countryId: 0, country: null, title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', skillSet: "", compliance: "", error: { rateRangeFrom: '', rateRangeTo: '', fixedRate: '', hourlyRate: '', title: '', expLevel: "", jobType: '', dailyRate: "", fixedRate: "", weeklyHrs: "", desc: "", skillSet: "", compliance: "", city: '', state: '', country: '', projLength: '', compCategory: '', industryType: '', rateType: "" } });
}
                   
      
    }
    function loadUtils() {

        const exp = experience.filter(item => item["value"] == values.expLevelId);
        const hours = weeklyHours.filter(item => item["value"] == values.weeklyHrsId);
        const coun = gContext.countriesList.filter(item => item["value"] == values.countryId);
        const projLen = projectLengthList.filter(item => item["value"] == values.projLengthId);
        setValues({ ...values, ['country']: (coun.length > 0) ? coun[0] : null, ['projLength']: (projLen.length > 0) ? projLen[0] : null, ['weeklyHrs']: (hours.length > 0) ? hours[0] : null, ['expLevel']: (exp.length > 0) ? exp[0] : null });
        setInitialValues({ ...initialValues, ['country']: (coun.length > 0) ? coun[0] : null, ['projLength']: (projLen.length > 0) ? projLen[0] : null, ['weeklyHrs']: (hours.length > 0) ? hours[0] : null, ['expLevel']: (exp.length > 0) ? exp[0] : null });

    }
    function removeSelectedIndustryType(id) {

        const selected = industryTypeList.filter(item => item["id"] == id);

        if (selected.length > 0) {

            setChanges('industryType', [...selectedIndustryData, selected[0]]);
            setSelectedIndustryData([...selectedIndustryData, selected[0]]);
        }
        const removed = industryTypeList.filter(item => item["id"] != id);
        setIndustryTypeList(removed)

    }

    function addToIndustryType(id) {
        if (industryTypeList) {
            const deleted = selectedIndustryData.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                setIndustryTypeList([...industryTypeList, deleted[0]]);
            }
            const added = selectedIndustryData.filter(item => item["id"] != id);

            setSelectedIndustryData(added);
            setChanges('industryType', added);

        }
    }

    async function BindJobs(guid) {
        try {
             
            const result = await ClientJobsRead((guid) ? guid : gContext.jobsDetail.currentJobId.guid);

            if (result.data.jobRead.responseData.code == "200") {

                let job = JSON.parse(result.data.jobRead.responseData.message);

                console.log("jobjobjob", job);
                
                if (job.length > 0) {
                    let jobData = { ...values };
                    const exp = experience.filter(item => item["value"] == job[0].explevelid);
                    const hours = weeklyHours.filter(item => item["value"] == job[0].weeklyhrsid);
                    const coun = gContext.countriesList.filter(item => item["value"] == job[0].countryid);
                    const projLen = projectLengthList.filter(item => item["value"] == job[0].projlenid);

                    jobData.id = ((gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode)) ? gContext.jobsDetail.currentJobId.id : null;
                    jobData.title = job[0].title;
                    jobData.dailyRate = (job[0].dailyrate) ? parseInt(job[0].dailyrate) : null;
                    jobData.hourlyRate = (job[0].hourlyrate) ? parseInt(job[0].hourlyrate) : null;
                    jobData.fixedRate = (job[0].fixedrate) ? parseInt(job[0].fixedrate) : null;
                    jobData.jobType = job[0].type;
                    jobData.desc = job[0].desc;
                    jobData.city = job[0].city;
                    jobData.state = job[0].state;
                    jobData.skillSet = job[0].skillids;
                    jobData.industryType = job[0].indtypeids;
                    jobData.rateRange = job[0].raterange;
                    jobData.compliance = job[0].compids;
                    jobData.compCategory = job[0].compcatids;
                    jobData.rateRangeFrom = (job[0].ratefrom) ? parseInt(job[0].ratefrom) : null;
                    jobData.rateRangeTo = (job[0].rateto) ? parseInt(job[0].rateto) : null;
                    jobData.expLevelId = (job[0].explevelid) ? job[0].explevelid : 0;
                    jobData.weeklyHrsId = (job[0].weeklyhrsid) ? job[0].weeklyhrsid : 0;
                    jobData.projLengthId = (job[0].projlenid) ? job[0].projlenid : 0;
                    jobData.countryId = (job[0].countryid) ? job[0].countryid : 0;
                    jobData.expLevel = (exp.length > 0) ? exp[0] : null;
                    jobData.weeklyHrs = (hours.length > 0) ? hours[0] : null;
                    jobData.country = (coun.length > 0) ? coun[0] : null;
                    jobData.projLength = (projLen.length > 0) ? projLen[0] : null;
                    jobData.rateTypeId = (job[0].raterangeid) ? job[0].raterangeid : 0;
                    jobData.rateType = (job[0].raterangeid) ? { code: job[0].raterangecode, value: job[0].raterangeid, label: job[0].raterangetype } : null;

                    jobData.error.desc = "";
                    if (job[0].countrycode == "US" && (job[0].state.toLowerCase() == 'new york' || job[0].state.toLowerCase() == 'ny')) { jobData.fixedRate = null; jobData.hourlyRate = null; jobData.dailyRate = null; setShowRateRange(true); }
                    else
                        setShowRateRange(false);


                    BindCategory(job[0].compcatids);
                    BindSkills(job[0].skillids);
                    BindIndustry(job[0].indtypeids);
                    setInitialValues(jobData);
                    setValues(jobData); 
                    setEditLength(job[0].desc.replace(/(<([^>]+)>)/ig, '').length);
                    values.dailyRate = (values.dailyRate == '') ? null : values.dailyRate;
                    values.fixedRate = (values.fixedRate == '') ? null : values.fixedRate;
                    values.hourlyRate = (values.hourlyRate == '') ? null : values.hourlyRate; 
                  
                   
                    BindCompliance(job[0].compids, job[0].desc);


                    let { error, ...rest } = values;
                  
                    error.title = '';
                    error.city = '';
                    error.compCategory = '';
                    error.compliance = '';
                    error.country = '';
                    error.dailyRate = '';
                    error.expLevel = '';
                    error.fixedRate = '';
                    error.hourlyRate = '';
                    error.industryType = '';
                    error.jobType = '';
                    error.projLength = '';
                    error.state = '';
                    error.skillSet = '';
                    error.weeklyHrs = ''; 

                }

            }

            setTimeout(() => {
                if (!guid)
                    getUtils();
                setTimeout(() => {
                    gContext.setLoaderModalVisible(false);
                    setLoaded(true);

                }, 1000);
            }, 1000);

        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);

        }
    }
    useEffect(() => {
        document.title = "Job | Syncuppro";

        BindExistingJobs();
        if (gContext.jobsDetail.currentJobId != null) { BindJobs(null); }
        else { getUtils(); }
    }, []);
    useEffect(() => {

        if (gContext.jobsDetail.currentJobId != null) { BindJobs(null); }
    }, [gContext.jobsDetail]);
    useEffect(() => {
        loadUtils();
    }, [projectLengthList]);
    useEffect(() => {
        loadUtils();
    }, [gContext.countriesList]);
    useEffect(() => {
        loadUtils();
    }, [experience]);
    useEffect(() => {
        loadUtils();
    }, [weeklyHours]);


    function BindCategory(data) {

        let selected = [];
        let removedlist = [];
        if (data && data != "") {
            data.split(',').forEach((value) => {
                let removed = [];
                let data = fullComplianceCategory.filter(item => item["id"] == value);
                if (data.length > 0) { selected.push(data[0]) }
                removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : fullComplianceCategory.filter(item => item["id"] != value);
                removedlist = removed;
            });
        }
        setSelectedCompCategory(selected)
        setCompCategory(removedlist);


    }
    function BindCompliance(data, desc) {
         
        let selected = [];
        let removedlist = [];
        data.split(',').forEach((value) => {
            let removed = [];
            let data = fullCompliance.filter(item => item["id"] == value);
            if (data.length > 0) { selected.push(data[0]) }
            removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : fullCompliance.filter(item => item["id"] != value);
            removedlist = removed;
        });
        setSelectedCompliance(selected)
        setComplianceList(removedlist);
 
    }
    function BindSkills(data) {

        let selected = [];
        let removedlist = [];
        data.split(',').forEach((value) => {
            let removed = [];
            let data = fullSkillSet.filter(item => item["id"] == value);
            if (data.length > 0) { selected.push(data[0]) }
            removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : fullSkillSet.filter(item => item["id"] != value);
            removedlist = removed;
        });
        setSelectedSkillData(selected)
        setSkillsList(removedlist);
    }
    function BindIndustry(data) {
        let selected = [];
        let removedlist = [];
        data.split(',').forEach((value) => {
            let removed = [];
            let data = fullIndustryType.filter(item => item["id"] == value);

            if (data.length > 0) { selected.push(data[0]) }
            removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : fullIndustryType.filter(item => item["id"] != value);
            removedlist = removed;
        });
        setSelectedIndustryData(selected)
        setIndustryTypeList(removedlist);
    }

    useEffect(() => {
     
        setEditvalue(values.desc);
    }, [values.desc]);

    useEffect(() => {
        if (values.industryType && values.industryType != "") {
            BindIndustry(values.industryType);
        }
    }, [fullIndustryType]);
    useEffect(() => {
        if (values.compCategory && values.compCategory != "") { BindCategory(values.compCategory); }
        setShowLoader(false);
    }, [fullComplianceCategory]);
    useEffect(() => {
        if (values.skillSet && values.skillSet != "") { BindSkills(values.skillSet); }
    }, [fullSkillSet]);
    useEffect(() => {
        if (values.compliance && values.compliance != "") { BindCompliance(values.compliance, values.desc); }
    }, [fullCompliance]);

    return (
        <>
            {loaded &&                <>

                <span className="screen-darken"></span>

                <div className="page-wrapper">


                    <div className="breadcrumb">
                        <div className="container-fluid">

                            <div className="breadcrumb-wrapper">
                                <h4>  {(gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode) ? "Edit Job" : "Create Job"}</h4>

                                <div className="info-meta">
                                    <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / {(gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode) ? "Edit Job" : "Create Job"}</span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <section className="section create-job-block">
                        <div className="container-fluid">
                            <div className="card panel p-0">
                                <div className="card-body p-b-0">
                                    <div className="row  d-none">
                                        {message && <Message message={'Jobs created successfully '} />}
                                        {errorMessage !== "" && <><span className="error py-10   w-auto  font-weight-semibold line-height-reset px-8   font-size-4">{errorMessage} </span><br /></>}
                                    </div>
                                    <div className="row">
                                        {titlesList.length > 0 && <>
                                            
                                            {(gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode) ? <></> : <> <div className="col-lg-3 col-md-5 col-sm-6">
                                                <div className="forn-group-input">
                                                    <label for="first name" className="form-label d-flex m-b-0"> Select from existing jobs</label>
                                                </div>
                                            </div>

                                                <div className="col-lg-9 col-md-7 col-sm-6">
                                                    <div className="forn-group-input">
                                                        <Select name="title" value={existingTitles}
                                                            options={titlesList} placeholder="Select..."
                                                            className={`form-select padding-select`}
                                                            border={false} onChange={(e) => handleTitleSelectChange(e)}
                                                        />
                                                    </div>
                                                </div></>}
                                        </>}
                                       

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="forn-group-input">
                                                <label for="job title" className="form-label m-b-10">Job Title <span className="text-red"> * </span></label>
                                                <input type="text" defaultValue={values.title} value={values.title}
                                                    className={`form-control   ${(values.error.title.length > 0) ? ' error' : ''}`}
                                                    id="Title"
                                                    name="title"
                                                    placeholder="eg.Software Engineer" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                />{values.error.title.length > 1 && (
                                                    <span className="text-red">{values.error.title}</span>
                                                )}
                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">
                                                    <label for="experience level" className="form-label m-b-10">Experience Level <span className="text-red"> * </span></label>
                                                <Select name="expLevel" value={values.expLevel}
                                                    options={experience} placeholder="Select..."
                                                    className={`form-select padding-select   ${(values.error.expLevel.length > 0) ? ' error' : ''}`}
                                                    border={false} onChange={(e) => handleSelectChange(e, 'expLevel')}
                                                />
                                                {values.error.expLevel.length > 1 && (
                                                    <span className="text-red">{values.error.expLevel}</span>
                                                )}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">
                                                    <label for="experience level" className="form-label m-b-10">Job Type<span className="text-red"> * </span></label>

                                                    <div className="d-flex">

                                                        <div className="d-flex align-item-center m-r-30">
                                                            <div className="form-check m-r-10">
                                                            <input className="form-check-input" type="checkbox" name="ONSITE" id="expert-check" onChange={() => handleOption('ONSITE')} checked={values.jobType == 'ONSITE'}  />
                      </div>
                                                                <span>Onsite</span>
                                                            </div>

                                                            <div className="d-flex align-item-center">
                                                                <div className="form-check m-r-10">
                                                            <input name="REMOTE"
                                                                className="form-check-input"  
                                                                type="checkbox"
                                                                id="client-check" onChange={() => handleOption('REMOTE')} checked={values.jobType == 'REMOTE'}/>
                      </div>
                                                                    <span>Remote</span>
                                                                </div>

                                                            </div>
                                            </div>
                                            {values.error.jobType.length > 1 && (
                                                <span className="text-red">{values.error.jobType}</span>
                                            )}
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="experience level" className="form-label m-b-10">Project Length<span className="text-red"> * </span></label>
                                                <Select name="projLength" value={values.projLength} options={projectLengthList}
                                                    placeholder="Select..."
                                                    className={`form-select padding-select   ${(values.error.expLevel.length > 0) ? ' error' : ''}`}
                                                    border={false} onChange={(e) => handleSelectChange(e, 'projLength')}
                                                /> {values.error.projLength.length > 1 && (
                                                    <span className="text-red">{values.error.projLength}</span>
                                                )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="experience level" className="form-label m-b-10">Weekly hours<span className="text-red"> * </span></label>
                                                <Select options={weeklyHours}
                                                    placeholder="Select..." value={values.weeklyHrs}
                                                    className={`form-select padding-select   ${(values.error.weeklyHrs.length > 0) ? 'error' : ''}`}
                                                    border={false} onChange={(e) => handleSelectChange(e, 'weeklyHrs')}
                                                />
                                                {values.error.weeklyHrs.length > 1 && (
                                                    <span className="text-red">{values.error.weeklyHrs}</span>
                                                )}
                                                        </div>
                                                    </div>

                                                    <div className="company-desc">
                                                        <div className="desc-info">
                                                            <label for="first name" className="form-label m-b-0"><b>Job Detail</b><span className="text-red"> * (Max 4000 character)</span></label>
                                                <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green"> {4000 - ((editLength != 0) ? editLength : 0)}</span></p>
                                                        </div>

                                            <ReactQuill modules={modules} className="m-b-40 " style={{height:"150px"}} preserveWhitespace={true}
                                                formats={formats} theme="snow" id="editor"  
                                                onKeyDown={editorKeyDown}
                                                ref={reactQuillRef}
                                                value={editvalue} onChange={editorChange}
                                            ></ReactQuill>
                                            {values.error.desc.length > 1 && (
                                                <span className="text-red m-t-5">{values.error.desc}</span>
                                            )}
                                        </div>


                                        <div className="row">

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">
                                                    <label for="first name" className="form-label m-b-10">Country<span className="text-red"> * </span></label>
                                                    <Select
                                                        options={gContext.countriesList} value={values.country}
                                                        className={`form-select padding-select  ${(values.error.country.length > 0) ? ' error' : ''}`}

                                                        border={false} onChange={(e) => handleSelectChange(e, 'country')}
                                                    />{values.error.country.length > 1 && (
                                                        <span className="text-red">{values.error.country}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">
                                                    <label for="state" className="form-label m-b-10">State<span className="text-red"> * </span></label>
                                                    <input name="state"
                                                        type="text" defaultValue={values.state} value={values.state}
                                                        className={`form-control   ${(values.error.state.length > 0) ? ' error' : ''}`}
                                                        id="state"
                                                        placeholder="State" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                    />{values.error.state.length > 1 && (
                                                        <span className="text-red">{values.error.state}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">
                                                    <label for="city" className="form-label m-b-10">City<span className="text-red"> * </span></label>
                                                    <input
                                                        name="city" defaultValue={values.city} value={values.city}
                                                        type="text"
                                                        className={`form-control   ${(values.error.city.length > 0) ? ' error' : ''}`}
                                                        id="city"
                                                        placeholder="City" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                    />{values.error.city.length > 1 && (
                                                        <span className="text-red">{values.error.city}</span>
                                                    )}
                                                </div>
                                            </div>

                                            

                                        </div>

                                        <div className="col-lg-12 ">   <div className="forn-group-input m-b-0 p-b-0">
                                            <label
                                               
                                                className="form-label d-flex"
                                            >
                                                Rate <OverlayTrigger trigger="hover" placement="auto" overlay={popoverRate} ><span variant="primary" className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger>
                                            </label></div> </div>
                                        {showRateRange && <>  <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="forn-group-input">
                                                <label for="dailyRate" className="form-label m-b-10">Daily Rate (USD)</label>
                                                <input name="dailyRate" id="dailyRate" type="text" className={`form-control   ${(values.error.dailyRate.length > 0) ? ' error' : ''}`} disabled={showRateRange} />
                                            </div>
                                        </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="forn-group-input">
                                                    <label for="fixedRate" className="form-label m-b-10">Fixed Rate (USD)</label>
                                                    <input name="fixedRate" id="fixedRate" type="text" className={`form-control   ${(values.error.fixedRate.length > 0) ? 'error' : ''}`} disabled={showRateRange} />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="forn-group-input">
                                                    <label for="hourlyRate" className="form-label m-b-10">Hourly Rate (USD)</label>
                                                    <input type="text" name="hourlyRate" id="hourlyRate" className={`form-control   ${(values.error.hourlyRate.length > 0) ? 'error' : ''}`} disabled={showRateRange} />
                                                </div>
                                            </div></>}
                                        {!showRateRange && <>  <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="forn-group-input">
                                                <label for="first name" className="form-label m-b-10">Daily Rate (USD)</label>
                                                {(values.fixedRate) || (values.hourlyRate) ?
                                                    <><input name="dailyRate1" disabled='true'
                                                        type="text" className={`form-control`} value={values.dailyRate ? '' : ''}
                                                        id="dailyRate" /></> :
                                                    <><input name="dailyRate"
                                                        type="text" value={values.dailyRate}
                                                        className={`form-control   ${(values.error.dailyRate.length > 0) ? ' error' : ''}`}
                                                        id="dailyRate" onKeyUp={handleFieldsChange}
                                                        placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                                    /></>}
                                                {values.error.dailyRate.length > 1 && (
                                                    <span className="text-red">{values.error.dailyRate}</span>
                                                )}
                                            </div>
                                        </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="forn-group-input">
                                                    <label for="last name" className="form-label m-b-10">Fixed Rate (USD)</label>
                                                    {(values.dailyRate) || (values.hourlyRate) ? <><input name="fixedRate1" disabled='true'
                                                        type="text" className={`form-control`} value={values.fixedRate ? '' : ''}
                                                        id="fixedRate1"
                                                    /></> : <><input name="fixedRate"
                                                        type="text" className={`form-control   ${(values.error.fixedRate.length > 0) ? ' error' : ''}`}

                                                        id="dailyRate" defaultValue={values.fixedRate} value={values.fixedRate} onKeyUp={handleFieldsChange}
                                                        placeholder=" " onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                                    /></>}

                                                    {values.error.fixedRate.length > 1 && (
                                                        <span className="text-red">{values.error.fixedRate}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="forn-group-input">
                                                    <label for="phonenumber" className="form-label m-b-10">Hourly Rate (USD)</label>
                                                    {(values.dailyRate) || (values.fixedRate) ? <><input name="hourlyRate1" disabled='true'
                                                        type="text" className={`form-control`} value={values.hourlyRate ? '' : ''}
                                                        id="hourlyRate1"
                                                    /></> : <><input name="hourlyRate"
                                                        type="text" className={`form-control   ${(values.error.hourlyRate.length > 0) ? ' error' : ''}`}

                                                        id="dailyRate" defaultValue={values.hourlyRate} value={values.hourlyRate} onKeyUp={handleFieldsChange}
                                                        placeholder=" " onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                                    /></>}
                                                    {values.error.hourlyRate.length > 1 && (
                                                        <span className="text-red">{values.error.hourlyRate}</span>
                                                    )}
                                                </div>
                                            </div></>}

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="forn-group-input">
                                                <label className="form-label d-flex m-b-10">Rate Range <span className="text-red p-l-5"> * </span> <OverlayTrigger trigger="hover" placement="auto" overlay={popoverRateRange} ><span variant="primary" className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger> </label>


                                                {showRateRange && <div className="row">
                                                    <div className="col-sm-4 m-b-10">
                                                        <Select name="expLevel" value={values.rateType}
                                                            options={rateType} placeholder="Select..."
                                                            className={`form-select padding-select  ${((values.error.rateType) && values.error.rateType.length > 0) ? 'form-control-error' : ''}`}
                                                            border={false} onChange={(e) => handleSelectChange(e, 'rateType')}
                                                        />
                                                        {values.error.rateType && values.error.rateType.length > 1 && (
                                                            <span className="text-red">{values.error.rateType}</span>
                                                        )}
                                                    </div>   <div className="col-sm-1">
                                                        <span className="d-flex justify-content-center m-b-10 m-t-10">From</span>
                                                    </div><div className="col-sm-3">


                                                        <input
                                                            name="rateRangeFrom"
                                                            type="text" defaultValue={values.rateRangeFrom} value={values.rateRangeFrom}
                                                            className={`form-control   ${(values.error.rateRangeFrom.length > 0) ? 'error' : ''}`}
                                                            id="rateRangeFrom"
                                                              onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                        />{values.error.rateRangeFrom.length > 1 && (
                                                            <span className="text-red">{values.error.rateRangeFrom}</span>
                                                        )}</div>
                                                    <div className="col-sm-1">
                                                        <span className="d-flex justify-content-center m-b-10 m-t-10">To</span>
                                                    </div>
                                                    <div className="col-sm-3"> <input
                                                        name="rateRangeTo"
                                                        type="text" defaultValue={values.rateRangeTo} value={values.rateRangeTo}
                                                        className={`form-control   ${(values.error.rateRangeTo.length > 0) ? ' error' : ''}`}
                                                        id="rateRangeTo"
                                                         onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                    /> {values.error.rateRangeTo.length > 1 && (
                                                            <span className="text-red">{values.error.rateRangeTo}</span>
                                                    )}</div></div>}



                                                {!showRateRange && <div className="row">
                                                    <div className="col-sm-4 m-b-10">  <select
                                                        name="rateRange" disabled={!showRateRange}
                                                        type="text"
                                                        className="form-control  "
                                                        id="srateRange" options={rateType} placeholder="Select..."
                                                    /> </div><div className="col-sm-1"> <span className="d-flex justify-content-center m-b-10 m-t-10">From</span></div><div className="col-sm-3">  <input
                                                        name="rateRange" disabled={!showRateRange} defaultValue=""
                                                        type="text"
                                                        className="form-control "
                                                        id="rateRange"
                                                    /> </div><div className="col-sm-1"> <span className="d-flex justify-content-center m-b-10 m-t-10">To</span></div><div className="col-sm-3"> <input
                                                        name="rateRange" disabled={!showRateRange}
                                                        type="text" defaultValue=""
                                                        className="form-control "
                                                        id="rateRange2"

                                                    /></div> </div>
                                                }
                                            </div>
                                        </div>

                                    </div> 

                                    <div className="company-meta">
                                        <h6 className="d-flex">Compliance Standards<span className="text-red p-l-5"> * </span> </h6>
                                        {values.error.compliance.length > 1 && (
                                            <span className="text-red error font-size-3">{values.error.compliance}</span>
                                        )}
                                        {selectedCompliances.length > 0 && <>  <div className="badge-btn-group">
                                            {selectedCompliances.map((data, index) => {
                                                const { id, code } = data;
                                                return ((selectedCompliances.length > 0) ? <span title="Click to remove" data-toggle="tooltip" className="badge compliance-standard btn btn-sm pointer" onClick={() => { addToCompliance(id) }}>{code}
                                                    <button className="btn-icon text-light-gray m-l-5 m-icon material-icons" onClick={() => { addToCompliance(id) }}>close</button>
                                                </span> : '')
                                            })}
                                        </div>   <div className="divider-line-20"></div></>}
                                    </div>



                                    <div className="badge-group">

                                        {compliances.map((data, index) => {

                                            const { code, id } = data;
                                            return (
                                                <span key={index} title="Click to select compliance" data-toggle="tooltip" onClick={() => { removeCompliance(id) }} className="badge compliance-standard pointer">{code}</span>
                                            )
                                        })}


                                    </div> 

                                    <div className="company-meta">
                                        <h6 className="d-flex">Compliance Category <span className="text-red p-l-5"> * </span>
                                            <OverlayTrigger trigger="hover" placement="auto" overlay={popoverCompliance} ><span variant="primary"  className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger></h6>
                                        {values.error.compCategory.length > 1 && (
                                            <span className="text-red">{values.error.compCategory}</span>
                                        )}
                                        {selectedCompCategory.length > 0 && <>


                                            <div className="badge-btn-group">

                                                {selectedCompCategory.map((data, index) => {
                                                    const { id, code, category } = data;
                                                    return ((selectedCompCategory.length > 0) ?

                                                        <span title="Click to remove" onClick={() => { addToCompCategory(id, code) }} data-toggle="tooltip" key={index} className="badge compliance-category btn btn-sm pointer"> {category}
                                                            <button title="Click to remove" onClick={() => { addToCompCategory(id, code) }} data-toggle="tooltip" className="btn-icon text-light-gray m-l-5 m-icon material-icons">close</button>
                                                        </span>
                                                        : '')
                                                })}

                                            </div> <div className="divider-line-20"></div></>}
                                    </div>



                                    <div className="badge-group">

                                        {compCategory.map((data, index) => {
                                            const { id, code, category } = data;
                                            return (<>
                                                {(selectedCompCategory.length === 0 || (selectedCompCategory.length > 0 && selectedCompCategory[0].code == "400")) &&

                                                    <span key={index} title="Click to select" data-toggle="tooltip" className="badge compliance-category pointer" onClick={() => { removeCompCategory(id, code) }} >
                                                        {category}
                                                    </span>
                                                }
                                                {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "100") &&

                                                    <span key={index} title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="badge compliance-category pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                                        {category}
                                                    </span>
                                                }
                                                {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "200") &&

                                                    <span key={index} title={(code == "400") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code == "400")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="badge compliance-category pointer" onClick={() => { if (code == "400") { removeCompCategory(id, code) } }} >
                                                        {category}
                                                    </span>
                                                }
                                                {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "300") &&

                                                    <span key={index} title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="badge compliance-category pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                                        {category}
                                                    </span>
                                                }

                                            </>)
                                        })}



                                    </div> 
                                    <div className="company-meta">
                                        <h6>Skill Set <span className="text-red"> * </span> <OverlayTrigger trigger="hover" placement="auto" overlay={popoverSkillSet} ><span variant="primary" className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger></h6>
                                        {values.error.skillSet.length > 1 && (
                                            <span className=" text-red error font-size-3">{values.error.skillSet}</span>
                                        )}
                                        {selectedSkillData.length > 0 && <>  <div className="badge-btn-group">

                                            {selectedSkillData.map((data, index) => {
                                                const { id, skill } = data;
                                                return ((selectedSkillData.length > 0) ?
                                                    <span className="badge skills btn btn-sm pointer" onClick={() => { addToSkills(id) }} key={index}>{skill}
                                                        <button title="Click to remove" data-toggle="tooltip" className="btn-icon text-light-gray m-l-5 m-icon material-icons" onClick={() => { addToSkills(id) }}>close</button>
                                                    </span>
                                                    : '')
                                            })}

                                        </div>   <div className="divider-line-20"></div></>}

                                    </div>



                                    <div className="badge-group">

                                        {skills.map((data, index) => {
                                            const { id, skill } = data;
                                            return (
                                                <span key={index} title="Click to select" data-toggle="tooltip" className="badge skills pointer" onClick={() => { removeSelectedSkill(id) }}> {skill}</span>

                                            )
                                        })}


                                    </div> 


            <div className="company-meta">
                                                                <h6>Industry Types <span className="text-red"> * </span></h6>
                                        {values.error.industryType.length > 1 && (
                                            <span className="text-red">{values.error.industryType}</span>
                                        )}
                                        {selectedIndustryData.length > 0 && <>    <div className="badge-btn-group">
                                            
                                            {selectedIndustryData.map((data, index) => {
                                                const { id, name } = data;
                                                return ((selectedIndustryData.length > 0) ?
                                                    <span className="badge industry-types btn btn-sm pointer" title="Click to remove" data-toggle="tooltip" onClick={() => { addToIndustryType(id) }}  key={index}>{ name}
                                                        <button className="btn-icon text-light-gray m-l-5 m-icon material-icons" onClick={() => { addToIndustryType(id) }} >close</button>
                                                    </span>
                                                     : '')
                                            })}

                                        </div> <div className="divider-line-20"></div></>}

                                                            </div>                                                            

                                                            <div className="badge-group">
                                        {industryTypeList.map((data, index) => {
                                            const { id, name } = data;

                                            return (
                                                <span title="Click to select" data-toggle="tooltip" key={index} onClick={() => { removeSelectedIndustryType(id) }} className="badge industry-types pointer"> {name}</span>
                                                )
                                        })}   
                                                                
                                                            </div> 

          
           

          

             

          </div> 

          <div className="card-footer">
                                                                                <div className="panel-action">
                                        {profileValues && profileValues.length > 0 && !profileValues[0].flagged && <button type="button" className="btn btn-orange m-r-20" onClick={() => {
                                            updateProfile(true);
                                        }}>Save & Post</button>}    
                                        <button type="button" className="btn btn-gray m-r-20" onClick={() => {
                                            updateProfile(false);

                                        }}>Save as draft</button>
                                        <button type="button" className="btn btn-dark-outline" onClick={() => {
                                            cancelEdit();

                                        }}>Cancel</button>
                                                                                </div>
                                                                            </div>

                                                                        </div> 
      </div> 
    </section>



                                                            </div>


                <div
                    className={`dashboard-main-container mt-20 mt-lg-25 position-relative d-none`}
                    id="dashboard-body"
                >
                    <div className="row justify-content-center">
                        <div className="col-12 dark-mode-texts">
                            <div className="mb-9">
                                <a className="pointer" onClick={() => { cancelEdit(); }}>
                                    <a className="d-flex align-items-center ml-4 pointer " onClick={() => { cancelEdit(); }} >
                                        <i className="icon icon-small-left bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-7 text-white font-weight-bold shadow-8"></i>
                                        <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                                            Back
                      </span>
                                    </a>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container">


                        <div className="mb-15 mb-lg-23">
                            <div className="row">
                                <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                    <div className="row ">
                                        {message && <Message message={'Jobs created successfully '} />}
                                        {errorMessage !== "" && <><span className="error py-10   w-auto  font-weight-semibold line-height-reset px-8   font-size-4">{errorMessage} </span><br /></>}
                                    </div>
                                </div>
                                <div className="col-xxxl-12  ">


                                    <>  <h5 className="font-size-8 font-weight-semibold mb-11">

                                    </h5>
                                        <div className="contact-form bg-white shadow-8 ">
                                            <div className="font-size-6 font-weight-semibold mb-11   text-gray bg-gray-opacity-2 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                                {(gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode) ? "Edit Job" : "Create Job"}
                                            </div>      <div className={(gContext.jobsDetail.currentJobId && !gContext.jobsDetail.mode) ? "d-none" : ""}>
                                                <a className="col-lg-3 ml-0"></a>
                                                <a className=" text-black-2 font-size-4 font-weight-semibold mb-4"> Create Job  <OverlayTrigger trigger="hover" placement="auto" overlay={popoverExisting} ><span variant="primary" className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger> </a>
                                                <a className="col-lg-4 ">
                                                    <div className="form-group">
                                                        <Select name="title" value={existingTitles}
                                                            options={titlesList} placeholder="Select..."
                                                            className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 `}
                                                            border={false} onChange={(e) => handleTitleSelectChange(e)}
                                                        />

                                                    </div>
                                                </a>
                                            </div>
                                            <form action="/" className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                                <fieldset>

                                                    <div className="row mb-xl-1 mb-9">

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Job Title <span className="error font-size-3">*</span>
                                                                </label>
                                                                <input
                                                                    type="text" defaultValue={values.title} value={values.title}
                                                                    className={`form-control   ${(values.error.title.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="Title"
                                                                    name="title"
                                                                    placeholder="eg.Software Engineer" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                                />{values.error.title.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.title}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Experience Level <span className="error font-size-3">* </span>
                                                                </label>
                                                                <Select name="expLevel" value={values.expLevel}
                                                                    options={experience} placeholder="Select..."
                                                                    className={`form-control pl-0 arrow-3 w-100   d-flex align-items-center w-100   ${(values.error.expLevel.length > 0) ? 'form-control-error' : ''}`}
                                                                    border={false} onChange={(e) => handleSelectChange(e, 'expLevel')}
                                                                />
                                                                {values.error.expLevel.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.expLevel}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 ">
                                                            <div className="form-group">
                                                                <label

                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Job Type <span className="error font-size-3">* </span>
                                                                </label>
                                                                <div className="media mb-2   row  ">
                                                                    {/* <!-- media icon start --> */}

                                                                    <div className="media-icon text-center  col-6  col-lg-4" onClick={() => handleOption('ONSITE')} style={{ cursor: 'pointer', }}>

                                                                        <div className="row font-size-4  font-weight-semibold">
                                                                            <div className="col-2 py-1 "> <input
                                                                                name="ONSITE"
                                                                                className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px' }}
                                                                                type="checkbox"
                                                                                id="expert-check" onChange={() => handleOption('ONSITE')} checked={values.jobType == 'ONSITE'}
                                                                            /><div className="border-1-green text-green font-size-3  d-none" style={{ width: '18px', height: '18px' }}>{values.jobType === 'ONSITE' && <> <i className="fa fa-check text-right pos-abs-cr mr-0"  ></i> </>} </div></div>
                                                                            <div className="col-9 text-left ">   {' '} Onsite</div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="media-icon col-6 text-center  col-lg-4" onClick={() => handleOption('REMOTE')} style={{ cursor: 'pointer', }}>
                                                                        <div className="row font-size-4 font-weight-semibold">
                                                                            <div className="col-2 py-1  ">
                                                                                <input
                                                                                    name="REMOTE"
                                                                                    className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px' }}
                                                                                    type="checkbox"
                                                                                    id="client-check" onChange={() => handleOption('REMOTE')} checked={values.jobType == 'REMOTE'}
                                                                                />
                                                                                <div className="d-none border-1-green text-green font-size-3   " style={{ width: '18px', height: '18px' }}>{values.jobType === 'REMOTE' && <i className="fa fa-check  pos-abs-cr"  ></i>} </div></div>
                                                                            <div className="col-9  text-left  ">   {' '} Remote</div>
                                                                        </div> </div>

                                                                </div>  {values.error.jobType.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.jobType}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 "></div>
                                                        <div className="col-lg-6 ">
                                                            <div className="form-group">
                                                                <label

                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Project Length <span className="error font-size-3">* </span>
                                                                </label>
                                                                <Select name="projLength" value={values.projLength} options={projectLengthList}
                                                                    placeholder="Select..."
                                                                    className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(values.error.expLevel.length > 0) ? 'form-control-error' : ''}`}
                                                                    border={false} onChange={(e) => handleSelectChange(e, 'projLength')}
                                                                /> {values.error.projLength.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.projLength}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Available weekly hours <span className="error font-size-3"> *</span>
                                                                </label>
                                                                <Select options={weeklyHours}
                                                                    placeholder="Select..." value={values.weeklyHrs}
                                                                    className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(values.error.weeklyHrs.length > 0) ? 'form-control-error' : ''}`}
                                                                    border={false} onChange={(e) => handleSelectChange(e, 'weeklyHrs')}
                                                                />
                                                                {values.error.weeklyHrs.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.weeklyHrs}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 d-none">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="aboutTextarea"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Job Description  <span className="error font-size-3">* ( Max 4000 Character)</span>
                                                                    <br /><span className="font-size-3 text-green-2 font-weight-semibold"> Remaining Character length: {4000 - ((values.desc) ? values.desc.length : 0)}</span>
                                                                </label>
                                                                <textarea
                                                                    name="desc"
                                                                    id="desc" maxLength="4000"
                                                                    cols="30"
                                                                    rows="7" defaultValue={values.desc} Value={values.desc}
                                                                    className={`border   ${(values.error.desc.length > 0) ? 'border-red' : 'border-mercury'} text-gray w-100 pt-4 pl-6`}
                                                                    placeholder="Describe about your profile what make you unique" onKeyUp={handleFieldsChange} onChange={handleFieldsChange}
                                                                ></textarea>{values.error.desc.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.desc}</span>
                                                                )}
                                                            </div>

                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="aboutTextarea"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Job Description  <span className="error font-size-3">* ( Max 4000 Character)</span>
                                                                    <br /><span className="font-size-3 text-green-2 font-weight-semibold">  Remaining Character length: {4000 - ((editLength) ? editLength : 0)}</span>
                                                                </label>
                                                                
                                                                {values.error.desc.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.desc}</span>
                                                                )}

                                                            </div>

                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Country <span className="error font-size-3">*</span>
                                                                </label>
                                                                <Select
                                                                    options={gContext.countriesList} value={values.country}
                                                                    className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(values.error.country.length > 0) ? 'form-control-error' : ''}`}

                                                                    border={false} onChange={(e) => handleSelectChange(e, 'country')}
                                                                />{values.error.country.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.country}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    State <span className="error font-size-3">*</span>
                                                                </label>
                                                                <input
                                                                    name="state"
                                                                    type="text" defaultValue={values.state} value={values.state}
                                                                    className={`form-control   ${(values.error.state.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="state"
                                                                    placeholder="State" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                                />{values.error.state.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.state}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    City <span className="error font-size-3">*</span>
                                                                </label>
                                                                <input
                                                                    name="city" defaultValue={values.city} value={values.city}
                                                                    type="text"
                                                                    className={`form-control   ${(values.error.city.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="city"
                                                                    placeholder="City" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                                />{values.error.city.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.city}</span>
                                                                )}
                                                            </div>
                                                        </div>


                                                        
                                                        <div className="col-lg-12 ">   <div className="form-group">
                                                            <label
                                                                htmlFor="select2"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                                Rate <OverlayTrigger trigger="hover" placement="auto" overlay={popoverRate} ><span variant="primary" className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger> 
                                                            </label></div> </div>

                                                        {showRateRange && <> <div className="col-lg-4 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Daily Rate <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                </label>
                                                                <input name="dailyRate" disabled={showRateRange}
                                                                    type="text" className={`form-control   ${(values.error.dailyRate.length > 0) ? 'form-control-error' : ''}`}

                                                                    id="dailyRate"
                                                                />

                                                            </div>
                                                        </div>
                                                            <div className="col-lg-4 ">
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="select2"
                                                                        className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                    >
                                                                        Fixed Rate <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                    </label>
                                                                    <input name="fixedRate" disabled={showRateRange}
                                                                        type="text" className={`form-control   ${(values.error.fixedRate.length > 0) ? 'form-control-error' : ''}`}

                                                                        id="dailyRate"
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 ">
                                                                <div className="form-group">
                                                                    <label

                                                                        className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                    >
                                                                        Hourly Rate  <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                    </label>
                                                                    <input name="hourlyRate" disabled={showRateRange}
                                                                        type="text" className={`form-control   ${(values.error.hourlyRate.length > 0) ? 'form-control-error' : ''}`}

                                                                        id="dailyRate"
                                                                        placeholder=" "
                                                                    />

                                                                </div>
                                                            </div></>}
                                                        {!showRateRange && <> <div className="col-lg-4 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Daily Rate  <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                </label>
                                                                {(values.fixedRate) || (values.hourlyRate) ?
                                                                    <><input name="dailyRate1" disabled='true'
                                                                        type="text" className={`form-control`} value={values.dailyRate ? '' : ''}
                                                                        id="dailyRate" /></> :
                                                                    <><input name="dailyRate"
                                                                        type="text" value={values.dailyRate}
                                                                        className={`form-control   ${(values.error.dailyRate.length > 0) ? 'form-control-error' : ''}`}
                                                                        id="dailyRate" onKeyUp={handleFieldsChange}
                                                                        placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                                                    /></>}
                                                                {values.error.dailyRate.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.dailyRate}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                            <div className="col-lg-4 ">
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="select2"
                                                                        className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                    >
                                                                        Fixed Rate   <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                    </label>
                                                                    {(values.dailyRate) || (values.hourlyRate) ? <><input name="fixedRate1" disabled='true'
                                                                        type="text" className={`form-control`} value={values.fixedRate ? '' : ''}
                                                                        id="fixedRate1"
                                                                    /></> : <><input name="fixedRate"
                                                                        type="text" className={`form-control   ${(values.error.fixedRate.length > 0) ? 'form-control-error' : ''}`}

                                                                        id="dailyRate" defaultValue={values.fixedRate} value={values.fixedRate} onKeyUp={handleFieldsChange}
                                                                        placeholder=" " onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                                                    /></>}

                                                                    {values.error.fixedRate.length > 1 && (
                                                                        <span className="error font-size-3">{values.error.fixedRate}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 ">
                                                                <div className="form-group">
                                                                    <label

                                                                        className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                    >
                                                                        Hourly Rate  <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                    </label>
                                                                    {(values.dailyRate) || (values.fixedRate) ? <><input name="hourlyRate1" disabled='true'
                                                                        type="text" className={`form-control`} value={values.hourlyRate ? '' : ''}
                                                                        id="hourlyRate1"
                                                                    /></> : <><input name="hourlyRate"
                                                                        type="text" className={`form-control   ${(values.error.hourlyRate.length > 0) ? 'form-control-error' : ''}`}

                                                                        id="dailyRate" defaultValue={values.hourlyRate} value={values.hourlyRate} onKeyUp={handleFieldsChange}
                                                                        placeholder=" " onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                                                    /></>}
                                                                    {values.error.hourlyRate.length > 1 && (
                                                                        <span className="error font-size-3">{values.error.hourlyRate}</span>
                                                                    )}
                                                                </div>
                                                            </div></>}
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Rate Range <span className="error font-size-3">*</span>
                                                                </label>
                                                                {showRateRange && <div className="media"><div className=" w-40 mr-5 ">  <Select name="expLevel" value={values.rateType}
                                                                    options={rateType} placeholder="Select..."
                                                                    className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${((values.error.rateType) && values.error.rateType.length > 0) ? 'form-control-error' : ''}`}
                                                                    border={false} onChange={(e) => handleSelectChange(e, 'rateType')}
                                                                />
                                                                    {values.error.rateType && values.error.rateType.length > 1 && (
                                                                        <span className="error font-size-3">{values.error.rateType}</span>
                                                                    )}
                                                                </div> <div className=" w-30 "  >


                                                                        <input
                                                                            name="rateRangeFrom"
                                                                            type="text" defaultValue={values.rateRangeFrom} value={values.rateRangeFrom}
                                                                            className={`form-control   ${(values.error.rateRangeFrom.length > 0) ? 'form-control-error' : ''}`}
                                                                            id="rateRangeFrom"
                                                                            placeholder="1000" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                                        />{values.error.rateRangeFrom.length > 1 && (
                                                                            <span className="error font-size-3">{values.error.rateRangeFrom}</span>
                                                                        )}</div><div className=" px-5 my-auto text-center"><span  >-</span></div>
                                                                    <div className=" w-30 "> <input
                                                                        name="rateRangeTo"
                                                                        type="text" defaultValue={values.rateRangeTo} value={values.rateRangeTo}
                                                                        className={`form-control   ${(values.error.rateRangeTo.length > 0) ? 'form-control-error' : ''}`}
                                                                        id="rateRangeTo"
                                                                        placeholder="2000" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                                    /> {values.error.rateRangeTo.length > 1 && (
                                                                        <span className="error font-size-3">{values.error.rateRangeTo}</span>
                                                                    )}</div></div>}



                                                                {!showRateRange && <div className="media w-100">
                                                                    <div className="w-40 pr-5">  <select
                                                                        name="rateRange" disabled={!showRateRange}
                                                                        type="text"
                                                                        className="form-control   "
                                                                        id="srateRange" options={rateType} placeholder="Select..."
                                                                    /> </div><div className="w-30">  <input
                                                                        name="rateRange" disabled={!showRateRange} defaultValue=""
                                                                        type="text"
                                                                        className="form-control "
                                                                        id="rateRange"
                                                                    /> </div><div className="px-5 w-30 my-auto text-center"><span  >-</span></div><div className="w-50"> <input
                                                                        name="rateRange" disabled={!showRateRange}
                                                                        type="text" defaultValue=""
                                                                        className="form-control "
                                                                        id="rateRange2"

                                                                    /></div> </div>
                                                                }

                                                            </div>

                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Compliance Standards <span className="error font-size-3">* </span>
                                                                </label>
                                                                {values.error.compliance.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.compliance}</span>
                                                                )}

                                                                {selectedCompliances.length > 0 && <>  <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedCompliances.map((data, index) => {
                                                                        const { id, code } = data;
                                                                        return ((selectedCompliances.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip" className="bg-green-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { addToCompliance(id) }} >
                                                                                {code}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 "></div></>}
                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {compliances.map((data, index) => {

                                                                        const { code, id } = data;
                                                                        return (<li key={index}>

                                                                            <a title="Click to select compliance" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { removeCompliance(id) }} >
                                                                                {code}
                                                                            </a>

                                                                        </li>)
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4 media "
                                                                >
                                                                    <div className="media mb-0 pb-0 w-100">   Compliance Category <span className="error font-size-3">* </span>
                                                                        <div className="position-relative w-75">
                                                                            <span className="circle-20 bg-green-2 ml-3 w-100 font-family-Cascadia Code  pointer text-white font-size-4  "
                                                                                onMouseOver={() => setTooltip(!tooltip)}
                                                                                onMouseLeave={() => setTooltip(!tooltip)}>
                                                                                <i className="fa fa-info  font-size-2 "></i>  </span>
                                                                            {tooltip === true && <div className="focus-reset mt-10   w-100 pos-abs-tl   z-index-supper bg-white rounded">
                                                                                <div className=" border w-100  px-5 font-size-3  z-index-supper bg-gray-opacity-1 rounded shadow-12">
                                                                                    <p className="font-size-3 pt-1 font-weight-normal  text-black line-height-1p4"  >
                                                                                        A <strong>Consultant</strong> is a person who is an expert in ISO/Compliance standards and has an extensive experience in implementing the standards, following the guidelines and has worked in a variety of industries.

                                                                              <br />   A<strong> first-party audit</strong> is performed inside an organization to measure its strength and weakness against its systems or techniques or potentially against external standard principles embraced by the organization. A first-party audit is an internal audit led by auditors who are working in the organization but who have no personal stake in the audit results of the area being audited.

                                                                              <br />  A <strong> second-party audit</strong> is an external audit performed on a supplier by a client or by a contracted organization for a client. An agreement is set up, and the services and products are being or will be, conveyed. Second-party audits are dependent upon the guidelines of agreement law, as they are legally binding to the client and the supplier. Second-party audits will quite often be more formal than first-party audits since audit results could impact the client's buying choices.

                                                                             <br />   A <strong>third-party audit</strong> is performed by an audit organization independent of the client-supplier relationship and is liberated from an irreconcilable circumstance. The independence of the audit organization is a vital part of an external audit. Third-party reviews might bring about certification, enlistment, acknowledgment, an honor, license endorsement, a reference, a fine, or punishment given by the 3rd party organization.

                                                                             <br />  <strong> 1st party auditor</strong> can be an internal resource of organization  who conducts review of organizational systems.

                                                                              <br />  <strong> 2nd party auditor</strong> can be an internal or external resource conducting review of supplier /vendor systems.

                                                                              <br /> <strong>  3rd party auditor</strong> are affiliated to 3rd independent bodies to perform audit of the organizational system.

                                                                   </p></div> </div>}</div></div>
                                                                </label>
                                                                {values.error.compCategory.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.compCategory}</span>
                                                                )}
                                                                {selectedCompCategory.length > 0 && <>  <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedCompCategory.map((data, index) => {
                                                                        const { id, code, category } = data;
                                                                        return ((selectedCompCategory.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip" className="bg-yellow-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { addToCompCategory(id, code) }} >
                                                                                {category}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 "></div></>}
                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {compCategory.map((data, index) => {
                                                                        const { id, code, category } = data;
                                                                        return (<li key={index}>
                                                                            {(selectedCompCategory.length === 0 || (selectedCompCategory.length > 0 && selectedCompCategory[0].code == "400")) &&

                                                                                <a title="Click to select" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { removeCompCategory(id, code) }} >
                                                                                    {category}
                                                                                </a>
                                                                            }
                                                                            {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "100") &&

                                                                                <a title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                                                                    {category}
                                                                                </a>
                                                                            }
                                                                            {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "200") &&

                                                                                <a title={(code == "400") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code == "400")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { if (code == "400") { removeCompCategory(id, code) } }} >
                                                                                    {category}
                                                                                </a>
                                                                            }
                                                                            {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "300") &&

                                                                                <a title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                                                                    {category}
                                                                                </a>
                                                                            }

                                                                        </li>)
                                                                    })}





                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Skill set <span className="error font-size-3">* </span>
                                                                </label>
                                                                {values.error.skillSet.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.skillSet}</span>
                                                                )}
                                                                {selectedSkillData.length > 0 && <>  <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedSkillData.map((data, index) => {
                                                                        const { id, skill } = data;
                                                                        return ((selectedSkillData.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip" className="bg-orange-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { addToSkills(id) }} >
                                                                                {skill}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 "></div></>}

                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {skills.map((data, index) => {
                                                                        const { id, skill } = data;
                                                                        return (<li key={index}>

                                                                            <a title="Click to select" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { removeSelectedSkill(id) }} >
                                                                                {skill}
                                                                            </a>

                                                                        </li>)
                                                                    })}





                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Industry Type <span className="error font-size-3">* </span>
                                                                </label>
                                                                {values.error.industryType.length > 1 && (
                                                                    <span className="error font-size-3">{values.error.industryType}</span>
                                                                )}
                                                                {selectedIndustryData.length > 0 && <>    <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedIndustryData.map((data, index) => {
                                                                        const { id, name } = data;
                                                                        return ((selectedIndustryData.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip"
                                                                                className="bg-red-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer"
                                                                                onClick={() => { addToIndustryType(id) }} >
                                                                                {name}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 "></div></>}

                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {industryTypeList.map((data, index) => {
                                                                        const { id, name } = data;

                                                                        return (<li key={index}>
                                                                            <a title="Click to select" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer"
                                                                                onClick={() => { removeSelectedIndustryType(id) }} >
                                                                                {name}
                                                                            </a>

                                                                        </li>)
                                                                    })}

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        


                                                        <div className="col-lg-12  justify-content-md-end"><div className="media py-10 w-100  justify-content-md-end "  >
                                                            <div className="p-0 m-0 position-relative  ">


                                                                <button
                                                                    type="button"
                                                                    className="btn-green  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"
                                                                    style={{ width: '115px', height: '34px' }}
                                                                    onClick={() => {
                                                                        updateProfile(true);
                                                                    }}>
                                                                    Save & Post
                            </button>
                                                            </div>
                                                            <div className="p-0 m-0 position-relative ">

                                                                <button
                                                                    type="button" className="btn-green  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase"
                                                                    style={{ width: '60px', height: '34px' }}
                                                                    onClick={() => {
                                                                        updateProfile(false);

                                                                    }}>
                                                                    Save
                            </button>
                                                            </div>

                                                            <button
                                                                type="button"

                                                                className="   btn-green  font-size-3 font-weight-bold border-0 text-white 
                                                                    rounded-5 text-uppercase ml-5 ml-sm-3"
                                                                style={{ width: '80px', height: '34px' }}

                                                                onClick={() => {
                                                                    cancelEdit();

                                                                }}>
                                                                Cancel
                            </button>

                                                        </div> </div>


                                                    </div>

                                                </fieldset>
                                            </form>
                                        </div>
                                    </>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>


                }

            {!loaded &&
                <div className="tab-pane active show" role="tabpanel">




                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title movingLoader w-150">
                                <h6>&nbsp;</h6>
                            </div>

                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>

                            </div>

                        </div>

                        <div className="card-body p-b-0">


                            <div className="row">



                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName" style={{ height: '150px' }}

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>
                            </div>
                        </div>

                    </div>
                </div>}
        </>
    );
};

export default Job;

import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import GlobalContext from "../../context/GlobalContext";
import { GetClientJobs, SaveJobInvites, LoadJobsInvites, CreateAlert } from "../../utils/apiCalls";
import AlertUtils from "../../utils/AlertUtils";
import ReactCountryFlag from "react-country-flag"; 
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalPostedJobs = (props) => {

    const gContext = useContext(GlobalContext);
    const [postedJobs, setPostedJobs] = useState([]);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [showLoader, setShowLoader] = useState(false);
    const [id, setID] = useState(null);
    const [loader, setLoader] = useState(false);
    const [jobInvited, setJobInvited] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    async function LoadClientInvites() {
        try {
            if (gContext.userInfo.userID && gContext.userInfo.userType == "2") {
                const result = await LoadJobsInvites(gContext.userInfo.userID, props.expertData.id);

                if (result.data.loadJobsInvites.responseData.code == "200") {

                    let data = JSON.parse(result.data.loadJobsInvites.responseData.message);

                    if (data) {
                         
                        setJobInvited(data);
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            
        }
    }
    async function LoadPostedJobs() {
        try {
            if (gContext.userInfo.userID && gContext.userInfo.userType == "2") {
                setLoader(true);
                const result = await GetClientJobs(gContext.userInfo.userID);

                if (result.data.clientJobsLoad.responseData.code == "200") {

                    let data = JSON.parse(result.data.clientJobsLoad.responseData.message);

                    if (data) {
                        setPostedJobs(data.posted);
                        
                    }
                }
                setLoader(false);
            }
        }
        catch (e) {
            console.log(e);
            setLoader(false);
            gContext.setLoaderModalVisible(false);

        }
    }
    async function InviteExpert(data) {
        try {
            setShowLoader(true);
            const result = await SaveJobInvites(gContext.userInfo.userID, props.expertData?.id, data.id);

            if (result.data.saveJobInvites.responseData.code == "200") {
                LoadClientInvites();
                SendNotificationtoExpert(data);
            }
            setShowLoader(false);
        }
        catch (e) {
            console.log(e);
            setShowLoader(false);
            gContext.setLoaderModalVisible(false);
        }
    }
    async function SendNotificationtoExpert(data) {

        const profileInfo = JSON.parse(gContext.userInfo.profileInfo); 
        setErrorMessage("");
        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            let alert = AlertUtils.ClientJobInvite.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company : "").
                replace("[JOBTITLE]", data.title).
                replace("[GUID]", data.guid);
 

            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: (props.expertData) ? props.expertData.id : null });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);

            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);

    useEffect(() => {        
        LoadPostedJobs();
        LoadClientInvites();
    }, []); 
    useEffect(() => { 
        LoadPostedJobs();
        LoadClientInvites();
    }, [props.expertData]); 
 
    return (
        <ModalStyled
            {...props}
            size="sm"
            backdrop="static"
            centered 
            show={props.modalvisible}
            onHide={() => { props.toggleModal(); }}
        >
            <div className="modal-content " >  
                <div className="modal-header">
                    <h4 className="modal-title">Invite </h4>
                    <button type="button" className="btn-close hide-md  " onClick={() => { props.toggleModal(); }}></button>
                    <button type="button" className="btn-close d-none hide-lg show-md m-5" onClick={() => { props.toggleModal(); }}></button>

                </div> 
                <div className="modal-body" style={{ height: "70vh" }} >

                    <div className="row " > 
                            <div className=" expert-info-details">
                                <div className="expert-data">
                                    <div className="expert-profile m-0 align-items-start">
                                        <div className="expert-img">

                                        {(props.expertData.avatar) && <img className="  pointer" src={s3URL + props.expertData.avatar} onError={(img) => { img.target.src = '../../images/user-default.png'; }} onClick={() => { window.location.href = "expert/" + props.expertData.sub; }} alt={(props.expertData.firstname) ? props.expertData.firstname : '' + (props.expertData.lastname) ? " " + props.expertData.lastname : ''} />}
                                        </div>

                                        <div className="d-flex flex-column">

                                        <strong className="expert-name pointer" onClick={() => { window.location.href =window.location.origin + "/expert/" + props.expertData.sub; }}> {(props.expertData.firstname) ? props.expertData.firstname : ''} {" "}   {(props.expertData.lastname) ? props.expertData.lastname : ''}<img className="verified-img m-l-5" src="../images/verified_img.svg" alt="verified_img.svg" /></strong>


                                            <div className="d-flex ">
                                                <span className="expert-desg">{(props.expertData.title) ? props.expertData.title : ''}</span>

                                                <div className="info-meta flag">
                                                    <span className="text-theme m-icon material-icons m-l-10 m-r-2">location_on</span><span className="one-line text-black  m-r-5">
                                                        <span className={`text-black ${(props.expertData.city != null && props.expertData.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{props.expertData.city != null ? props.expertData.city.toLowerCase() : ''}</span>

                                                        , {props.expertData.countrycode}</span>
                                                    <ReactCountryFlag
                                                        countryCode={props.expertData.countrycode}
                                                        svg

                                                    />
                                                </div>

                                            </div>

                                            

                                        </div>
                                    </div>


                                </div>


                             

                        </div>
                        <hr className="text-gray m-t-5" />
                        {(!loader && postedJobs && postedJobs.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                            <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                            <span>No active jobs found. Please create and post a job to invite experts</span>
                        </div>}
                        {!loader && postedJobs && postedJobs.length > 0 && <table className="table table-striped m-5">
                            <thead>
                                <tr>
                                    <th className="d-flex f-36 min-md-cell justify-content-left align-items-left">Job Title</th>

                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {postedJobs && postedJobs.map((data, index) => {

                                    return (
                                        <tr key={index}>

                                            <td>
                                                <span className="d-flex justify-content-left align-items-left" onClick={() => { window.location.href = window.location.origin + "/job/" + data.guid; }}>
                                                    <span className="d-flex title pointer" data-toggle="tooltip" title={data.title}
                                                    ><span className=" one-line ">{data.title}</span>   </span>
                                                   
                                                </span>
                                            </td>

                                            <td>
                                                <div className="btn-table text-center">
                                                    {jobInvited && (jobInvited.filter(item => item["jobid"] == data.id).length > 0) ?
                                                        <button className="btn btn-gray" style={{ width: '70px' }} > Invited</button>
                                                        :
                                                        <button className="btn btn-orange" style={{ width: '70px' }} onClick={() => { setID(data.id); InviteExpert(data); }}> {(showLoader && (id == data.id)) ? <ClipLoader color="#ffff" size="20px" /> : "Invite"}</button>}
                                                </div>
                                            </td>
                                        </tr>)
                                })}</tbody>
                        </table>}

                        {loader && <div className="table-responsive w-full">
                            <table className="table table-striped">
                                <thead>
                                    <tr >
                                        <th className=" movingLoader p-10"> </th>
                                        <th className=" movingLoader "> </th>
                                        <th className=" movingLoader "> </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className=" movingLoader ">
                                        </td>
                                        <td className=" movingLoader ">
                                        </td>  <td className=" movingLoader ">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=" movingLoader ">
                                        </td>
                                        <td className=" movingLoader ">
                                        </td>  <td className=" movingLoader ">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=" movingLoader ">
                                        </td>
                                        <td className=" movingLoader ">
                                        </td>  <td className=" movingLoader ">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=" movingLoader ">
                                        </td>
                                        <td className=" movingLoader ">
                                        </td>  <td className=" movingLoader ">
                                        </td>
                                        <tr>
                                            <td className=" movingLoader ">
                                            </td>
                                            <td className=" movingLoader ">
                                            </td>  <td className=" movingLoader ">
                                            </td>
                                        </tr>
                                    </tr>
                                    <tr>
                                        <td className=" movingLoader ">
                                        </td>
                                        <td className=" movingLoader ">
                                        </td>  <td className=" movingLoader ">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=" movingLoader ">
                                        </td>
                                        <td className=" movingLoader ">
                                        </td>  <td className=" movingLoader ">
                                        </td>
                                    </tr>







                                </tbody>
                            </table>
                        </div>}

                     </div>

                   
                </div> 
            </div>              
        </ModalStyled>
    );
};

export default ModalPostedJobs;

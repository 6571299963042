 import React, { useContext, useEffect, useState } from "react"; 
import GlobalContext from "../../context/GlobalContext"; 
import { sinceDate, getMonthDateYear } from "../../utils/addDays";
import Milestones from "./Milestones";
import DOMPurify from 'isomorphic-dompurify';
import ReactCountryFlag from "react-country-flag";
import { LoadOffer, LoadContract } from "../../utils/apiCalls";
 


export default function ContractView(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
   
    const [mode, setMode] = useState("");   
    const gContext = useContext(GlobalContext);
    const [contract, setContract] = useState({});
    const [milestones, setMilestones] = useState([]);
    const [loaded, setLoaded] = useState(false);
    async function BindContract() {
        try {


           
            const result = await LoadContract(props.id);
            
            if (result.data?.loadContract && result.data.loadContract.responseData?.code == "200") {

                let data = JSON.parse(result.data.loadContract.responseData.message);
                
                if (data.length > 0) {
                   
                    if ((gContext.userInfo.userType == 2 && data[0].clientid == gContext.userInfo.userID) || (gContext.userInfo.userType == 1 && data[0].expertid == gContext.userInfo.userID)) {
                        setContract(data[0]);
                        setMilestones(data[0].milestones);
                    } else {
                        window.location.href = "/error";
                    }
                }
                else
                    window.location.href = "/error";
            }
            else {
                if (result.errors && result.errors.length > 0) {

                    if (result.errors[0]?.originalError.response.status == "403")
                        gContext.setErrorModal("Access denied from your location or Internet connection. Make sure you are not using a VPN.");
                    else
                        gContext.setErrorModal(result.errors[0]?.message);

                    gContext.toggleErrorMessage(true);
                }
                else if (result.message){
                    if (result.message.includes("403"))
                        gContext.setErrorModal("Access denied from your location or Internet connection. Make sure you are not using a VPN.");
                    else
                        gContext.setErrorModal(result.message);
                    gContext.toggleErrorMessage(true);
                }

            }

            gContext.setLoaderModalVisible(false);
            setLoaded(true);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false); 
        }
    }
    useEffect(() => {
        if (props.id) { 
        }
        document.title = "Contract | Syncuppro";

    }, []);
    useEffect(() => {
        const user = localStorage.getItem('connect');
        if (!user)
            window.location.href = "/error";
        if ((props.id && gContext.userInfo.userID)) {
            BindContract();
        } 

    }, [gContext.userInfo]);
    return (
        <>


            {loaded && <>

                <div className="page-wrapper  ">

                    <div className="container-fluid  ">

                        <div className="card">

                            <div className="company-post">
                                <div className="medie-info-img">
                                    {(contract.logo) && <img src={s3URL + contract.logo} id={"imagelogo"} onError={(img) => { img.target.src = '../images/user-default.png'; }} alt="" />}
                                </div>

                                <div className="d-flex flex-column">
                                    <a className="m-b-10" target="_self" href={"/client/" + contract.sub}><b> {(contract.company) ? contract.company : ''}</b></a>
                                    <span>  <div className="info-meta flag">
                                        {contract.countrycode && <ReactCountryFlag
                                            countryCode={contract.countrycode}
                                            svg
                                            style={{
                                                width: '1.5em',
                                                height: '.7em',
                                            }}
                                            title={contract.countrycode}
                                        />}
                                        <span className="one-line text-black m-l-5">
                                            <span className={`    ${(contract.city != null && contract.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(contract.city) ? contract.city.toLowerCase() : "" + ' '}</span>
                                            {' ' + contract.countrycode}</span>
                                    </div></span>
                                </div>
                            </div>

                            {contract.clientid === gContext.userInfo.userID && gContext.userInfo.userType === "2" &&


                                <a className="   pointer d-none" target="_blank" rel="noopener noreferrer" href={"/expert/" + contract.expertsub}  >
                                    <div className="company-post">
                                        <div className="medie-info-img">
                                            <img src={s3URL + contract.avatar} alt="" />
                                        </div>

                                        <div className="d-flex flex-column">
                                            <a className="m-b-10 m-t-10" href="#"><b>{contract.firstname + ' ' + contract.lastname}</b></a>

                                        </div>
                                    </div>

                                </a>}




                            <div className="d-flex justify-content-between m-b-20">
                                <h6 className="text-capi"> {(contract.title) ? contract.title : ''}</h6>
                            </div>

                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contract.desc) }}></p>

                            <div className="divider-line"></div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 m-b-20 f-16 hide-md">
                                    <div className="info-meta pointer" onClick={(e) => { window.location.href = "/job/" + contract.jobguid; }}>
                                        <span className="text-theme m-icon material-icons m-r-10">work</span> <span className="align-middle  text-capi">  <b> {" Job : " +(contract.jobtitle)}</b>   </span>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 m-b-20 f-12 d-none hide-lg show-md ">

                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        <div className="d-flex flex-column" onClick={(e) => { window.location.href = "/job/" + contract.jobguid; }} >
                                            <span className="m-b-5">Job</span>
                                            <b>{contract.jobtitle}  </b>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="info-meta">
                                        <span>Status: <b>{(contract.status)}</b></span>
                                    </div>
                                </div>

                                {(contract.startdate) && <>  <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="info-meta">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span><span>  Start Date : <b>{getMonthDateYear(contract.startdate)}</b></span>
                                    </div>
                                </div></>}

                                {(contract.enddate) && <>   <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="info-meta">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span><span>  End Date : <b>{getMonthDateYear(contract.enddate)}</b></span>
                                    </div>
                                </div></>}
                                {props.mode === "accept" &&
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <div className="info-meta">
                                            <span className="text-theme m-icon material-icons m-r-10">person</span><span>Hiring Person: <b> {contract.hiringperson}</b></span>
                                        </div>
                                    </div>}

                            </div>
                            <div className="divider-line"></div>

                            <div className="row">

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">monitor</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Rate type</span>
                                            <b>{contract.rate}</b>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Weekly Hours</span>
                                            <b>{(contract.hours) ? contract.hours : ''}</b>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5"> {(contract.dailyrate) ? "Daily" : ((contract.fixedrate) ? "Fixed" : (contract.hourlyrate ? "Hourly" : ""))}  Rate</span>
                                            <b>${(contract.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(contract.dailyrate)) : ""}
                                                {(contract.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(contract.hourlyrate)) : ""}
                                                {(contract.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(contract.fixedrate)) : ""}
                                            </b>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="divider-line"></div>


                            <Milestones guid={props.id} isPopup={false} milestones={milestones} contract={contract} contractId={contract.id} mode={mode} BindContract={() => { BindContract(); }}  />
                        </div></div> </div> </>}
            {!loaded &&
                <div className="page-wrapper  m-20">



                    <div className="container-fluid  ">

                        <div className="card  m-20">



                            <div className="company-post">
                                <div className="btn-circle movingLoader">

                                </div>

                                <div className="d-flex flex-column w-50  ">
                                    <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                    <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div></span>
                                </div>
                            </div>






                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                            </div>



                            <div className="d-flex justify-content-between m-b-0   movingLoader">
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                
                            </div>
                            

                            <div className="row m-t-5">

                                <div className="col-lg-4 col-md-4 col-sm-6 m-t-10">
                                    <div className="d-flex flex-column w-50  ">
                                        <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                        <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div></span>
                                    </div>
                                </div>



                                <div className="col-lg-4 col-md-4 col-sm-6 m-t-10">
                                    <div className="d-flex flex-column w-50  ">
                                        <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                        <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div></span>
                                    </div>
                                </div>

                            </div>
                            <div className="divider-line"></div>

                            <div className="row">

                                <div className="col-lg-4 col-md-4 col-sm-6 m-t-10">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10"> </span>
                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6 m-t-10">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10"> </span>
                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6 m-t-10">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10"></span>
                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div></span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="divider-line"></div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr >
                                            <th className="w-130 movingLoader "> </th>
                                            <th className="w-130 movingLoader "> </th>
                                            <th className="w-130 movingLoader "> </th>
                                            <th className="w-130 movingLoader "> </th>
                                            <th className="w-130 movingLoader "> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr> <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr> <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr> <tr>
                                            <td className="w-130 movingLoader ">
                                            </td>
                                            <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>  <td className="w-130 movingLoader ">
                                            </td>
                                        </tr>





                                    </tbody>
                                </table>
                            </div>

                        </div></div> </div>
            }

        </>
    );
};

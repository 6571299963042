import React, {   useEffect } from "react"; 
const Brands = (props) => {
     
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
     
    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
         
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }
   
    useEffect(() => {
        
    }, [props.bindData]);
  return (
    <>
      {/* <!-- Brands Area --> */}
          {(props.bindData && props.bindData.clients && props.bindData.clients.length > 0) &&

              <>  <section className="section-sm d-block d-sm-none">
                            
                                <div className="company-logo">
                      <h6 className=" m-b-20">Get hired by top companies</h6>

                      <div className="logo-img-block m-t-20 text-center">
                          {(props.bindData && props.bindData.clients) && props.bindData.clients.map((client, index) => {
                             
                              return (
                                  <img key={index} className="pointer p-t-10 m-b-30 m-l-10 m-r-10" data-toggle="tooltip" onClick={() => { window.open((client.featuredlinktype == "W" && client.website && client.website != "") ? sociallink(client.website) : "/client/" + client.sub, "_blank"); }} title={client.company} src={(client.featuredlogo) ? s3URL + client.featuredlogo : s3URL + client.logo} alt={client.company} onError={(img) => {   img.target.src = '../images/noimage.jpg'; }} key={index} />
                                              )
                          })
                          }
            </div>
           
        </div>
      
          </section>
              <section className="section-sm   d-none d-sm-block m-t-20 m-b-30">

                  <div className="company-logo">
                      <h6 className=" m-b-20">Get hired by top companies</h6>
                      {(props.bindData && props.bindData.clients) &&
                          <div className="row m-t-30">
                              <div className={"col-" + (6 - props.bindData.clients.length)}></div>
                              {(props.bindData && props.bindData.clients) && props.bindData.clients.map((client, index) => {

                                  return (
                                      <>
                                          <div className="col-2 text-center p-l-30 p-r-30"> <img key={index} className="pointer  " data-toggle="tooltip" onClick={() => { window.open((client.featuredlinktype == "W" && client.website && client.website != "") ? sociallink(client.website) : "/client/" + client.sub, "_blank"); }} title={client.company} src={(client.featuredlogo) ? s3URL + client.featuredlogo : s3URL + client.logo} alt={client.company} onError={(img) => { img.target.src = '../images/noimage.jpg'; }} key={index} /></div>
                                      </>

                                  )
                              })
                              }
                              <div className={"col-" + (6 - props.bindData.clients.length)}></div>
                          </div>
                      }
                  </div>

              </section>
               </>}
    </>
  );
};

export default Brands;

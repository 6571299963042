import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import {  ClientContractComplete, LoadContract } from "../../utils/apiCalls";
import { getMonthDateYear, addDateDays } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Message from "../../components/Message"; 
import ModalContract from "../../components/JobProposal/ClientContract";
import SendMessage from "../../components/Message/SendMessage";
import Feedback from "../../components/JobProposal/Feedback";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
export default function Contract(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowsContracts, setRowsContracts] = useState([]);
    const [id, setId] = useState(null);
    const [guid, setGuid] = useState(null);
    const [message, setMessage] = React.useState(false);
    const [contract, setContract] = useState({});
    const [contractmodalvisible, setContractmodalvisible] = useState(false);
    const [modalvisible, setmodalvisible] = useState(false);
    const [modalMessagevisible, setmodalMessagevisible] = useState(false);
    const [currentValue, setCurrentValue] = useState({ code: null, id: null });
    const [mode, setMode] = useState("");
    const [showScrollup, setShowScrollup] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [profileValues, setprofileValues] = useState(JSON.parse(gContext.userInfo.profileInfo));
    const { slice, range } = tableHelper(rowsContracts, page, gContext.paginationSize);

    const togglecontractmodal = (cguid) => {       
        if (cguid && id)
           setContractmodalvisible(!contractmodalvisible);
    };
    const togglemodal = () => {
        setmodalvisible(!modalvisible);
    };
    const toggleMessagemodal = () => {
        setmodalMessagevisible(!modalMessagevisible);
    };
    const contractStatusChange = (id, mode) => {
        setShowScrollup(document.documentElement.scrollTop);
        if (id) {

            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + mode + " this contract?", ["confirmed"]: false, code: mode, id: id, showButton: true, mode: 'post' });
            gContext.toggleConfirmModal();
        }
    }
   
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "post") {

                UpdateStatus(gContext.confirmModal.code, gContext.confirmModal.id);
                gContext.setconfirmModal({
                    ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["code"]: null
                });

            }
            else
                setCurrentValue({ code: null, id: null });
        }
        else { setCurrentValue({ code: null, id: null }); }
    }, [gContext.confirmModal]);


    async function UpdateStatus(code, id) {
        try {
            if (code == "complete") {
               
                gContext.setLoaderModalVisible(true);
                const result = await ClientContractComplete();
                
                if (result.data.expertContractUpdate.responseData.code == "200") {
                    gContext.toggleRefreshDashboard("contract");
                    
                } gContext.setLoaderModalVisible(false);
            }
            if (code == "terminate") { }
        }
        catch (e) {
            console.log(e);
        }
       
    }


    function LoadContracts() {
        try {

            if (props.contractData) {
                setPage(1);
                let data;
                if (props.mode === "new") {

                    data = props.contractData.new;
                  
                     
                }
                else if (props.mode === "Active") {

                    data = props.contractData.active;
                 
                    
                }
                else if (props.mode === "Completed") {

                    data = props.contractData.completed;                 
                    
                }
                else {
                    data = props.contractData.terminated;
                                      
                }
                
 
                
                setRowsContracts(data);
                setLoaded(true);
            }
        } catch (e) { console.log(e); }
    }
    useEffect(() => {
        LoadContracts();
    }, [props.contractData]);

    useEffect(() => {
        
        LoadContracts();
    }, [props.mode]);
     

    useEffect(() => {
        LoadContracts();
        gContext.setLoaderModalVisible(false);
    }, []);
    async function contractComplete(data,mode)
    {
        try {

            setErrorMessage("");
           

            if (data.statuscode == 100) {
                 
                setId(data.id); setGuid(data.contractguid); setMode(mode); togglecontractmodal(data.contractguid);
                return;
            }
            gContext.setLoaderModalVisible(true);
            const result = await LoadContract(data.contractguid);
           
            if (result.data.loadContract && result.data.loadContract.responseData.code == "200") {

                let response = JSON.parse(result.data.loadContract.responseData.message);
                
                if (response.length > 0  ) {
                    
                    if (mode == "terminate") {
                        let filteredData = response[0].milestones.filter(item => (item["mscode"] == 200 || item["mscode"] == 300 || item["mscode"] == 450));
                        if (filteredData.length > 0) {
                            gContext.setconfirmModal({ header: " ", ["text"]: ConfirmMessages.contractTerminationAlert.replace("[DATE]", getMonthDateYear(new Date().toISOString())), ["confirmed"]: false, code: data.contractguid, id: data.id, page: "contract", showInfo: true, showButton: true, mode: 'post' });
                            gContext.toggleConfirmModal();
                        }
                        else {
                            setId(data.id); setGuid(data.contractguid); setMode("terminate"); togglecontractmodal(data.contractguid);

                        }
                    }
                    else {
                        //Client can complete Approved/PAyment completed contracts
                        let filteredData = response[0].milestones.filter(item => (data.mscode != 350 && data.mspaymentstatuscode != 400));
                        if (filteredData.length > 0) {
                            
                            setErrorMessage(ConfirmMessages.contractCompleteAlert);
                        }
                        else {
                            setId(data.id); setGuid(data.contractguid); setMode("complete"); togglecontractmodal(data.contractguid);

                        }
                    }
                }
               
            }
            
            gContext.setLoaderModalVisible(false);
            setLoaded(true);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            //  window.location.href = "/error";
        }

        
    }
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "post" && gContext.confirmModal.page== "contract") {

               
                setId(gContext.confirmModal.id); setGuid(gContext.confirmModal.code); setMode("terminate"); togglecontractmodal(gContext.confirmModal.code);
                gContext.setconfirmModal({
                    ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["code"]: null
                });

            }
            else
                setCurrentValue({ code: null, id: null });
        }
        else { setCurrentValue({ code: null, id: null }); }
    }, [gContext.confirmModal]);
    useEffect(() => {

        document.title = "Dashboard | Syncuppro";
    }, []);
    return (
        <>
           

                            {message && <div className="text-red">
                                <Message message="Job deleted successfully" />
                            </div>}
                        
            {(loaded && rowsContracts && rowsContracts.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No contract found</span>
            </div>}
            {(loaded && rowsContracts && rowsContracts.length > 0) &&
                <>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>

                            <tr>
                                <th className="w-200   min-lg-cell" > Title</th>
                                <th className="  min-lg-cell">   Applicant</th>

                                <th className="  min-lg-cell"> Rate </th>

                                {(props.mode == "Active" || props.mode == "Completed" ) && 
                                <>  <th className="  min-lg-cell" >Hours Spent</th> </>}


                                
                                {props.mode == "new"  || props.mode == "Active" ? <> <th className="  min-lg-cell"> Weekly Hours</th>   
                                </>:''}
                                

                                {props.mode == "Active" && <> <th className="  min-lg-cell"> Accepted on</th>   
                                </>}
                                
                                {(props.mode == "Active" || props.mode == "Completed" || props.mode == "Terminated") ?
                                <>  <th className="  min-lg-cell" >Started on</th></>: <> <th className="  min-lg-cell" >Starts On</th></>}
                               
                                
                               
                               
                                {props.mode == "Completed" && <> <th className="  min-lg-cell">Completed on</th>
                                </>}
                                {props.mode == "Terminated" && <>
                                    {props.mode == "Terminated" && <th className="  min-lg-cell">
                                        Termination  Reason
                        </th>}
                                    <th className="  min-lg-cell"
                                     
                                >
                                    Terminated by
                        </th>

                                    <th 
                                        className="  min-lg-cell"
                                    >
                                        Terminated on
                        </th>
                                     
                                </>}
                                <th></th>
                            </tr>
                             
                        </thead>
                        <tbody>
                            {slice && slice.map((data, index) => {
                                

                                return (
                                    <tr key={index}>
                                        <td >
                                            <span className="d-flex justify-content-left align-items-left">
                                                <span className="title one-line pointer justify-content-left align-items-left" data-toggle="tooltip" title={data.title} onClick={() => { setId(data.id); setGuid(data.contractguid); setMode("view"); togglecontractmodal(data.contractguid); }}>{data.title}</span> 
                                            </span>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/expert/" + data.sub; }}   >
                                                    <img className="m-r-10 " id={ "img"+index} src={s3URL + data.avatar} alt={data.firstname + ' ' + data.lastname} onError={(img) => { img.target.src = '../images/user-default.png'; }} />
                                                    <span className="one-line text-black ">{data.firstname + ' ' + data.lastname} </span> 
                                                </div>

                                               
                                            </div>
                                        </td>
                                        <td>${(data.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.dailyrate)) : ((data.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.fixedrate)) : (data.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.hourlyrate)) : (data.ratefrom && data.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(data.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(data.rateto)) : '-')}</td>
                                        

                                        {(props.mode == "Active" || props.mode == "Completed" ) && <> 
                                         <td  >
                                             
                                            {data.totalhoursspent ? (data.totalhoursspent):""}
                                             
                                        </td></>}
                                        
                                        {(props.mode == "new" || props.mode == "Active" ) && <> 
                                         <td  >
                                             
                                            {data.hours ? (data.hours):""}
                                             
                                        </td></>}


                                        {props.mode == "Active" && <><td >
                                               
                                                    {(data.accepteddate) ? getMonthDateYear(data.accepteddate) : ""}
                                                
                                            </td>
                                        </>}


                                        {(props.mode == "new" || props.mode == "Active" || props.mode == "Completed" || props.mode == "Terminated") &&
                                            <>   <td className="">

                                            <span className="  d-flex  align-item-center justify-content-center"> {data.startdate ? getMonthDateYear(data.startdate) : ""} {props.mode == "new" && <OverlayTrigger trigger="hover" placement="auto" overlay={<Popover className="" >

                                                <div className="tooltip-header bg-gray">
                                                    <h5>Information</h5>
                                                </div>
                                                <div className="tooltip-meta">
                                                    <p>Message expert before expiry date {data.datecreated ? getMonthDateYear(addDateDays(data.datecreated, 14).toISOString()) : ""} </p>

                                                </div>
                                            </Popover>} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger>}</span>
                                 
                            </td></>}

                            {props.mode == "Completed" && <> <td>
                                           
                                           {data.completeddate ? getMonthDateYear(data.completeddate) : ""}
                                       
                                   </td>
                                   </>}

                                   {props.mode == "Terminated" && <>
                                            <td  
                                            >
                                                {(data.reason) ?  data.reason : ""}
                                            </td>
                                            <td  >
                                                
                                                    {data.terminateperson}
                                                
                                            </td>
                                            <td >
                                                
                                                    {data.terminateddate ? getMonthDateYear(data.terminateddate) : ""}
                                                
                                            </td>
                                            
                                        </>}
                               
                                       

                                       


                                      
                                       
                                       

                                        

                                       
                                        
                                       


                                       
                                        <td>
                                            <div className="btn-table">
                                                {props.mode == "new" && <button className="btn btn-sm btn-gray pointer" onClick={() => { window.location.href = "/contract/" + data.contractguid ; }}>
                                                    View Contract
                                                </button>}
                                            {props.mode == "Active" && <button className="btn btn-sm btn-gray pointer" onClick={() => { window.location.href = "/contract/" + data.contractguid; }}>
                                                    Manage Contract
                                                </button>}
                                        {(props.mode != "Active" && props.mode != "new") && <button className="btn btn-sm btn-gray pointer" onClick={() => { window.location.href = "/contract/" + data.contractguid; }}>
                                                    View
                                                </button>}
                                                {props.mode == "new" &&

                                              <>  <button className="btn btn-sm btn-gray" onClick={() => { setContract(data); toggleMessagemodal(); }}>
                                                            Message</button>

                                                    <button className="btn btn-sm btn-gray" onClick={() => {  setId(data.id); setGuid(data.contractguid); setMode("edit"); togglecontractmodal(data.contractguid); }}>Edit</button>
                                                </>   }


                                                {(props.mode == "Terminated") && 
                                                    <button className="btn btn-sm btn-gray"  onClick={() => { setContract(data); togglemodal(); }}>
                                                        Feedback</button>

                                                    }
                                                {props.mode == "Active" &&
                                                    <button className="btn btn-sm btn-gray" onClick={() => { contractComplete(data,'complete'); }}>
                                                    Complete</button>
                                                     }
                                                {(props.mode == "new" || props.mode == "Active") &&  profileValues && profileValues.length > 0 && !profileValues[0].flagged && 


                                                    <button className="btn btn-sm btn-gray" onClick={() => { contractComplete(data, 'terminate');  }}>
                                                    Terminate</button>
                                                   }
 
                                            </div>
                                        </td>
                                    </tr>)
                            })}</tbody>
                    </table>
                </div>
                {(rowsContracts && rowsContracts.length > 5) &&
                    <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                </>}
            {modalMessagevisible && <SendMessage userdata={contract} modalvisible={modalMessagevisible} togglemodal={toggleMessagemodal} />}
            {modalvisible && <Feedback contract={contract} modalvisible={modalvisible} togglemodal={togglemodal} />}
            {contractmodalvisible && <ModalContract guid={guid} id={id} mode={mode} contractmodalvisible={contractmodalvisible} togglecontractmodal={() => { togglecontractmodal(guid); }} updateData={() => LoadContracts()} />}
        </>
    );
};
import React, { useEffect, useState, useContext } from "react"; 
import GlobalContext from "../../context/GlobalContext";
import { GetClientProfileLoad, GetClientPreviewProfile } from "../../utils/apiCalls"; 
import StarRating from "../../components/Core/Rating";
import ReactCountryFlag from "react-country-flag";
import DOMPurify from 'isomorphic-dompurify';
import { getMonthDateYear } from "../../utils/addDays";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import { PaymentRetrieveAccount } from "../../utils/payment";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { sinceDate } from "../../utils/addDays";
import Feedback from "../../sections/ClientDashboard/Feedback";
import Icon from '@mui/material/Icon';
import { Helmet } from "react-helmet";
 

export default function ProfileView(props) {
    const gContext = useContext(GlobalContext);
    gContext.setUserMode(props.userMode);
    const [profileInfo, setProfileInfo] = React.useState({});   
    const [values, setValues] = React.useState({stripeStatus: false,stripeid:null, amountSpent:0, datecreated: '', counts: null, feedbacks: null, contracts: null, industryTpes: null, code: '', avgrating: 0, logo: '', industryname: '', countryname: '', desc: '', tagline: '', company: '', city: '', state: '' });
    const subParam = props.sub;
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [feedback, setFeedbackData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [allfeedbacks, setAllFeedbacks] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [showMore, setShowMore] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper((values.contracts ? values.contracts.active : []), page, gContext.paginationSize);
    const [completedPage, setcompletedPage] = useState(1);
    const completed = tableHelper((profileInfo.contracts ? profileInfo.contracts.completed : []), completedPage, gContext.paginationSize);


    useEffect(() => {
        document.title = "Profile View | Syncuppro";         
        ClientPreviewInfo();      
    }, []) 

    function number_test(n) {
        
        if ((n - Math.floor(n)) != 0) {
            var dec_value = parseFloat(n).toFixed(1);
            return dec_value;

        }
        else {
            return n;
        }

    }

    async function ClientPreviewInfo() {
        try {
             
            const result = (!props.userMode) ? await GetClientProfileLoad(subParam) : await GetClientPreviewProfile(subParam);
            if (result.errors && result.errors.length > 0) {

                if (result.errors[0]?.originalError.response.status == "403")
                    gContext.setErrorModal("Access denied from your location or Internet connection. Make sure you are not using a VPN.");
                else
                    gContext.setErrorModal(result.errors[0]?.message);
                gContext.setLoaderModalVisible(false);
                gContext.toggleErrorMessage(true);
            }
            else if (result.message) {
                if (result.message.includes("403"))
                    gContext.setErrorModal("Access denied from your location or Internet connection. Make sure you are not using a VPN.");
                else
                    gContext.setErrorModal(result.message);
                gContext.setLoaderModalVisible(false);
                gContext.toggleErrorMessage(true);
            } else if (result.data?.clientAllLoad?.responseData?.code == "200")
            {
                    let profile = JSON.parse(result.data.clientAllLoad.responseData.message);
                    if (profile && profile.length > 0) {

                        if ((profile[0].deleted || profile[0].flagged) && !props.userMode) {
                            window.location.href = "/notavailable";
                            return;
                        }
                         
                        let profileData = { ...values };

                        profileData.desc = profile[0].desc;
                        profileData.tagline = profile[0].tagline;
                        profileData.company = profile[0].company;
                        profileData.logo = profile[0].logo;
                        profileData.countryname = profile[0].countryname;
                        profileData.industryname = profile[0].industryname;
                        profileData.city = profile[0].city;
                        profileData.state = profile[0].state;
                        profileData.code = profile[0].code;
                        profileData.industryTpes = profile[0].industryTpes;
                        profileData.contracts = profile[0].contracts;
                        profileData.feedbacks = profile[0].feedbacks;
                        profileData.counts = profile[0].counts;
                        profileData.datecreated = profile[0].datecreated;
                        profileData.amountSpent = profile[0].amountSpent;
                        profileData.stripeid = profile[0].stripeid;
                        
                        setAllFeedbacks(profile[0].feedbacks);
                        setFeedbacks(profile[0].feedbacks.slice(0, (profile[0].feedbacks.length > (pageCount * 3)) ? (pageCount * 3) : profile[0].feedbacks.length));
                        setShowMore(profile[0].feedbacks.length > (pageCount * 3));


                        if (profile[0].avgrating == null) {
                            profileData.avgrating = 0;
                        }
                        else if (profile[0].avgrating !== null) {

                            profileData.avgrating = profile[0].avgrating;

                        } 
                        setValues(  profileData  );
                        setProfileInfo(profile[0]);

                    }
                    
                }
                else {
                   window.location.href = "/notavailable";
                }
                setLoaded(true);
            
        } catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);

        }

    }
 
    function LoadMore() {

        setPageCount(pageCount + 1);
    } 

    async function setPaymentStatus(id) {

        try {
            if (id) {
                const detail = await PaymentRetrieveAccount(id);
               
                if (detail.data.paymentRetrieveAccount.responseData.code == 200) {
                    // console.log("paymentpaymentpaymentpayment", detail.data.paymentRetrieveAccount.responseData.message);
                    // details_submitted, charges_enabled
                    let data = JSON.parse(detail.data.paymentRetrieveAccount.responseData.message); 
                    setValues({
                        ...values, stripeStatus: data.details_submitted && data.charges_enabled
                    });
                }
              
            }
           
        } catch (e) {
            console.log("paymenterror", e);
            return false;
        }

    }
    useEffect(() => {
        if (pageCount > 1) {
            let data = allfeedbacks;
            setFeedbacks(data.slice(0, (data.length > (pageCount * 3)) ? (pageCount * 3) : data.length));

            setShowMore(data.length > (pageCount * 3));
        }
    }, [pageCount]);

    useEffect(() => {
        setPaymentStatus(values.stripeid);
    }, [values]);
    

    return (
        <>
            {loaded &&

                <>
                <Helmet>
                    
                    <meta name="title" content={(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"} />
                    <meta name="description" content={values.desc?.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc?.replace(/(<([^>]+)>)/ig, '') : values.desc?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />
                    <meta name="og:title" content={(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"} />
                    <meta name="og:description" content={values.desc?.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc?.replace(/(<([^>]+)>)/ig, '') : values.desc?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />
                    <meta name="twitter:title" content={(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"} />
                    <meta name="twitter:description" content={values.desc?.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc?.replace(/(<([^>]+)>)/ig, '') : values.desc?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />

                </Helmet>
                    <div className="page-wrapper">

                        <div className="section">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-3 col-md-12 col-sm-12">

                                        <div className="card profile-details">
                                            <div className="profile-img">
                                            <img id="logo" onError={(img) => { img.target.src = '../images/user-default.png'; }} src={(values.logo && values.logo != "" && values.logo !="null") ? s3URL + values.logo :"../images/user-default.png"} alt=" " />
                                            </div>

                                            <h4 className="client-name"> {values.company}</h4>

                                            <span className="company-tag">{values.tagline}</span>

                                            <div className="d-flex flex-column align-item-center justify-content-center">
                                                <div className="month-data text-light-gray text-center m-b-0">
                                                    <span>Active since <strong> <span className="text-black"> {(values.datecreated) ? sinceDate(values.datecreated, new Date()).replace('ago', '') : ''}</span></strong></span>
                                                </div>

                                                <div className="ratings m-t-0">
                                                    {(values.avgrating != 0) ? <>

                                                        <StarRating rating={values.avgrating} isSelectable="false" />
                                                        <span className="m-t-5 m-l-5" >
                                                            {number_test(values.avgrating)}/5

                                                        </span>
                                                </> : ''} </div>

                                            <div className="info-meta m-t-10">

                                                <Icon className=" pointer dropdown-toggle text-theme m-icon material-icons  m-r-10 " data-bs-toggle="dropdown" aria-expanded="false">share</Icon><span className="pointer dropdown-toggle  " data-bs-toggle="dropdown" aria-expanded="false" ><b>Share</b> </span>


                                                <ul className="dropdown-menu dropdown-menu-end share-dropdown">
                                                    <li> <FacebookShareButton
                                                        url={window.location.href}


                                                    >
                                                        <a className="dropdown-item d-flex align-item-center" target='_blank' href={window.location.href}><img className="m-r-10" src="../images/social-icon-sm/facebook.svg" />Facebook</a>

                                                    </FacebookShareButton></li> 
                                                    <li>   <TwitterShareButton title={(values.company) ? values.company : ''} url={window.location.href}
                                                    >
                                                        <a className="dropdown-item d-flex align-item-center   " href={window.location.href}><div className=" btn    btn-circle bg-black m-r-10"><img className="  w-half " src="../images/twitter.svg" /></div>X</a>
                                                    </TwitterShareButton></li>
                                                    <li>   <LinkedinShareButton title={(values.company) ? values.company : ''} summary={values.desc ? (values.desc.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc.replace(/(<([^>]+)>)/ig, '') : values.desc.replace(/(<([^>]+)>)/ig, '').substring(0, 100)):""} url={window.location.href} >

                                                        <a className="dropdown-item d-flex align-item-center" href={window.location.href} target='_blank' ><img className="m-r-10" src="../images/social-icon-sm/linked.svg" />Linked</a>

                                                    </LinkedinShareButton></li>
                                                   


                                                </ul>
                                            </div>
                                        </div>
                                            <div className="divider-line-20"></div>
                                             
                                            <div className="meta-inline">
                                                <span>Location :</span>

                                            <div className=" info-meta flag  location-width" data-toggle="tooltip" title={values.city + ' ' + values.state + ' ' + values.countryname} >
                                                <span className="text-theme m-icon material-icons m-r-10">location_on</span><span className=" text-capi one-line text-black m-r-10"><strong>       <span className={`    ${(values.city != null && values.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(values.city) ? values.city.toLowerCase() : "" + '   '}{" "}</span>
                                                    <span className={`${(values.state != null && values.state.length > 2) ? 'text-capi' : 'text-upper'}`}>{(values.state) ? values.state.toLowerCase() : ""}</span>{' ' + values.code}   </strong></span>
                                                    <ReactCountryFlag
                                                        countryCode={values.code}
                                                        svg
                                                        style={{
                                                            width: '1.5em',
                                                            height: '.7em',
                                                        }}
                                                        title={values.code}
                                                    />

                                                </div>
                                            </div>


                                        <div className="divider-line-20"></div>
                                        {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>  <div className="meta-inline  ">
                                            <span>Payment Verified :</span>
                                            <span></span> <strong>{values.stripeStatus == true ? <>YES    </> : <>NO</>}</strong>
                                        </div>
                                          </>}
                                        <div className="divider-line-20"></div>
                                        {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>  <div className="meta-inline ">
                                            <span>Amount Spent :</span>
                                            <span></span> <strong>${values.amountSpent ? new Intl.NumberFormat('en-US').format(parseInt(values.amountSpent)) : "0"}</strong>
                                        </div>
                                            <div className="divider-line-20 "></div> </>}

                                       
                                        </div>

                                    </div>

                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                        <div className="card panel profile-details-desc">

                                            <div className="card-header">
                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#company">Company</a>
                                                    </li>
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#company-jobs">Jobs</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active" id="company">
                                                        <div className="profile-details-desc-title">

                                                            <h6>About</h6>

                                                            <div className="text-light-gray f-16" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(values.desc) }} ></div>


                                                        </div>

                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Industry types</h6>

                                                            {(values && values.industryTpes) && values.industryTpes.map((data, index) => {
                                                                const { name } = data;
                                                                return (
                                                                    <span key={index} className="badge industry-types"> {name}</span>
                                                                )
                                                            })}

                                                        </div>
                                                        {<Feedback jobsList={feedbacks} />}

                                                        {(showMore) && <div className="text-center ">



                                                            <div className="load-more mt-5">
                                                                <button type="button" className="btn btn-orange-outline d-flex align-item-center" onClick={() => { LoadMore(); }}>
                                                                    <Icon className="m-icon material-icons m-r-10  ">refresh</Icon>Load more</button>
                                                            </div>

                                                        </div>}

                                                    </div>

                                                    <div className="tab-pane" id="company-jobs">
                                                        <div className="profile-jobs-block">

                                                            <div className="d-flex">
                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                                        <div className="icon-wrapper">
                                                                            <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                            <div className="d-flex flex-column">
                                                                                <span className="m-b-10 text-light-gray">Active jobs</span>
                                                                                <b>  {(values && values.contracts) ? values.contracts.active.length == 0 ? '-' : values.contracts.active.length : '-'}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div></>}

                                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                                    <div className="icon-wrapper">
                                                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="m-b-10 text-light-gray">Completed Jobs</span>
                                                                            <b>   {(values && values.counts) ? values.counts.jobs.completed.length == 0 ? '-' : values.counts.jobs.completed : '-'}</b>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                                    <div className="icon-wrapper">
                                                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="m-b-10 text-light-gray">Experts hired</span>
                                                                            <b> {values.counts == null ? '' : values.counts.contracts.hired == 0 ? '-' : values.counts.contracts.hired}</b>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="accordion" id="accordionjobs">
                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' :
                                                                    ((values.contracts) && values.contracts.active.length > 0) ?
                                                                        <>     <div className="accordion-wrapper m-b-30">

                                                                            <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-active-job" aria-expanded="false"><h5>Active Jobs ({(values.contracts) ? values.contracts.active.length : 0})</h5>
                                                                                <span className="m-icon material-icons indicator show">expand_more</span>
                                                                                <span className="m-icon material-icons indicator hide">expand_less</span>
                                                                            </a>

                                                                            <div id="accordion-active-job" className="collapse" data-bs-parent="#accordionjobs">
                                                                                <div className="accordion-body card panel p-0">

                                                                                    <div className="card-body">
                                                                                        <div className="tab-content">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th className="min-lg-cell">Job Title</th>
                                                                                                            <th className="min-lg-cell">Type</th>
                                                                                                            <th className="min-lg-cell">Expert</th>
                                                                                                            <th className="min-md-cell">Duration</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {(values && values.contracts) && slice.map((data, index) => {
                                                                                                            const { guid, name, jobtype, firstname, lastname, startdate, duration, expertavatar, sub } = data;
                                                                                                            return (
                                                                                                                <tr>


                                                                                                                    <td>
                                                                                                                        <span className="d-flex justify-content-left align-items-left"
                                                                                                                         onClick={(e) => { window.location.href = "/job/" + guid }}>
                                                                                                                            <span className="title one-line pointer" data-toggle="tooltip" style={{ fontSize: "14px" }}
                                                                                                                                title={name} >{name}</span>
                                                                                                                               
                                                                                                                           
                                                                                                                        </span>
                                                                                                                    </td>



                                                                                                                    <td className="d-none">
                                                                                                                        <div className="d-flex align-items-left justify-content-left pointer" onClick={(e) => { window.location.href = "/job/" + guid }}>
                                                                                                                            <span className="m-r-5" >  {name}</span> 
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td className="text-capi ">
                                                                                                                        {jobtype ? (jobtype).toLowerCase() : ''}
                                                                                                                    </td>
                                                                                                                    <td>


                                                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                                                            <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/expert/" + sub }}>
                                                                                                                                <img className="m-r-10 " src={s3URL + expertavatar} alt=" " id={"image" + index} onError={(img) => { img.target.src = '../images/user-default.png'; }} />
                                                                                                                                <span className="one-line text-black "> {firstname + ' ' + lastname}</span>
                                                                                                                                 
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {(startdate) ? getMonthDateYear(startdate) + ' - Present' : ''}
                                                                                                                    </td>
                                                                                                                </tr>



                                                                                                            )
                                                                                                        })}</tbody>
                                                                                                </table>

                                                                                            </div>  {(loaded && values.contracts && values.contracts.active.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div> </> : ''}

                                                                {(profileInfo.contracts && profileInfo.contracts.completed.length > 0) ? <>
                                                                    <div className="accordion-wrapper">

                                                                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-completed-jobs" aria-expanded="false"><h5>Completed Jobs ({(values.contracts) ? values.contracts.completed.length : 0})</h5>
                                                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                                                        </a>

                                                                        <div id="accordion-completed-jobs" className="collapse" data-bs-parent="#accordionjobs">
                                                                            <div className="accordion-body card panel p-0">

                                                                                <div className="card-body">
                                                                                    <div className="tab-content">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th className="min-lg-cell">Job Title</th>
                                                                                                        <th className="min-lg-cell">Type</th>
                                                                                                        <th className="min-lg-cell">Expert</th>
                                                                                                        <th className="min-lg-cell">Duration</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {(values && values.contracts) && completed.slice.map((data, index) => {
                                                                                                        const { guid, name, jobtype, firstname, lastname, duration, sub, expertavatar } = data;
                                                                                                        return (<tr key={index}>


                                                                                                            <td>
                                                                                                                <span className="d-flex justify-content-left align-items-left" onClick={(e) => { window.location.href = "/job/" + guid }}>
                                                                                                                    <span className="title one-line pointer" data-toggle="tooltip" style={{ fontSize: "14px" }}
                                                                                                                        title={name} >{name}</span>
                                                                                                                    
                                                                                                                </span>
                                                                                                            </td>




                                                                                                            <td className="d-none">

                                                                                                                <div className="d-flex align-items-left justify-content-left pointer" onClick={(e) => { window.location.href = "/job/" + guid }}>
                                                                                                                    <span className="one-line text-black ">  {name}</span> 
                                                                                                                </div>

                                                                                                            </td>
                                                                                                            <td className="text-capi ">
                                                                                                                {(jobtype).toLowerCase()}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className="d-flex align-items-center justify-content-center pointer">
                                                                                                                    <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/expert/" + sub }}>
                                                                                                                        <img className="m-r-10  " src={s3URL + expertavatar} alt="" id={"image" + index} onError={(img) => { img.target.src = '../images/user-default.png'; }} />
                                                                                                                        <span className="one-line text-black "> {firstname + ' ' + lastname}</span>
                                                                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>    {(duration.years <= 0) ? '' : duration.years + ' Years '}
                                                                                                                {(duration.months <= 0) ? '' : duration.months + ' Months'}</td>
                                                                                                        </tr>)
                                                                                                    })}



                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>   {(loaded && values.contracts && values.contracts.completed.length > 5) && <TablePagination range={completed.range} slice={completed.slice} setPage={setcompletedPage} page={completedPage} />}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div> </> : ''}

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>


                     
                </>}

            {!loaded &&

                <>
                    <div className="page-wrapper">

                        <div className="section">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-3 col-md-5 col-sm-12">

                                        <div className="card profile-details">
                                            <div className="profile-img">
                                                <img className="movingLoader btn-circle w-150" />
                                            </div>



                                            <div className="d-flex flex-column align-item-center justify-content-center">
                                                <div className="month-data text-light-gray text-center m-b-0 p-5 movingLoader w-150">
                                                    <span>  &nbsp; </span>
                                                </div>

                                                <h4 className="client-name movingLoader w-100 m-t-10 align-item-center justify-content-center  "> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>

                                                <span className="company-tag movingLoader align-item-center justify-content-center w-100">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>
                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline movingLoader w-full">
                                                <span>&nbsp;</span>

                                            </div>


                                            <div className="divider-line-20"></div>
                                            <div className="meta-inline movingLoader w-full">
                                                <span>&nbsp;</span>



                                            </div>


                                        </div>

                                    </div>

                                    <div className="col-lg-9 col-md-7 col-sm-12">
                                        <div className="card panel profile-details-desc">

                                            <div className="card-header">
                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className="nav-link active movingLoader w-100" data-bs-toggle="tab" href="#company">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </a>
                                                    </li>
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link movingLoader w-100" data-bs-toggle="tab" > &nbsp;&nbsp;&nbsp;</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active w-full" id="company">
                                                        <div className="profile-details-desc-title movingLoader w-full">

                                                            <h6 className=" movingLoader w-100">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h6>

                                                            <div className="d-flex justify-content-between m-b-0 w-full  movingLoader">
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>

                                                            </div>


                                                        </div>

                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                            </div>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                        </div>
                                                        <div className="company-meta">
                                                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                            </div>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                        </div>
                                                        <div className="company-meta">
                                                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                            </div>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>


                </>
            }
        </>
    );
};